import React from 'react';
import './SixSDCC.scss';
import ProductPoster from '../KodanshaUIKit/ProductPoster/ProductPoster';
import KodanshaUnderlinedText from '../KodanshaUIKit/KodanshaUnderlinedText/KodanshaUnderlinedText';
import { ProductsData } from '../AX/ProductsData';
import { scrollToItem } from '../../utils/utils';
import { useAtom } from 'jotai';
import { LoginStatus, LoginStatusAtom } from '../../store/AccountStore';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import { Link } from '../../Devon/Components/InkyLink';

interface Props {
    data: typeof ProductsData[0];
    id: number;
    claimed: boolean;
}

export enum axSamplerButtonStatus {
    claim,
    signuptoread,
    read,
}

const MangaItem = ({ data, id, claimed }: Props) => {
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);

    const scrollToClaim = (e) => {
        scrollToItem('intro-ax', 120);
        return;
    }

    const logInSignup = (e) => {
            setLoginOverlay(true)
            return;
    };



    return <div className={'six-item-wrapper'}>
        <ProductPoster src={'https://cdn.kodansha.us/statics/Publications/' + data?.reader + '/Pages/page-0001.jpg'} ratio={140} />
        <span className={'sampler'}>DISCOVER KODANSHA</span>
        <span className={'name'}>{data.sampler}</span>
        {/*//TODO: READER uses readable url*/}

        {
            loginStatus === "LoggedIn" ?
                <Link to={'/reader/' + data.reader.toString()}>
                    <KodanshaUnderlinedText text={'Read'} />
                </Link>
                :
                <KodanshaUnderlinedText onClick={logInSignup} text={"Sign up to read"}/>
        }

    </div>;

};

const SixSDCC = (props) => {
    return (
        <div className='six-ax-container'>
            <h1 className='header-one' id={'digital-manga-samplers-title'}>DIGITAL MANGA SAMPLERS</h1>
            <h2 className='header-two'>Sample our best manga across six genres</h2>
            <div className='six-ax-wrapper'>
                {ProductsData.map((data, index) => <MangaItem data={data} id={index} claimed={props.redeemComics} />)}
            </div>
        </div>
    );
};

export default SixSDCC;