import { Cart, CartDTO } from './Cart';
import { Product } from './Product';
import { Variant, VariantDTO } from './Product/Variant';
import Dinero from 'dinero.js';

export class CartItem {
    id: number;
    cartId?: number;
    product: Product;
    productId: number;
    variantId: number;
    variant: Variant;
    distributionType: VariantType;
    price: Dinero.Dinero
    fullPrice: Dinero.Dinero
    isOnSale:boolean;


    static createFromApi(dto: CartItemDTO): CartItem{
        return Object.assign(new CartItem(), dto, {
            variant: Variant.createFromApi(dto.variant),
            price: Dinero({amount: Math.round(dto.price*100), currency: 'USD'}),
            fullPrice: Dinero({amount: Math.round(dto.fullPrice*100), currency: 'USD'})
        });
    }

}

export interface CartItemDTO {
    id: number;
    cartId?: number;
    product: Product;
    variantId: number;
    variant: VariantDTO;
    distributionType: VariantType;
    price: number;
    fullPrice: number;
    isOnSale:boolean;
}

export class CountedCartItem {
    amount: number;
    cartItem: CartItem;
}

export enum VariantType {
    Digital = "Digital",
    Print= "Print"
}