import { PublishManager, DashboardProduct, IapsResult, Publishable } from './';
import { AxiosResponse, CancelTokenSource } from 'axios';
import apiClient from "../../API/InkyAPI";
import { NameTuple } from '../FilterTuple';
import dayjs from 'dayjs';
import { DashboardProductDTO } from '../DashboardProduct';
import { DisplayOptions, DisplayOptionValue } from '../../store/DashboardProductsStore';

class ProductManager extends PublishManager<DashboardProduct> {

  constructor() {
    super();

    this.addItem = async(
      cancelTokenSource: CancelTokenSource,
      item: DashboardProduct
    ) => {

      console.log("Adding product...\n" + JSON.stringify(item));
      return await apiClient.post<DashboardProduct>("/dashboard/product/new",item, cancelTokenSource);
    };

    this.alterItems = (cancelTokenSource: CancelTokenSource, items: DashboardProduct[]) => {
      console.log("Altering products...\n" + JSON.stringify(items));
      return apiClient.post<DashboardProduct[]>("/dashboard/product/alter", items, cancelTokenSource);
    };

    this.publishItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      return apiClient.post<Object>("/dashboard/product/publish?id="+id, null, cancelTokenSource);
    };

    this.unPublishItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      return apiClient.post<Object>("/dashboard/product/unpublish?id="+id, null, cancelTokenSource);
    };

    this.removeItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      return apiClient.post<Object>("/dashboard/product/remove?id="+id, null, cancelTokenSource);
    };

    this.fetchItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      const item = await apiClient.get<DashboardProductDTO>(`/dashboard/product/${id}`, cancelTokenSource);
      const ds = DashboardProduct.createFromApi(item.data);
      // ds.original = item.data;
      // ds.copyFromOriginal();
      console.log("Load Dash Product:");
      console.log(ds);
      return ds;
    };

    this.scheduleItem =  async(
        cancelTokenSource: CancelTokenSource,
        item: DashboardProduct,
    ) => {
      const dj = dayjs(item.publishDate);
      return apiClient.post<any>(`/dashboard/product/publish?id=${item.id}&publishDate=${dj.toISOString()}&publishStatus=${item.status}`, null, cancelTokenSource);
    };

    this.batchPublish = async (IDs: number[], sheduleDate?: Date, scheduleType?: DisplayOptionValue, abortSignal?: AbortSignal) => {
      const dj = dayjs(sheduleDate);
      let url = `/dashboard/product/batchPublish?ids=${IDs.join('&ids=')}`;
      if (scheduleType !== undefined) {
        url += `&publishDate=${dj.toISOString()}&publishStatus=${scheduleType}`;
      }

      return apiClient.postV4<any, any>(url, null, abortSignal);
    };

    this.batchUnPublish = async (IDs: number[], abortSignal?: AbortSignal) => {
      const url = `/dashboard/product/batchUnPublish?ids=${IDs.join('&ids=')}`;
      return apiClient.postV4<any, any>(url, null, abortSignal);

    };
  }

  // async getPublishStatus(item: DashboardProduct): Promise<IapsResult> {
  //   console.log("Getting publish status");
  //   const appleSkuCode = item.codes.find(x => x.type === "SKU" && x.variant=== "APPLE");
  //   const androidSkuCode = item.codes.find(x => x.type === "SKU" && x.variant=== "ANDROID");
  //   if (process.env.REACT_APP_USING_IAPS && (appleSkuCode || androidSkuCode)) {
  //     //return {appleIapStatus: "???"}
  //     const res = await apiClient.get<IapsResult>(`/dashboard/product/${item.id}/iaps`, this.tokenSource);
  //     console.log(res.data);
  //     return res.data;
  //   }
  //   else {
  //    const res = new IapsResult();
  //    res.id = item.id;
  //
  //    return res;
  //   }
  // }


  async submitIAPs(productId: number): Promise<AxiosResponse<any>> {
    return apiClient.post<any>(`/dashboard/product/${productId}/iaps/add`, null, this.tokenSource);
  }

  async getImprints(): Promise<NameTuple[]> {
    const response = await apiClient.get<NameTuple[]>('/dashboard/home/imprints',this.tokenSource);

    return response.data;
  }
}

export default ProductManager;
