import Dinero from 'dinero.js';

export class OrderPrices {
    isoCurrency: string;
    taxesPaid: Dinero.Dinero;
    shippingCost: Dinero.Dinero;

    toApi(): OrderPricesDTO {
        return {
            isoCurrency: this.isoCurrency,
            taxesPaid: this.taxesPaid?.getAmount(),
            shippingCost: this.shippingCost?.getAmount()
        };
    }

    static createFromApi(dto: OrderPricesDTO): OrderPrices{
        if (!dto){
            return null;
        }
        const taxesPaid = dto.taxesPaid ? Dinero({amount: Math.round(dto.taxesPaid * 100), currency: "USD"}) : null;
        const shippingCost = dto.shippingCost ? Dinero({amount: Math.round(dto.shippingCost * 10000), currency: "USD", precision: 4}) : null;

        return Object.assign(new OrderPrices(), dto, {
            taxesPaid: taxesPaid,
            shippingCost: shippingCost
        });
    }
}

export interface OrderPricesDTO {
    isoCurrency: string;
    taxesPaid: number;
    shippingCost: number;
}
