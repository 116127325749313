
class Address {
    isShippingAddress: boolean;
    isBillingAddress: boolean;
    firstName: string;
    surName: string;
    companyName: string;
    country: string;
    address: string;
    address2: string;
    locality: string;
    postalCode: string;
    administrativeArea: string;
    phoneNumber: string;
    email: string;

    static FromPartial(partial:Partial<Address>){
        const newAddress = new Address();
         Object.assign(newAddress, partial);
        return newAddress;
    }
    static areExactMatch(a: Address, b: Address): boolean {
        if(a === b) return true;

        //Treate null and undefined as the same for matching
        if((a === null || a === undefined) && (b === null || b === undefined)) {
            return true;
        }

        if(a == null || b == null){ return false;}
        // Lets just match the shipping and billing-flags for this test.
        a = Object.assign({}, a, {
            isShippingAddress: false,
            isBillingAddress: false
        });
        b = Object.assign({}, b, {
            isShippingAddress: false,
            isBillingAddress: false
        });
        return JSON.stringify(a) === JSON.stringify(b);
    }


    static isNullOrEmpty(address: Address): boolean {
        if(address === null || address === undefined) {
            return true;
        }

        return !(
            address.firstName ||
            address.surName ||
            address.country ||
            address.address ||
            address.locality ||
            address.postalCode ||
            address.administrativeArea ||
            address.phoneNumber ||
            address.email
        );

    }

    /**
     * Have all mandatory address fields been entered?
     */
    static mandatoryFieldsFilled(address: Address): boolean {
        const isEmptyOrSpaces = txt => {
            //regex at end means "Is this all spaces?"
            return txt === undefined || txt === null || txt.match(/^ *$/) !== null;
        };

        return address && !(
            isEmptyOrSpaces(address.firstName) ||
            isEmptyOrSpaces(address.surName) ||
            isEmptyOrSpaces(address.country) ||
            isEmptyOrSpaces(address.address) ||
            isEmptyOrSpaces(address.locality) ||
            isEmptyOrSpaces(address.postalCode) ||
            isEmptyOrSpaces(address.administrativeArea) ||
            isEmptyOrSpaces(address.phoneNumber) ||
            isEmptyOrSpaces(address.email)
        );
    }

    static isValid(address: Address): ValidStatus{
        const isEmptyOrSpaces = txt => {
            //regex at end means "Is this all spaces?"
            return txt === undefined || txt === null || txt.match(/^ *$/) !== null;
        };

        // Format the reason so that it can be sufficed with for example " from Shipping-address".
        if(isEmptyOrSpaces(address.firstName)){return {isValid: false, notValidReason: "Missing first name"};}
        if(isEmptyOrSpaces(address.surName)){return {isValid: false, notValidReason: "Missing surname"};}
        if(isEmptyOrSpaces(address.country)){return {isValid: false, notValidReason: "Missing country"};}
        if(isEmptyOrSpaces(address.address)){return {isValid: false, notValidReason: "Missing address field"};}
        if(isEmptyOrSpaces(address.locality)){return {isValid: false, notValidReason: "Missing city"};}
        if(isEmptyOrSpaces(address.postalCode)){return {isValid: false, notValidReason: "Missing ZIP code"};}
        if(isEmptyOrSpaces(address.administrativeArea)){return {isValid: false, notValidReason: "Missing state"};}
        if(isEmptyOrSpaces(address.phoneNumber)){return {isValid: false, notValidReason: "Missing phone number"};}
        if(isEmptyOrSpaces(address.email)){return {isValid: false, notValidReason: "Missing email"};}

        // If everything is valid
        return {isValid: true, notValidReason: null};

    }

}

export interface ValidStatus {
    isValid: boolean;
    notValidReason: string;
}

enum AddressType {
    Billing,
    Shipping
}

export { Address, AddressType };

