import { useState, useEffect, useRef } from 'react';

export const getTimeDiffs = (latestTime) => {
    if (latestTime === null || latestTime === undefined) {
        return null;
    }
    const currentTime = new Date().getTime();
    const timeDiffInMinutes = (currentTime - Number(latestTime)) / (1000 * 60);
    // console.log('timeDiffInMinutes', timeDiffInMinutes, new Date(Number(latestTime)));
    return timeDiffInMinutes;
};

function convertTimeToAmPm(time): string {
    const [hours, minutes] = time.split(':');
    const isAM = hours < 12 || hours === '00';
    const convertedHours = hours % 12 || 12;
    return `${convertedHours}:${minutes} ${isAM ? 'AM' : 'PM'}`;
}


export function useCounter(key: string) {
    const emailSentTime = localStorage.getItem(key);
    const [status, setStatus] = useState<false | number>(emailSentTime === undefined ?
        false : getTimeDiffs(emailSentTime) >= 3 ? false : getTimeDiffs(emailSentTime));
    const timerRef = useRef(null);
    // const [countdown, setCountdown] = useState<number | null>(null);

    const startTimer = (): void => {
        const LatestEmailSentTime = localStorage.getItem(key);
        if (status === false || status < 3) {
            const timeDiffInMinutes = getTimeDiffs(LatestEmailSentTime);
            setStatus(timeDiffInMinutes < 3 ? timeDiffInMinutes : false);
            timerRef.current = setInterval(() => {

                const timeDiffInMinutes = getTimeDiffs(LatestEmailSentTime);
                if (timeDiffInMinutes >= 3) {
                    setStatus(false);
                    clearInterval(timerRef.current);
                } else {
                    setStatus(timeDiffInMinutes);
                    // setCountdown(Math.ceil((3 - timeDiffInMinutes) * 60));
                }
            }, 1000);
        }
    };

    useEffect(() => {
        if (emailSentTime === undefined) {
            return;
        }

        startTimer();

        return (): void => {
            clearInterval(timerRef.current);
        };
    }, []);

    const date = new Date(Number(emailSentTime));
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const sentTime = `${hours}:${minutes}`;

    return {
        startTimer,
        sentTime: convertTimeToAmPm(sentTime),
        status,
        emailSentTime,
    };
}
