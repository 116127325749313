import React, { useRef } from 'react';
import './SeriesMAL.scss';
import KodanshaUnderlinedText from '../KodanshaUIKit/KodanshaUnderlinedText/KodanshaUnderlinedText';

import { GoodiesData } from './GoodiesDataMAL';
import { useAtom } from 'jotai';
import { LoginStatusAtom } from '../../store/AccountStore';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import { useHistory } from 'react-router-dom';
import { Link } from '../../Devon/Components/InkyLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { FreeModeOptions } from 'swiper/types/modules/free-mode';
import { useWindowSize } from '../Hooks/useWindowSize';
import { Swiper as T } from 'swiper/types';
import 'swiper/css';
import 'swiper/css/virtual';
import KodanshaArrow from '../KodanshaUIKit/KodanshaArrow/KodanshaArrow';
import ProductPoster from '../KodanshaUIKit/ProductPoster/ProductPoster';

interface Props {
    data: typeof GoodiesData[0];
}


export const ChaptersItem = ({ data }: Props) => {
    const history = useHistory();
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);

    const onButtonCLicked = (e) => {
        // if (e.currentTarget.textContent === 'Sign up to read') {
        //     setLoginOverlay(true);
        //     return;
        // }
    };

    return <div className={'series-item-wrapper'}>
        <Link target={'_blank'} to={'/product/' + data.readableUrl} className={'series-item-link'}>
            <ProductPoster ratio={120} src={data.image} />
        </Link>

        <div className={'series-item-info-wrapper'}>

            <h5 className={'series-name'}>{data.name}</h5>

            {<Link target={"_blank"} to={{
                pathname: '/product/' + data.readableUrl.toString(),
                state: { returnPath: history.location.pathname },
            }}
            >
                <KodanshaUnderlinedText text={'Find retailer'} />
            </Link>}

        </div>
    </div>;
};

const ChaptersMAL = () => {
    const ref = useRef<any>();
    const { width } = useWindowSize();
    const SwiperRef = useRef<T>();

    return (
        <div className='series-ax-container'>
            <h1 className='header-one'>Attack on Titan Box Sets</h1>
            <h2 className='header-two'>Collect and catch up on AoT before the series finale</h2>

            <div className='series-mal-grid-wrapper'>
                <Swiper
                    ref={ref}
                    spaceBetween={15}
                    slidesPerView={2}
                    slidesPerGroup={2}
                    touchEventsTarget={'container'}
                    freeMode={width < 1278}
                    modules={[FreeMode]}
                    longSwipesRatio={0.01}
                    onSwiper={(swiper) => {
                        SwiperRef.current = swiper;
                    }}
                    navigation={{
                        prevEl: '.prev',
                        nextEl: '.next',
                    }}
                    onResize={(swiper) => {
                        swiper.params.freeMode = {
                            ...swiper.params.freeMode as FreeModeOptions,
                            enabled: width < 1278,
                        };
                        swiper.update();
                    }}
                    breakpoints={{
                        576: {
                            slidesPerView: 2.2,
                            slidesPerGroup: 2,
                        },
                        768: {
                            slidesPerView: 3.2,
                            slidesPerGroup: 3,
                        },
                        1278: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        },
                    }}
                >
                    {GoodiesData.map((data, index) => {
                        return <SwiperSlide key={index}>
                            <ChaptersItem data={data} />
                        </SwiperSlide>;
                    })}
                </Swiper>
            </div>

            <Link className={'link-view-more-mal'} target={"_blank"} to={'/series/attack-on-titan-season-manga-box-sets'}>
                <KodanshaArrow text={'View more'} />
            </Link>

        </div>
    );
};

export default ChaptersMAL;