import { atom } from 'jotai';
import { LoginStatus, LoginStatusAtom } from './AccountStore';
import { CartHasItemsAtom } from './CartStore';
import { CheckoutOverlayVisible } from './OverlayStore';


let stripeLoaded = false;

/**
 * Returns true if any of the following conditions have ever been met at any point:
 * - At least one item in the Cart
 * - User is logged in.
 */
export const UseStripeWrapperAtom = atom(get => {
    if (navigator.userAgent === 'RenderBot') {
        return false;
    }
    if (!stripeLoaded) {
        stripeLoaded = window.location.pathname === '/confirm' || get(LoginStatusAtom) === 'LoggedIn' || get(CartHasItemsAtom) || get(CheckoutOverlayVisible);
    }

    return stripeLoaded;
});
