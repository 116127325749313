import React, { ReactElement, useEffect, useRef, useState } from 'react';
import './KodanshaLogin.scss';
import KodanshaEmail from '../../KodanshaUIKit/KodanshaEmail/KodanshaEmail';
import KodanshaArrow from '../../KodanshaUIKit/KodanshaArrow/KodanshaArrow';
import useLazyFetch from '../../Hooks/useLazyFetch';
import { InkyApiV2 } from '../../../API/InkyApiV2';
import { useAtom, useSetAtom } from 'jotai';
import { AccountInfoAtom, LoginStatus, LoginStatusAtom } from '../../../store/AccountStore';
import { CheckoutOverlayVisible, LoginOverlayVisible, LoginType, LoginTypeStore } from '../../../store/OverlayStore';
import apiClient from '../../../API/InkyAPI';
import { isEmail } from '../../../utils/utils';
import { FetchCartAtom, ShowCartAtom } from '../../../store/CartStore';
import { VariantType } from '../../../Models/CartItem';

// const colors = {
    // 0: '#3cc8e1',
    // 1: '#f5333f',
    // 2: '#ffe600',
    // 3: '#C964CF',
// };

export function setVerificationTime(): void {
    const currentTime = new Date().getTime();
    localStorage.setItem('verification_time', currentTime.toString());
}

export const ResendVerification = () => {
    return apiClient.resendVerification(null).then(resp => {
        console.log(resp);
        setVerificationTime();
        // localStorage.setItem('verification_time', )
    });
};

const KodanshaLogin = ({ email, setEmail, setStep, validEmailError = '', setValidEmailError }): ReactElement => {
    const { data, error, fetch } = useLazyFetch(() => InkyApiV2.shared().CheckIfEmailExists(email));
    const [isLoggedIn] = useAtom(LoginStatusAtom);
    // const [loginType] = useAtom(LoginTypeAtom);
    const [AccountInfo] = useAtom(AccountInfoAtom);
    const [hasSentVerification, setHasSentVerification] = useState(false);

    const [cart] = useAtom(FetchCartAtom); // The Cart-object
    // Checkout actions
    const [, setCheckoutOverlay] = useAtom(CheckoutOverlayVisible);
    const SetLoginOverlay = useSetAtom(LoginOverlayVisible);

    function hasDigital() {
        return cart && cart.containsProductVariantType(VariantType.Digital)
    }

    useEffect(() => {
        if (data === true) {
            setStep(1);
            setValidEmailError('');
        } else if (data === false) {
            setStep(2);
            setValidEmailError('');
        }
    }, [data, error]);

    const fetchAfterCheck = () => {
        if (isEmail(email)) {
            fetch();
        } else {
            email && setValidEmailError('Please enter a valid email.');
        }
    };

    const onLoginClicked = (): void => {
        fetchAfterCheck();
    };

    function onContinueAsGuestClicked(e:any) {
        e.preventDefault();
        setCheckoutOverlay(true);

        SetLoginOverlay(false)
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            fetchAfterCheck();
        }
    };

    console.log('LoginTypeStore.getLoginType()', LoginTypeStore.getLoginType());

    if (isLoggedIn !== "LoggedIn" && LoginTypeStore.getLoginType() === LoginType.Checkout) {
        // TODO: If user not signed in
        // LoginTypeStore.setLoginType(LoginType.Normal);
        return <div className='auth-login-container'>
            <span className='auth-login-title'>Log in or Sign up to continue</span>

            <div className='auth-login-features-wrapper-checkout'>
                <span className='auth-login-feature-text'>{(hasDigital())?"To purchase digital products you must be signed in to an account.":"Save time on your next checkout by creating an account."}</span>
            </div>

            <span className='auth-login-email-label'>
                Enter your email to get started
            </span>


            <div className='auth-login-email-wrapper'>
                <KodanshaEmail placeHolder={'mikasa@surveycorps.us'}
                               name={'email'}
                               onChange={(value) => {
                                   setValidEmailError('');
                                   setEmail(value);
                               }}
                               onEnterPushed={onEnter}
                               withFocus={true}
                               errorMessage={error === undefined ? validEmailError === '' ? '' : 'Please enter a valid email.' : error.toString()} />
            </div>

            <div className={"auth-bottom-buttons-wrapper-checkout"}>
                {/*<div className='auth-continue-wrapper'>*/}
                {/*    {hasPhysical()&& */}
                {/*        <KodanshaArrow */}
                {/*            onClick={onContinueAsGuestClicked} */}
                {/*            active={!hasDigital()} */}
                {/*            title={hasDigital()?'You need to be signed in to an account to purchase digital products.':''} */}
                {/*            text={'Continue as Guest'} */}
                {/*            withArrow={false} />}*/}
                {/*</div>*/}
                <div className={"auth-bottom-buttons-wrapper-checkout-inner"}>
                    {/*<div className='auth-continue-wrapper'>*/}
                    {/*    {hasPhysical()&&*/}
                    {/*        <KodanshaArrow*/}
                    {/*            onClick={onContinueAsGuestClicked}*/}
                    {/*            active={!hasDigital()}*/}
                    {/*            title={hasDigital()?'You need to be signed in to an account to purchase digital products.':''}*/}
                    {/*            text={'Continue as Guest'}*/}
                    {/*            withArrow={false} />*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className='auth-login-submit-wrapper'>
                        <KodanshaArrow onClick={onLoginClicked} active={isEmail(email)} text={'Continue'} />
                    </div>

                </div>

            </div>

        </div>;
    }

    if (isLoggedIn === "LoggedIn" && AccountInfo.verified === false) {
        // TODO: If user signed in but didn't verify account
        return <div className='auth-login-container'>
            <span className='auth-login-title'>Confirm your account</span>

            <div className='auth-login-features-wrapper'>
                {/*<span className='auth-login-feature-text'>Follow the instructions in the message we sent to your email to confirm your account. Then return here to continue.</span>*/}
                <span className='auth-login-feature-text'>
                    You’re all set! <br />
                    We’ve sent an email to <strong>{AccountInfo.email}</strong> with instructions to verify your new account. Please take a moment to complete this step.</span>
            </div>

            <div className='auth-login-verification-wrapper'>
                <span className='auth-login-feature-text'>Your account has not been verified.</span>
                <KodanshaArrow text={'Resend verification email'} withArrow={false} active={!hasSentVerification}
                               onClick={() => {
                                   ResendVerification();
                                   setHasSentVerification(true);
                               }} />
            </div>
        </div>;
    }

    return (
        <div className='auth-login-container'>
            <span className='auth-login-title'>Log in or create a Reader account</span>
            <span className='auth-login-subtitle'>As a Kodansha Reader, you’ll get:</span>

            <div className='auth-login-features-wrapper'>
                {[
                    'Extra chapter previews on most Kodansha series.',
                    'Free volumes to read with our rotation of Spotlight Series.',
                    'One place to read, buy, and collect our digital manga.',
                    'An online reader that always remembers your progress.',
                ].map((text, index) => {
                    return <div key={text} className='auth-login-feature'>
                        <span className='auth-login-feature-decoration' />
                        <span className='auth-login-feature-text'>{text}</span>
                    </div>;
                })}
            </div>

            <span className='auth-login-email-label'>
                Enter your email to get started
            </span>


            <div className='auth-login-email-wrapper'>
                <KodanshaEmail placeHolder={'mikasa@surveycorps.us'}
                               onEnterPushed={onEnter}
                               name={'email'}
                               onChange={(value) => {
                                   setEmail(value);
                                   setValidEmailError('');
                               }}
                               withFocus={true}
                               errorMessage={error === undefined ? validEmailError === '' ? '' : 'Please enter a valid email.' : error.toString()} />
            </div>

            <div className={"auth-bottom-buttons-wrapper"}>
                {/*<div className='auth-continue-wrapper'>*/}
                {/*    {hasPhysical()&&*/}
                {/*        <KodanshaArrow*/}
                {/*            onClick={onContinueAsGuestClicked}*/}
                {/*            active={!hasDigital()}*/}
                {/*            title={hasDigital()?'You need to be signed in to an account to purchase digital products.':''}*/}
                {/*            text={'Continue as Guest'}*/}
                {/*            withArrow={false} />*/}
                {/*    }*/}
                {/*</div>*/}
                <div className='auth-login-submit-wrapper'>
                    <KodanshaArrow onClick={onLoginClicked} active={isEmail(email)} text={'Continue'} />
                </div>

            </div>

        </div>
    );
};

export default KodanshaLogin;