export class DashThumbnail {
    url?: string = '';
    width?: number;
    height?: number;
    localPath?: string;
    color?: string;

    static fromApi(dto: DashThumbnailDTO): DashThumbnail {
        return Object.assign(new DashThumbnail(), dto);
    }

    toApi(): DashThumbnailDTO {
        return {
            url: this.url,
            width: this.width,
            height: this.height,
            localPath: this.localPath,
            color: this.color
        };
    }

    equals(other: DashThumbnail): boolean {
        return this.url === other.url &&
            this.width === other.width &&
            this.height === other.height &&
            this.localPath === other.localPath &&
            this.color === other.color;
    }

    merge(options: {
        url?: string;
        width?: number;
        height?: number;
        localPath?: string;
        color?: string;
    }): DashThumbnail {
        return Object.assign(new DashThumbnail(), this, options);
    }

    duplicate(): DashThumbnail {
        return Object.assign(new DashThumbnail(), this);
    }
}

export interface DashThumbnailDTO {
    url?: string;
    width?: number;
    height?: number;
    localPath?: string;
    color?: string;
}
