import { State } from './State';
import { OrderDTO } from './Order';

export class Country {
    numericCode: number;
    countryCode: string;
    name: string;
    states: State[];

    /**
     * Only USA has states for now.
     */
    hasStates(): boolean {
        //return this.countryCode === "US";
        return (this.states?.length ?? 0) > 0;
    }

    static createFromApi(dto: CountryDTO): Country {
        return Object.assign(new Country(), dto, {
            states: []
        });
    }
}

export interface CountryDTO {
    numericCode: number;
    countryCode: string;
    name: string;
}