
abstract class Publishable {

  public static readonly STATUS_NEW = "New";
  public static readonly STATUS_DRAFT = "Draft";
  public static readonly STATUS_PUBLISHED = "Published";
  public static readonly STATUS_REMOVED = "Removed";

  public static readonly STATUS_SCHEDULED = "Scheduled";
  public static readonly STATUS_SCHEDULED_PREORDER = "ScheduledPreOrder";
  public static readonly STATUS_SCHEDULED_COMING_SOON = "ScheduledComingSoon";


  id: number = null;
  status: string;

  original: Publishable;


  isToPublish(): boolean {
    if( this.status !== Publishable.STATUS_PUBLISHED)return false;

    switch (this.original.status){
      case Publishable.STATUS_DRAFT:
      case Publishable.STATUS_SCHEDULED:
      case Publishable.STATUS_SCHEDULED_PREORDER:
      case Publishable.STATUS_SCHEDULED_COMING_SOON:
        return true;
      default:
        return false
    }
  }

  wasScheduled(): boolean {
    switch (this.original.status){
      case Publishable.STATUS_SCHEDULED:
      case Publishable.STATUS_SCHEDULED_PREORDER:
      case Publishable.STATUS_SCHEDULED_COMING_SOON:
        return true;
      default:
        return false
    }
  }

  isToSchedule(): boolean {
    return false;
  }

  isToUnPublish(): boolean {
    return this.original.status === Publishable.STATUS_PUBLISHED
        && this.status === Publishable.STATUS_DRAFT;
  }

  isToRestore(): boolean {
    return this.original.status === Publishable.STATUS_REMOVED
        && this.status === Publishable.STATUS_DRAFT;
  }

  isToAdd(): boolean {
    return this.original.status === Publishable.STATUS_NEW;
  }

  isToRemove(): boolean {
    return this.status === Publishable.STATUS_REMOVED;
  }

  remove(): void {
    this.status = Publishable.STATUS_REMOVED;
  }

  isPublished(): boolean{
    return  this.status === Publishable.STATUS_PUBLISHED || this.status === "OnGoing";
  }

  //Define what the publish conditions are here.
  abstract canPublish(): boolean;



  copyFromOriginal(): void {
      const item = this.original;
      this.id= item.id;
      this.status=item.status;
  }

  hasChanged(): boolean {
    const orig = this.original;
    return orig && (this.status !== orig.status);
  }

}

interface OrderedItem {
  order: number;
}

abstract class OrderedPublishable extends Publishable implements OrderedItem {
  isForWeb: boolean;
  isForApp: boolean;
  isHeroBanner: boolean;
  order: number;

  getOriginal(): OrderedPublishable {
    return this.original? this.original as OrderedPublishable : null;
  }

  //Don't include order change in hasChanged.
  hasChanged(): boolean {
    if(!super.hasChanged()) {
      const orig = this.getOriginal();
      return orig && (this.isForWeb !== orig.isForWeb
          || this.isForApp !== orig.isForApp
          || this.isHeroBanner !== orig.isHeroBanner);
    }
    return true;
  }

  hasChangedOrder(): boolean {
    return this.getOriginal()?.order !== this.order;
  }

  copyFromOriginal(): void {
    super.copyFromOriginal();
    const item = this.getOriginal();
    this.isForApp = item.isForApp;
    this.isForWeb= item.isForWeb;
    this.isHeroBanner = item.isHeroBanner;
    this.order = item.order;
  }

  getItemReOrder(): {id: number; oldOrder: number; newOrder: number} {
    return {
      id: this.id,
      oldOrder: this.getOriginal().order,
      newOrder: this.order
    };
  }
}

class ItemOrder {
  id: number;
  oldOrder: number;
  newOrder: number;
}

export { Publishable, ItemOrder, OrderedPublishable };
export type { OrderedItem };

