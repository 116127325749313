import update from 'immutability-helper';

class PricingTier {
    id: number;
    ordinal: number;
    name: string;
    defaultCcy: string;
    defaultPrice: number;

    //For react-select.
    value?: string;
    label?: string;

    static copyFrom(orig: PricingTier): PricingTier {
        return update(orig, {
            value: {$set: `${orig.id}`},
            label: {$set: `${orig.defaultPrice.toFixed(2)} (${orig.name})` }
        });
    }
}

export default PricingTier;