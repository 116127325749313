import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { InkyApiV2 } from '../API/InkyApiV2';
import { Purchase, PaymentMethod, PaymentResult } from '../Models/Purchase';
import { PaymentRequest, PaymentRequestPaymentMethodEvent, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { PurchaseAttempt } from '../Models/PurchaseAttempt';
import { CheckoutOverlayVisible } from './OverlayStore';
import { CartIdAtom, FetchCartAtom } from './CartStore';
import { v4 as uuid } from 'uuid';
import { PurchaseError } from '../Models/Errors/PurchaseError';
import { AddressValidationResponse } from '../Models/Google/AddressValidation';

// PaymentProcessingOverlay
export const ShowPaymentProcessingOverlayAtom = atom(false);

export const PaymentMethodAtom = atomWithStorage<PaymentMethod>('paymentMethod', PaymentMethod.NotSelected);
export const PaymentIntentAtom = atomWithStorage<string | false>('paymentIntent', false);
export const SuccessOrderIDAtom = atom<string>('');


export const IsPurchaseCompleteAtom = atom<boolean>(false);
export const LastPurchaseIdAtom = atom<number>(-1);

export const PaymentPurchaseAtom = atom<Purchase | false>(false);
export const FetchPaymentPurchaseAtom = atom(
    (get) => get(PaymentPurchaseAtom),
    async (get, set) => {
        const paymentIntent = get(PaymentIntentAtom);

        if (!paymentIntent) return;

        const purchase = await InkyApiV2.shared().FetchPurchaseByPaymentIntent(paymentIntent);
        set(PaymentPurchaseAtom, purchase);
    },
);

export enum PaymentConfirmationStatus {
    Ready,
    Processing,
    Successful,
    ApiError,
    CardError,
    IdempotencyError,
    InvalidRequestError,
    InternalError

}

// Atoms for Stripe Card events
export const StripeCardElementAtom = atom<StripeCardNumberElement | false>(false);

export const StripePaymentRequestAtom = atom<PaymentRequest | false>(false);

export const StripeObjectAtom = atom<Stripe | false>(false);

export const StripeConfirmPaymentStatusAtom = atom<PaymentConfirmationStatus>(PaymentConfirmationStatus.Ready);
export const StripeConfirmPaymentAtom = atom(
    (get) => get(StripeConfirmPaymentStatusAtom),
    async (get, set, pa: PurchaseAttempt) => {
        //set(ShowPaymentProcessingOverlayAtom, true);
    },
);

// Initiate Purchase Stores
export const PurchaseAttemptAtom = atom<PurchaseAttempt>(null);


// region Error-Handling


// region Primary Error-Handler
export enum PurchaseErrorType {
    NoError,
    InvalidPaymentMethodError
}
const PrimaryErrorHandlerAtom = atom<PurchaseErrorType>(PurchaseErrorType.NoError);
// endregion Primary Error-Handler


// region StripeCardDetail Error-handler
export enum StripeCardDetailErrorType {
    NoError,
    InvalidCardNumber,
    InvalidExpirationDate,
    InvalidCCVNumber
}
const StripeCardDetailsErrorHandlerAtom = atom<StripeCardDetailErrorType>(StripeCardDetailErrorType.NoError);

// endregion StripeCardDetail Error-handler




// endregion Error-Handling

export enum PurchaseErrorMessage {
    NoError,
    InvalidStripePaymentCredentials,// Error occurs when one of the card-fields for the Stripe-integration is invalid.

}

export enum StripeCardErrorMessage {
    IsValid,
    StripeCardInvalidCardNumber,
    StripeCardInvalidExpirationDate,
    StripeCardInvalidCCVNumber
}

export enum StripeProcessingErrorMessage {
    NoError,
    UnknownError,
    ApiConnectionError,
    ApiError,
    ApiAuthError,
    CardError,
    IdempotencyError,
    InvalidRequestError,
    RateLimitError,
    ValidationError
}

export enum PurchaseValidationError {
    NoError,
    UnknownError,
    TaxError
}


// This is the Primary error-handler. Sub-handlers should set and reset this.

// Stripe Card-info valudation error-handler
export const StripeCardInfoErrorHandlerAtom = atom<StripeCardErrorMessage>(StripeCardErrorMessage.IsValid);
export const StripePaymentProcessingErrorHandlerAtom = atom<StripeProcessingErrorMessage>(StripeProcessingErrorMessage.NoError);

export const PurchaseValidationErrorHandlerAtom = atom<PurchaseValidationError>(PurchaseValidationError.NoError);
export const ShowCheckoutErrorMessages = atom<boolean>(false);


// Old errorhandling beneath here!
interface InitiatePurchaseProps {
    pr: Purchase;
    webpayPaymentEvent?:  PaymentRequestPaymentMethodEvent;
}

export const InitiatePurchaseAtom = atom(
    (get) => get(PurchaseAttemptAtom),
    async (get, set, props: InitiatePurchaseProps) => {
        let pMethod = get(PaymentMethodAtom);
        const stripePaymentInfoIsValid = get(StripeCardInfoErrorHandlerAtom);
        const cart = get(FetchCartAtom)
        const freeOrder = cart && cart.isFreeOrder()
        set(ShowCheckoutErrorMessages, false);
        console.log('Initiating purchase');
        console.log(pMethod);

        if(freeOrder && pMethod != PaymentMethod.NotSelected){
            console.log('Free order detected and payment method is not none. Correcting to none');
            pMethod = PaymentMethod.NotSelected;
            set(PaymentMethodAtom, pMethod);
        }

        if (!pMethod && !freeOrder) return; // TODO: Add error handling here?
        console.log('Initiating Purchase Request');

        // Pre-Checks for Stripe (Card)
        if(pMethod == PaymentMethod.Card){
            // Pre-Check the card details are valid.
            if (stripePaymentInfoIsValid !== StripeCardErrorMessage.IsValid) {
                console.error('Payment-info is not valid!', stripePaymentInfoIsValid);
                set(ShowCheckoutErrorMessages, true);
                return;
            }
        }


        // Generate PurchaseAttempt
        const purchaseAttempt = new PurchaseAttempt();
        purchaseAttempt.purchase = props.pr;
        purchaseAttempt.purchase.paymentMethod = pMethod;
        // Create the purchase in the backend.

        purchaseAttempt.onPurchaseRejected = (purchase, message): void => {
            console.warn('Purchase Failed for reason: ', message);
            console.warn(purchase);
            if(message == "Could not calculate tax"){
                set(PurchaseValidationErrorHandlerAtom, PurchaseValidationError.TaxError);
            }
            set(ShowCheckoutErrorMessages, true);
            return;
        };

        // Reset before we recheck
        set(PurchaseValidationErrorHandlerAtom, PurchaseValidationError.NoError);
        const createdPurchase = await purchaseAttempt.createPurchase();

        if (createdPurchase) {

            // Define the executePayment-callback based on what payment-method was chosen.
            switch (pMethod) {
                case PaymentMethod.Card:
                    createdPurchase.executePayment = async (purchase: Purchase): Promise<PaymentResult> => {
                        console.log('Paying for Purchase');

                        set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.Processing);
                        // Hide Error Messages for now
                        set(ShowCheckoutErrorMessages, false);
                        // Reset Stripe Payent Error
                        set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.NoError);

                        console.log('Processing Purchase atom');
                        const stripeObject = get(StripeObjectAtom);
                        const cardElement = get(StripeCardElementAtom);
                        const paymentIntentSecret = purchase.paymentIntent;

                        if (cardElement == false) {
                            console.error('Card-element not found. Cancelling');
                            set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                            return;
                        }

                        if (!stripeObject) {
                            console.error('Stripe Object not loaded for atom.');
                            set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                            return;
                        }

                        if (!paymentIntentSecret) {
                            console.error('Payment Intent not found.');
                            set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                            return;
                        }

                        console.log("Sending request to stripe to charge the card.");

                        const {paymentIntent, error} = await stripeObject.confirmCardPayment(
                            paymentIntentSecret,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            {payment_method: { card: cardElement}}
                        );
                        console.log('STRIPE CONFIRM', paymentIntent);
                        console.log('Stripe error?', error)



                        if(error){

                            // TODO: Iterate trough errors and set the status-atom accordingly
                            switch (error.type) {
                                case 'api_connection_error':
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.ApiConnectionError);
                                    throw new PurchaseError("Could not connect to Stripe", purchase);
                                case 'api_error':
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.ApiError);
                                    throw new PurchaseError("Could not communicate with Stripe", purchase);
                                case 'authentication_error':
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.ApiAuthError);
                                    throw new PurchaseError("Could not make a secure connection with Stripe", purchase);
                                case 'card_error':
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.CardError);
                                    throw new PurchaseError("There was an error with the card", purchase);
                                case 'idempotency_error':
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.IdempotencyError);
                                    throw new PurchaseError("There was a communication error with Stripe", purchase);
                                case 'invalid_request_error':
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.InvalidRequestError);
                                    throw new PurchaseError("Stripe could not understand the request", purchase);
                                case 'rate_limit_error':
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.RateLimitError);
                                    throw new PurchaseError("Stripe rejected the request (Rate-limited)", purchase);
                                case 'validation_error':
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.ValidationError);
                                    throw new PurchaseError("Could not validate the card", purchase);
                                default:
                                    set(StripePaymentProcessingErrorHandlerAtom, StripeProcessingErrorMessage.UnknownError);
                                    throw new PurchaseError("Stripe Charge failed for non-known reason.", purchase);
                            }
                        } else {
                            // Order is hereby client confirmed.
                            return {
                                message: 'Payment Successfull',
                                transactionId: paymentIntent.id,
                                purchase: purchase,
                            };
                        }


                    };
                    break;
                case PaymentMethod.Paypal:
                    createdPurchase.executePayment = async (purchase: Purchase): Promise<PaymentResult> => {
                        const paymentIntent = purchase.paymentIntent;

                        if (!paymentIntent) {
                            console.error('Payment Intent not found.');
                            set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                            return;
                        }
                        set(PaymentIntentAtom, purchase.paymentIntent);

                        // Redirect (window.location usage intended) to paypal
                        window.location.href = process.env.REACT_APP_PAYPAL_APPROVE_URL + '?token=' + purchase.paymentIntent;
                        return;
                        console.log('PAYPAL PAYMENTINTENT', purchase.paymentIntent);

                        //set(ShowPaymentProcessingOverlayAtom, true);
                        console.log('Paypal can start here', purchase.paymentIntent);
                        throw new Error('oops');
                        return {
                            message: '',
                            transactionId: '',
                            purchase: purchase,
                        };
                    };
                    break;
                case PaymentMethod.GooglePay:
                case PaymentMethod.ApplePay:
                    createdPurchase.executePayment = async (purchase: Purchase): Promise<PaymentResult> => {
                        console.log('Paying for Purchase Trough Webpay');

                        set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.Processing);
                        console.log('Processing Purchase atom');
                        const stripeObject = get(StripeObjectAtom);
                        const paymentIntentSecret = purchase.paymentIntent;
                        const paymentRequest = get(StripePaymentRequestAtom);
                        const paymentMethodEv = props.webpayPaymentEvent;


                        if (!stripeObject) {
                            console.error('Stripe Object not loaded for atom.');
                            set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                            return;
                        }

                        if (!paymentIntentSecret) {
                            console.error('Payment Intent not found.');
                            set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                            return;
                        }

                        if (!paymentRequest) {
                            console.error('Payment Request not found.');
                            set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                            return;
                        }

                        if (!paymentMethodEv) {
                            console.error('Payment-method not received from webPayment.');
                            set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                            return;
                        }


                        const {paymentIntent, error: confirmError} = await stripeObject.confirmCardPayment(
                            paymentIntentSecret,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            { payment_method: paymentMethodEv.paymentMethod.id},
                            {handleActions: false}
                        );


                        if(confirmError){
                            paymentMethodEv.complete('fail');
                            // TODO: Iterate trough errors and set the status-atom accordingly
                            switch (confirmError.type) {
                                case 'api_connection_error':
                                    set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                                    break;
                                case 'api_error':
                                    set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.ApiError);
                                    break;
                                case 'authentication_error':
                                    set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                                    break;
                                case 'card_error':
                                    set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.CardError);
                                    break;
                                case 'idempotency_error':
                                    set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                                    break;
                                case 'invalid_request_error':
                                    set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InvalidRequestError);
                                    break;
                                case 'rate_limit_error':
                                    set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.InternalError);
                                    break;
                                case 'validation_error':
                                    set(StripeConfirmPaymentStatusAtom, PaymentConfirmationStatus.CardError);
                                    break;
                            }
                        }
                        else {
                            console.log('STRIPE CONFIRM', paymentIntent);


                            if (paymentIntent.status === "requires_action") {
                                const {error} = await stripeObject.confirmCardPayment(paymentIntentSecret);
                                if (error) {
                                    // The payment failed -- ask your customer for a new payment method.
                                    console.error("Something failed. add error handling.", error);
                                    return;
                                } else {
                                    // The payment has succeeded.
                                    return {
                                        message: 'Payment Successful',
                                        transactionId: paymentIntent.id,
                                        purchase: purchase,
                                    };
                                }
                            }

                            paymentMethodEv.complete('success');
                            return {
                                message: 'Payment Successful',
                                transactionId: paymentIntent.id,
                                purchase: purchase,
                            };
                        }


                    };

                    break;
                default:
                    if (createdPurchase.purchase.totalPrice.getAmount() == 0) {
                        createdPurchase.executePayment = async (purchase: Purchase): Promise<PaymentResult> => {
                            return {
                                message: 'Free Order No Payment Required',
                                transactionId: 'FreeOrder',
                                purchase: purchase,
                            };
                        };
                    } else {
                        throw new Error('Payment method not implemented');
                    }

            }

            createdPurchase.onPurchaseSuccessful = (purchase, message): void => {
                console.log('Purchase was finalized and payment confirmed by Client.', message);
                console.log(purchase);
                // TODO: Add overlay for ordercomplete
                // Hide checkout
                // Reset Cart id
                set(CartIdAtom, uuid());
                // Hide Processing
                // Show Ordercomplete overlay
                set(LastPurchaseIdAtom, purchase.id);
                set(IsPurchaseCompleteAtom, true);

                //KlaviyoManager.PurchaseEvent(purchase);
            };



            await createdPurchase.payForPurchase();
        }


    },
);

export const IsInitiatingPurchaseAtom = atom<boolean>(false);

export const LoadableInitiatePurchaseAtom = atom(
    (get) => get(InitiatePurchaseAtom),
    async (get, set, props: InitiatePurchaseProps) => {
        set(IsInitiatingPurchaseAtom, true);
        try {
            await set(InitiatePurchaseAtom, props);
        } catch (e) {
            console.error(e);
        } finally {
            set(IsInitiatingPurchaseAtom, false);
        }
    }
)

export const ShippingAddressValidationResponseAtom = atom<AddressValidationResponse | false>(false);
export const ShippingAddressValidationResponseIsValidAddressAtom = atom((get) => {
    const response = get(ShippingAddressValidationResponseAtom);
    if(response ===false || response === null|| response === undefined) return false;

    if(!response.result.verdict.addressComplete){
        console.log('YEEEEDAD Address is not complete');
        return false;
    }

    switch (response.result.verdict.validationGranularity) {
        case 'Premise':
        case 'SubPremise':
            // continue
            break;
        default:
            console.log('YEEEEDAD Address is not granular enough');
            return false;
    }

    let hasInferredComponents = false;
    let hasSpellCorrectedComponents = false;
    let country = '';

    response.result.address.addressComponents.forEach((component) => {
        if (component.inferred && component.componentType !== 'postal_code_suffix') {
            hasInferredComponents = true;
        }
        if (component.spellCorrected) {
            hasSpellCorrectedComponents = true;
        }

        if(component.componentType === 'country'){
            country = component.componentName.text;
        }
    });

    if(country ==="USA"){
        switch (response.result.uspsData.dpvConfirmation){
            case 'Y':
                break;
            default:
                console.log('YEEEEDAD Address is not DPV confirmed for USA');
                return false;
        }
    }

    if (hasInferredComponents) {
        console.log('YEEEEDAD3 Address has inferred components');
        return false;
    }if (hasSpellCorrectedComponents) {
        console.log('YEEEEDAD3 Address  has SpellCorrectedComponents components');
        return false;
    }
    if(response.result.verdict.hasReplaceableComponents){
        console.log('YEEEEDAD Address has replaceable components');
        return false;
    }

    return true;
})

export const ShippingAddressValidationResponseNeedConfirmationAtom = atom((get) => {
    const response = get(ShippingAddressValidationResponseAtom);
    if(response ===false || response === null|| response === undefined) return false;

    if (!response.result.verdict.addressComplete) {
        console.log('YEEEEMOM Address is not complete');
        return false;
    }

    switch (response.result.verdict.validationGranularity) {
        case 'SubPremise':
        case 'Premise':
        case 'PremiseProximity':
        case 'Block':
        case 'Route':
            //continue
            break;
        case `Other`:
        case `GranularityUnspecified`:
        default:
            console.log('YEEEEMOM Address is not granular enough');
            return false;
    }


    let hasInferredComponents = false;
    let hasSpellCorrectedComponents = false;
    let hasReplaceableComponents = false;
    let country = '';

    response.result.address.addressComponents.forEach((component) => {
        if (component.inferred && component.componentType !== 'postal_code_suffix') {
            hasInferredComponents = true;
        }
        if (component.spellCorrected) {
            hasSpellCorrectedComponents = true;
        }

        if(component.componentType === 'country'){
            country = component.componentName.text;
        }
        if( component.replaced){
            hasReplaceableComponents = true;
        }
    });
    if (hasInferredComponents || hasReplaceableComponents) {
        console.log('YEEEEMOM Address is not inferred or replaceable');
        return false;
    }

    if (country === 'USA') {
        switch (response.result.uspsData.dpvConfirmation) {
            case 'Y':
            case 'S':
                break;
            default:
                console.log('YEEEEMOM Address is not DPV confirmed for USA');
                return false;
        }
    }

    return true;
})

export interface VerificationIssue {
    type: string;
    label: string;
    content: string;
}

export const ShippingVerificationIssuesAtom = atom<VerificationIssue[]>((get) =>{
    const response = get(ShippingAddressValidationResponseAtom);
    if(response ===false || response === null|| response === undefined) return [];
    let issues = [];
    let country = '';
    response.result.address.addressComponents.forEach((component) => {
        if (component.inferred && component.componentType !== 'postal_code_suffix') {
            issues.push({type: 'inferred', label: component.componentType, content: component.componentName.text});
        }
        if (component.spellCorrected) {
           switch (component.componentType) {
               case "route":
                   issues.push({type: 'spellCorrected', label: component.componentType, content: component.componentName.text});
                   break;
               default:
                   issues.push({type: 'spellCorrected', label: component.componentType, content: component.componentName.text});
                   break;
               }
        }
        if(component.componentType === 'country'){
            country = component.componentName.text;
        }
    });
    if (country === 'USA') {
        switch (response.result.uspsData.dpvConfirmation) {
            case 'Y':
                break;
            case 'S':
                issues.push({type: 'dpv', label: 'dpv', content: 'Additional details for this address, such as the apartment, suite, or floor number, have not been confirmed by the USPS. Please verify that all entered information is correct.'});
                break;
            case "D":
                //fix the address
                issues.push({type: 'dpv', label: 'dpv', content: 'The address is missing secondary information, such as an apartment or suite number. This is recommended for your address.'});
                break;
            case 'N':
            case '':
                issues.push({type: 'dpv', label: 'dpv', content: 'This address has not been confirmed by the USPS.'});
                break;
            default:
                break;
        }
    }
    return issues;
});

export const ShippingAddressValidationNeedsDPVConfirmAtom = atom((get) => {
    const response = get(ShippingAddressValidationResponseAtom);
    if(response ===false || response === null|| response === undefined) return false;

    let country = '';

    response.result.address.addressComponents.forEach((component) => {

        if(component.componentType === 'country'){
            country = component.componentName.text;
        }

    });

    if (country === 'USA') {
        switch (response.result.uspsData.dpvConfirmation) {
            case 'S':
                return true

            default:
                return false;
        }
    }

})
