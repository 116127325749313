import React, { ReactElement } from 'react';
import './ProductPoster.scss';
import useElementSize from '../../Hooks/useElementSize';
import { useHistory } from 'react-router-dom';
import { ImagePlaceholder } from '../../Assets/ImagePlaceholder';
import SalesOverlay from './SalesOverlay';
import InkyMageImg, { InkyMageImgDimension } from '../../../Devon/Components/InkyMageImg/InkyMageImg';

interface Props {
    text: string;
    src: string;
    alt?: string;
    ratio?: number;
    hover?: boolean;
    productId?: number;
    readableLink:string;
    isOnSale?: boolean;
    isProductPage?: boolean;
    preloadImage?: boolean;
    /// Image width when column is small (Will fallback to extra-small if not set.)
    s?:  InkyMageImgDimension;
    /// Image width when column is medium (Will fallback to small if not set.)
    m?: InkyMageImgDimension;
    /// Image width when column is large (Will fallback to medium if not set.)
    l?: InkyMageImgDimension;
    /// Image width when column is extra-large (Will fallback to large if not set.)
    xl?:    InkyMageImgDimension;
}

export type KodanshaPosterProps = Partial<Props & React.HTMLAttributes<HTMLImageElement>>;

const ProductPoster = ({ src, alt, ratio = 125, hover, s = {width: 145}, m, l = {width: 288}, xl = {width: 405}, isOnSale, isProductPage, ...props }: KodanshaPosterProps): ReactElement => {
    const [posterRef, { width }] = useElementSize();
    const history = useHistory();

    return (
        <div className={`ui-product-poster-container ${hover !== false && 'ui-product-poster-container-hover'}`}
             style={{ paddingTop: ratio + '%' }} onClick={() => {
            if (props.productId != undefined) {
                history.push('/product/' + props.readableLink);
            }
        }}>
            {isOnSale && <span className={`on-sale ${isProductPage ? 'on-sale-product-page' : ''}`}>ON SALE</span>}
            <div className='ui-product-poster-container-2'>
                <div className='ui-product-poster-wrapper' ref={posterRef}>
                    {src ?
                        <InkyMageImg className='ui-product-poster-image'
                                     src={src}
                                     alt={alt}
                                     preloadImage={props.preloadImage}
                                     s={s}
                                     m={m}
                                     l={l}
                                     xl={xl}
                                     width={1763}
                                     height={2530}
                        />
                        :
                        <ImagePlaceholder alt={alt} />
                    }

                </div>
            </div>
        </div>
    );
};

export default ProductPoster;
