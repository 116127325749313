import Dinero from 'dinero.js';
import { VariantDTO } from './Product/Variant';

export class ShippingRate {
    mailClass: string;
    mailService: string;
    totalPrice: Dinero.Dinero;
    deliveryTimeInDays: string;

    toApi(): ShippingRateDTO {
        return {
            mailClass: this.mailClass,
            mailService: this.mailService,
            totalPrice: this.totalPrice.getAmount(),
            deliveryTimeInDays: this.deliveryTimeInDays,
        };
    }

    static createFromApi(dto: ShippingRateDTO): ShippingRate{
        return Object.assign(new ShippingRate(), dto, {
            totalPrice: Dinero({amount: Math.round(dto.totalPrice * 10000), currency: "USD", precision: 4}),
        });
    }
}

export interface ShippingRateDTO {
    mailClass: string;
    mailService: string;
    totalPrice: number;
    deliveryTimeInDays: string;
}