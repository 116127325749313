import axios from 'axios';
import Axios, { AxiosError, AxiosPromise, AxiosResponse, CancelTokenSource } from 'axios';
import { Series } from '../Models/Series';
import { Comic } from '../Models/Comic';
import { SearchResult } from '../Models/SearchResult';
import { FilterTuple } from '../Models/FilterTuple';
import { AccountInfo } from '../Models/AccountInfo';
import { HomeModel } from '../Models/HomeModel';
import { Page } from '../Models/Page';
import { LoginResponse } from '../Models/LoginResponse';
import { CarouselEndpointResponseType } from '../Models/CarouselItem';
import { PaymentPlanModel } from '../Models/PaymentPlanModel';
import { InstrumentationSessionList } from '../Models/Instrumentation/InstrumentationPayload';
import { UserComicData } from '../Models/UserComicDataPayload';
import { Bundle } from '../Models/Bundle';
import { IpTag } from '../Models/IpTag';
import CreateUserPayload from '../Models/CreateUserPayload';
import GamesAndMerch from '../Models/Product/GamesAndMerch';
import { Product, ProductDTO } from '../Models/Product';
import NewsModel from '../Models/NewsModel';
import OrderConfirmationModel from '../Models/OrderConfirmationModel';
import { DiscoverItem, DiscoverWheel } from '../Models/DiscoverItem';
import { Order, OrderDTO, OrdersCount } from '../Models/Order';
import { InkyApiV2 } from './InkyApiV2';
import { Article, ArticleDTO } from '../Models/Article';
import { UserDownloadableResponse } from '../Models/UserDownloadableData';
import { BrowseGenre } from '../Models/BrowseGenre';
import AnalyticsEvent from '../Models/Analytics/AnalyticsEvent';
import { ReleaseCalendarType } from '../Models/ReleaseCalendarType';
import { SpotlightSeries } from '../Models/SpotlightSeries';
import { Spotlight } from '../Models/Spotlight';
import { HeroBanner } from '../Models/HeroBanner';
import AnalyticsReaderEvent from '../Models/Analytics/AnalyticsReaderEvent';
import Log from '../Kodansha/Services/Logger';
import { DashboardBadgeDTO } from '../Models/Badge';
import { TrilogyImportUpdates, XMLPriceMapping } from '../store/DashboardTrilogyImportStore';
import { Note } from '../Devon/Components/DashboardNotesTable';
import { DashboardSeries } from '../Models/DashboardSeries';
import { BrowsePageMenuType } from '../Models/BrowsePageMenu';
import { PriceTypeFlagEnum } from '../Models/BrowseData';
import { PublishersType } from '../store/Publishers';
import { InkyPenClaimCouponType } from '../Models/InkyPenClaimCoupon';
import { BatchXmlImportOverridesUpdateResponse } from '../Components/Dashboard/Series';
import { ChangeEmailRequest } from './AccountAPI';

export const DEFAULT_API_VERSION: string = '1.4';

class AccountSetting {
    key: string;
    value: string;

    constructor(_key: string, _value: string) {
        this.key = _key;
        this.value = _value;
    }
}

export interface ApiModel<T> {
    status: ApiStatusModel;
    response: T;
}

export interface ApiStatusModel {
    type: string;
    message: string;
    fullCount: number;
    geoRestriction?: GeoRestrictionStatus;
}

export type GeoRestrictionStatus =
    'NotRestricted'
    | 'Restricted'
    | 'RestrictedWithData'
    | 'RestrictedButAdmin'
    | 'RestrictedButOwned';

export enum InkyApiStatus {
    NotFound = 'NotFound',
    Default = 'Default',
}

export class InkyApiError extends Error {
    public status: InkyApiStatus;

    constructor(msg: string, status: InkyApiStatus) {
        super(msg);

        this.status = status;

        Object.setPrototypeOf(this, InkyApiError.prototype);
    }
}

export class InkyApiGeoRestrictedError extends InkyApiError {
    public payload = null;
    public geoRestrictionStatus: GeoRestrictionStatus = undefined;

    constructor(geoStatus: GeoRestrictionStatus, msg: string, payload: any | null) {
        super(msg, InkyApiStatus.Default);
        this.payload = payload;
        this.geoRestrictionStatus = geoStatus;
        Object.setPrototypeOf(this, InkyApiGeoRestrictedError.prototype);
    }
}

class InkyAPI {
    // Get the baseURL from the environment-variables.
    apiBaseUrl = process.env.REACT_APP_BASE_URL;


    /**
     * Gets the accessToken from the authState, and checks if its expired.
     * @returns string of accessToken is found and valid. null if not found or invalid.
     */
    getAccessToken(): string {
        return InkyApiV2.shared().AccessToken;
    }

    get<T>(url: string,
           cancelTokenSource?: CancelTokenSource,
           params?: string[][], apiVersion?: string,
    ): Promise<AxiosResponse<T>> {
        const token = this.getAccessToken(); // authClient.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }
        //console.log({desc:"Rhubarb", token:token, baseUrl: this.baseURL, subUrl: url, fullUrl: fullUrl.href});

        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return axios.get<T>(fullUrl.href, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
        });
    }

    getV2<T>(url: string, params?: string[][], apiVersion?: string): Promise<T> {
        const token = this.getAccessToken(); // authClient.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return axios.get(fullUrl.href, { headers: { Authorization: `Bearer ${token}` } })
            .then(function(response) {
                return response.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });

    }

    getV3<T>(url: string, accessToken: string = null, params?: string[][], apiVersion?: string): Promise<T> {

        const fullUrl = new URL(url, this.apiBaseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return axios.get(fullUrl.href, { headers: { Authorization: `Bearer ${accessToken}` } })
            .then(function(response) {
                return response.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });

    }

    getV4<T>(url: string, abortSignal: AbortSignal, params?: string[][], apiVersion?: string): Promise<T> {
        const accessToken = this.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return axios.get(fullUrl.href, { signal: abortSignal, headers: { Authorization: `Bearer ${accessToken}` } })
            .then(function(response) {
                return response.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }


    //ToDo: Payload should be any, not T. T is the return type.
    post<T>(url: string,
            payload: T,
            cancelTokenSource?: CancelTokenSource,
            apiVersion?: string,
    ): Promise<AxiosResponse<T>> {
        const token = this.getAccessToken(); // authClient.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return axios.post<T>(fullUrl.href, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
        });
    }

    postV2<T>(url: string,
              payload: any,
              cancelTokenSource?: CancelTokenSource,
              apiVersion?: string,
    ): Promise<AxiosResponse<T>> {
        const token = this.getAccessToken(); // authClient.getAccessToken();
        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return axios.post<T>(fullUrl.href, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'text/json',
            },
            cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
        });
    }

    /**
     *  T is payload type, U is return type
     * @param url
     * @param payload
     * @param abortSignal
     */
    postV4<T, U>(url: string, payload: T, abortSignal?: AbortSignal, apiVersion?: string): Promise<U> {
        const token = this.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return (axios.post<U>(fullUrl.href, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'text/json',
            },
            signal: abortSignal,
        })).then(res => res.data);
    }


    postForm(url: string, params?: string[][], apiVersion?: string): Promise<AxiosResponse<any>> {
        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return axios.post(fullUrl.href, this.getFormData(params), this.getAuthorizedConfig());
    }

    postData(url: string, data: Blob, params?: string[][], apiVersion?: string): Promise<AxiosResponse<any>> {
        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        const formData = this.getFormData(params);
        formData.append('file', data);
        return axios.post(fullUrl.href, formData, this.getAuthorizedConfig());
    }

    private getAuthorizedConfig(): any {
        const token = this.getAccessToken(); // authClient.getAccessToken();
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
    }

    private getFormData(params?: string[][]): FormData {
        const formData = new FormData();

        if (params) {
            for (let i = 0; i < params.length; i++) {
                formData.append(params[i][0], params[i][1]);
            }
        }

        return formData;
    }

    patch<T>(url: string,
             payload: T,
             cancelTokenSource?: CancelTokenSource,
             apiVersion?: string,
    ): Promise<AxiosResponse<T>> {
        const token = this.getAccessToken(); // auth0Client.getAccessToken();
        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        return axios.patch<T>(fullUrl.href, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
        });
    }

    // delete(url: string): Promise<AxiosResponse<void>> {
    //     const token = this.getAccessToken(); // authClient.getAccessToken();
    //     return axios.delete(this.apiBaseUrl + url, {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     });
    // }

    //region Response-fetchers for new endpoints
    async getResponse<T>(url: string, cancelTokenSource?: CancelTokenSource, params?: string[][], apiVersion?: string): Promise<ApiModel<T>> {
        const token = this.getAccessToken(); // authClient.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        try {
            const response = await axios.get<ApiModel<T>>(fullUrl.href, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
            });


            return response.data;
        } catch (err) {
            Log.error('Request failed', err);
            return null;
        }

    }


    /**
     * Using new Signal property, see: https://axios-http.com/docs/cancellation
     * @param url
     * @param abortSignal see: https://developer.mozilla.org/en-US/docs/Web/API/AbortController
     * @param params
     */
    async getResponseV2<T>(url: string, abortSignal?: AbortSignal, params?: string[][], apiVersion?: string): Promise<ApiModel<T>> {
        const token = this.getAccessToken(); // authClient.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        try {
            const response = await axios.get<ApiModel<T>>(fullUrl.href, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                signal: abortSignal ? abortSignal : null,
            });

            return response.data;
        } catch (err) {
            Log.error('Request failed', err);
            return null;
        }

    }

    async getData<T>(url: string, params?: string[][], apiVersion?: string): Promise<ApiModel<T>> {
        const token = this.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);


        const response = await axios.get<ApiModel<T>>(fullUrl.href, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const apiResponse = response.data;

        if (apiResponse.status.type == 'NotFound') {
            throw new InkyApiError('Object does not exist in backend', InkyApiStatus.NotFound);
        }

        return apiResponse;

    }

    async postResponse<T>(url: string, payload: any, cancelTokenSource?: CancelTokenSource, apiVersion?: string): Promise<ApiModel<T>> {
        const token = this.getAccessToken(); // auth0Client.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        const response = await axios.post<ApiModel<T>>(fullUrl.href, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
        });

        return response.data;
    }

    async patchResponse<T>(url: string, payload: any, cancelTokenSource?: CancelTokenSource, apiVersion?: string): Promise<ApiModel<T>> {
        const token = this.getAccessToken(); // auth0Client.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        const response = await axios.patch<ApiModel<T>>(fullUrl.href, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
        });

        return response.data;
    }

    async getFile<T>(url: string, payload: any, cancelTokenSource?: CancelTokenSource, apiVersion?: string) {
        const token = this.getAccessToken();

        const fullUrl = new URL(url, this.apiBaseUrl);
        fullUrl.searchParams.append('api-version', apiVersion ?? DEFAULT_API_VERSION);

        const response = await axios.post(fullUrl.href, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
            responseType: 'blob',
        });

        return response;
    }

    //endregion

    getSubscriptionWithCode(code: string,
    ): Promise<AxiosResponse<any>> {
        return this.get<any>(
            `url/getLongUrl/` + code,
            null,
        );
    }

    /* CONTENT */
    getHome(platform: string,
            cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<HomeModel>> {
        return this.get<HomeModel>(
            `/home/V2/?platform=${platform}`,
            cancelTokenSource,
        );
    }

    getSpotlight() {
        return this.get<Spotlight[]>(
            `/spotlight`,
        );
    }

    getPublishers() {
        return this.get<PublishersType[]>(
            `/publisher`,
        );
    }

    getPlan(cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<PaymentPlanModel>> {
        return this.get<PaymentPlanModel>(
            `/plan`,
            cancelTokenSource,
        );
    }

    getPages(comicId: number,
             cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<Page[]>> {
        const url = `/comic/${comicId}/pages`;
        return this.get<Page[]>(url, cancelTokenSource);
    }

    getMyComics(
        userid: number,
        cancelTokenSource: CancelTokenSource,
    ): AxiosPromise<Series[]> {
        const url = `/mycomics`;
        return this.get<Series[]>(url, cancelTokenSource);
    }

    getMyBooks(onlyPurchased: boolean, cancelTokenSource: CancelTokenSource): AxiosPromise<Comic[]> {

        const url = '/mycomics/?onlyPurchased=' + onlyPurchased;

        return this.get<Comic[]>(url, cancelTokenSource);
    }

    getMyPurchasedBooks(cancelTokenSource: CancelTokenSource): AxiosPromise<Comic[]> {
        const url = '/mycomics?withReadingProgress=false';
        return this.get<Comic[]>(url, cancelTokenSource);
    }

    getMyProcessingBooks(cancelTokenSource: CancelTokenSource): AxiosPromise<Comic[]> {
        const url = '/mycomics/processing';
        return this.get<Comic[]>(url, cancelTokenSource);
    }

    getReadingProgress(cancelTokenSource: CancelTokenSource): AxiosPromise<UserDownloadableResponse> {
        const url = '/mycomics/withReadingProgress';
        return this.get<UserDownloadableResponse>(url, cancelTokenSource);
    }

    addToMyComics(
        userid: number,
        id: number,
        cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<string>> {
        const url = `/mycomics/${userid}/${id}`;
        return this.post<string>(url, null, cancelTokenSource);
    }

    EmptyBookshelf(
        ClearReadingHistory: boolean,
        cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<string>> {
        let url = `/mycomics/EmptyBookshelf`;

        if (ClearReadingHistory) {
            url = url + '?wipeReadingHistory=true';
        }

        return this.post<string>(url, null, cancelTokenSource);
    }

    postMaxAgeRating(
        age: string,
        password: string,
        cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse> {
        const url = `/account/settings`;
        return this.post(url, {
            'Password': password,
            'UpdateSetting': {
                'key': 'maxshownratingkey',
                'value': age,
            },
        }, cancelTokenSource);
    }

    // postChangeEmail(
    //     data: ChangeEmailRequest,
    // ): Promise<AxiosResponse<ChangeEmailRequest>> {
    //     const url = `/account/ChangeEmail/V2/`;
    //     return this.post<ChangeEmailRequest>(url, data);
    // }

    postChangeEmail(
        NewEmail: string,
        Password: string,
    ): Promise<AxiosResponse> {
        const url = `/account/initiateChangeEmail`;
        return this.post(url, { NewEmail, Password });
    }


    postChangeEmailToken(
        Token: string,
    ): Promise<AxiosResponse> {
        const url = `/account/completeChangeEmail`;
        return this.post(url, { Token });
    }


    postRemoveSeriesReadingHistory(
        SeriesId: number,
    ): Promise<AxiosResponse> {
        const url = `/mycomics/wipe-reading-history`;
        return this.post(url, { SeriesId, 'ProductId': null });
    }

    postRemoveAllReadingHistory(): Promise<AxiosResponse> {
        const url = `/mycomics/wipe-reading-history`;
        return this.post(url, { All: true });
    }

    claimCoupon(
        code: string,
        cancelTokenSource?: CancelTokenSource,
    ): Promise<AxiosResponse<InkyPenClaimCouponType>> {
        const url = `/coupons/claim`;
        return this.postV2<InkyPenClaimCouponType>(url, { code: code }, cancelTokenSource);
    }

    async postFeedback(feedbackEmail: string, feedbackMessage: string) {
        const response = await this.post('/home/contact', { feedbackEmail, feedbackMessage }, null);
        return response;
    }

    removeFromMyComics(
        userid: number,
        id: number,
        cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<void>> {
        const url = `/mycomics/remove/${userid}/${id}`;
        return this.patch<void>(url, null, cancelTokenSource);
    }

    setUserReadingProgress(
        userID: number,
        ucd: UserComicData,
        cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<UserComicData>> {
        const url = `/mycomics/${userID}`;
        return this.patch<UserComicData>(url, ucd, cancelTokenSource);
    }

    getCarousel(cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<CarouselEndpointResponseType>> {

        return this.get<CarouselEndpointResponseType>(
            '/discover/carousel?api-version=1.4&platform=web',
            cancelTokenSource,
        );
    }

    //ToDO: Change to DiscoverItem[]
    getDiscover(
        currentType: number,
        currentSort: number,
        currentGenre: number,
        includeSeries: boolean,
        subCategory: string,
        cancelTokenSource: CancelTokenSource,
        start?: number,
        count?: number,
        widths?: number,
        publishStatus?: number,
    ): Promise<ApiModel<DiscoverItem[]>> {
        let url = '/discover/v2';

        const params = [];
        if (currentType > -1) params.push('type=' + currentType);
        if (currentSort > -1) params.push('sort=' + currentSort);
        if (currentGenre > -1) params.push('genreIds=' + currentGenre);
        if (subCategory !== '') params.push('subCategory=' + subCategory);
        params.push('includeSeries=' + includeSeries);
        if (widths > -1) params.push('widths=' + widths);
        if (publishStatus > -1) params.push('publishStatus=' + publishStatus);

        if (start != null) {
            params.push(`fromIndex=${start.valueOf()}`);
            if (count && count > -1) params.push(`count=${count.valueOf()}`);
        }

        if (params.length > 0) {
            url += '?' + params.filter(p => !p.endsWith('null')).join('&');
        }
        console.log(url);
        return this.getResponse<DiscoverItem[]>(url, cancelTokenSource);
    }

    newReleaseCalendar(type: 'manga' | 'book') {
        return this.getResponse<ReleaseCalendarType>('/product/ReleaseCalendar?filterBy=' + type);
    }

    getDiscoverVariant(
        currentType: number,
        currentSort: number,
        currentGenre: string,
        includeSeries: boolean,
        subCategory: string,
        cancelTokenSource: CancelTokenSource,
        start?: number,
        count?: number,
        widths?: number,
        publishStatus?: number,
        newOnly?: boolean,
        ageRatings?: string,
        seriesStatus?: number,
        isFree?: boolean,
    ): Promise<ApiModel<DiscoverItem[]>> {
        let url = '/product/getPlanned';

        const params = [];
        if (currentType > -1) params.push('type=' + currentType);
        if (currentSort > -1) params.push('sort=' + currentSort);
        if (currentGenre?.length > 0) params.push('genreIds=' + currentGenre);
        if (subCategory !== '') params.push('subCategory=' + subCategory);
        params.push('includeSeries=' + includeSeries);
        if (widths > -1) params.push('widths=' + widths);
        if (publishStatus > -1) params.push('publishStatus=' + publishStatus);
        if (newOnly) params.push('newOnly=' + newOnly);
        if (isFree) params.push('isFree=' + isFree);
        if (ageRatings?.length > 0) params.push('ageRating=' + ageRatings);
        if (seriesStatus > -1) params.push('seriesStatus=' + seriesStatus);

        if (start != null) {
            params.push(`fromIndex=${start.valueOf()}`);
            if (count && count > -1) params.push(`count=${count.valueOf()}`);
        }

        if (params.length > 0) {
            url += '?' + params.filter(p => !p.endsWith('null')).join('&');
        }
        Log.debug(url);
        return this.getResponse<DiscoverItem[]>(url, cancelTokenSource);
    }

    getDiscoverV2(
        currentTypeIds: number[],
        currentSort: number,
        currentGenre: string,
        includeSeries: boolean,
        subCategory?: string,
        cancelTokenSource?: CancelTokenSource,
        start?: number,
        count?: number,
        widths?: number,
        scheduleStatus?: number,
        newOnly?: boolean,
        ageRatings?: string,
        seriesStatus?: number,
        downloadablePriceTypes?: PriceTypeFlagEnum,
        publishStatus?: number,
        sale?: boolean,
        isOnSpotlight?: boolean,
        category?: number,
        format?: number,
        shippablePriceTypes?: PriceTypeFlagEnum,
    ): Promise<ApiModel<DiscoverItem[]>> {
        let url = '/discover/v2';

        const params: string[] = [];
        if (currentTypeIds?.length >0) params.push('typeIds=' + currentTypeIds.join(','));
        if (currentSort > -1) params.push('sort=' + currentSort);
        if (currentGenre?.length > 0) params.push('genreIds=' + currentGenre);
        if (subCategory && subCategory !== '') params.push('subCategory=' + subCategory);
        params.push('includeSeries=' + includeSeries);
        if (widths > -1) params.push('widths=' + widths);
        if (scheduleStatus > -1) params.push('scheduleStatus=' + scheduleStatus);
        if (newOnly) params.push('newOnly=' + newOnly);
        if (downloadablePriceTypes) params.push('downloadablePriceTypes=' + downloadablePriceTypes);
        if (shippablePriceTypes) params.push('shippablePriceTypes=' + shippablePriceTypes);
        if (ageRatings?.length > 0) params.push('ageRatings=' + ageRatings);
        if (seriesStatus > -1) params.push('seriesStatus=' + seriesStatus);
        if (publishStatus > -1) params.push('publishStatus=' + publishStatus);
        if (sale) params.push('isOnSale=' + sale);
        if (isOnSpotlight) params.push('showSpotLightInfo=' + isOnSpotlight);
        if (category !== undefined) params.push('category=' + category);
        if (format > -1) params.push('distributionType=' + format);

        if (start != null) {
            params.push(`fromIndex=${start.valueOf()}`);
            if (count && count > -1) params.push(`count=${count.valueOf()}`);
        }

        if (params.length > 0) {
            url += '?' + params.filter(p => !p.endsWith('null')).join('&');
        }
        Log.debug('aaa ' + url);
        return this.getResponse<DiscoverItem[]>(url, cancelTokenSource);
    }

    getDiscoverV2Fixed(
        currentSort: number,
        currentGenre: string,
        includeSeries: boolean,
        subCategory?: string,
        excludeIsInLibrary?: boolean,
        cancelTokenSource?: CancelTokenSource,
        start?: number,
        count?: number,
        widths?: number,
        scheduleStatus?: number,
        newOnly?: boolean,
        ageRatings?: string,
        seriesStatus?: number,
        publishStatus?: number,
        sale?: boolean,
        isOnSpotlight?: boolean,
        category?: number,
        distributionType?: number,
        isFree?: boolean,
        type?: number,
        publisherId?: number,
    ): Promise<ApiModel<DiscoverItem[]>> {
        let url = '/discover/v2';

        const params: string[] = [];
        if (type) params.push('type=' + type);
        if (publisherId) params.push('publisherId=' + publisherId);
        if (currentSort > -1) params.push('sort=' + currentSort);
        if (currentGenre?.length > 0) params.push('genreIds=' + currentGenre);
        if (excludeIsInLibrary) params.push('isInUserLibrary=false');
        if (subCategory && subCategory !== '') params.push('subCategory=' + subCategory);
        params.push('includeSeries=' + includeSeries);
        if (widths > -1) params.push('widths=' + widths);
        if (scheduleStatus > -1) params.push('scheduleStatus=' + scheduleStatus);
        if (newOnly) params.push('newOnly=' + newOnly);
        if (isFree) params.push('isFree=' + isFree);
        if (ageRatings?.length > 0) params.push('ageRatings=' + ageRatings);
        if (seriesStatus > -1) params.push('seriesStatus=' + seriesStatus);
        if (publishStatus > -1) params.push('publishStatus=' + publishStatus);
        if (sale) params.push('isOnSale=' + sale);
        if (isOnSpotlight) params.push('showSpotLightInfo=' + isOnSpotlight);
        if (category !== undefined) params.push('category=' + category);
        if (distributionType !== undefined) params.push('distributionType=' + distributionType);

        if (start != null) {
            params.push(`fromIndex=${start.valueOf()}`);
            if (count && count > -1) params.push(`count=${count.valueOf()}`);
        }

        if (params.length > 0) {
            url += '?' + params.filter(p => !p.endsWith('null')).join('&');
        }
        Log.debug('aaa ' + url);
        return this.getResponse<DiscoverItem[]>(url, cancelTokenSource);
    }

    getBookshelf(
        currentSort: number,
        includeSeries: boolean,
        searchQuery?: string,
        cancelTokenSource?: CancelTokenSource,
        start?: number,
        count?: number,
        widths?: number,
    ): Promise<ApiModel<DiscoverItem[]>> {
        let url = '/discover/v2';

        const params: string[] = ['isInUserLibrary=true'];
        // const params: string[] = [''];
        if (currentSort > -1) params.push('sort=' + currentSort);
        if (searchQuery !== undefined) params.push('seriesTitleSearchTerm=' + searchQuery);
        params.push('includeSeries=' + includeSeries);
        if (widths > -1) params.push('widths=' + widths);

        if (start != null) {
            params.push(`fromIndex=${start.valueOf()}`);
            if (count && count > -1) params.push(`count=${count.valueOf()}`);
        }

        if (params.length > 0) {
            url += '?' + params.filter(p => !p.endsWith('null')).join('&');
        }
        Log.debug('aaa ' + url);
        return this.getResponse<DiscoverItem[]>(url, cancelTokenSource);
    }


    getDiscoverSeries(
        currentType: number,
        currentSort: number,
        currentGenre: number,
        cancelTokenSource: CancelTokenSource,
        start?: number,
        count?: number,
        widths?: number,
    ): Promise<AxiosResponse<Series[]>> {
        let url = '/discover/series';

        const params = [];
        if (currentType > -1) params.push('type=' + currentType);
        if (currentSort > -1) params.push('sort=' + currentSort);
        if (currentGenre > -1) params.push('genre=' + currentGenre);
        if (widths > -1) params.push('widths=' + widths);

        if (start != null) {
            params.push(`fromIndex=${start.valueOf()}`);
            params.push(`count=${count.valueOf()}`);
        }

        if (params.length > 0) {
            url += '?' + params.filter(p => !p.endsWith('null')).join('&');
        }

        return this.get<Series[]>(url, cancelTokenSource);
    }

    async getDiscoverByID(
        id: number,
        cancelTokenSource: CancelTokenSource,
        fromIndex?: number,
        count?: number,
        widths?: string,
    ): Promise<ApiModel<DiscoverWheel>> {
        const params = [];

        if (fromIndex) {
            params.push(['fromIndex', '' + fromIndex]);
        }
        if (count) {
            params.push(['count', '' + count]);
        }
        if (widths) {
            params.push(['widths', widths]);
        }
        return await this.getResponse<DiscoverWheel>('/discover/' + id, cancelTokenSource, params);
    }

    getBrowsePageTypes(cancelTokenSource?: CancelTokenSource,
    ): Promise<AxiosResponse<BrowsePageMenuType[]>> {
        return this.get<BrowsePageMenuType[]>('/type', cancelTokenSource);
    }

    getSeriesTypes(cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<FilterTuple[]>> {
        return this.get<FilterTuple[]>('/type', cancelTokenSource);
    }

    getSeriesGenres(cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<FilterTuple[]>> {
        return this.get<FilterTuple[]>('/genre', cancelTokenSource);
    }


    async PurchaseWithWebsite(all: any, paymentId: string) {
        return this.post('/order/purchase?platform=web', {
            ...all,
            StripeNonce: paymentId,
            paymentMethod: 'Card',
        })
            .then((response) => {
                console.log('Purchase Success purchasing:');
                return response.data;
            })
            .catch((error) => {
                console.error('Purchase Error purchasing:', error.response.data);
                return error.response.data;
            });
    }

    async getAllGenres(cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<BrowseGenre[]>> {
        return this.get<BrowseGenre[]>('/genre', cancelTokenSource);
    }

    getSortOrders(cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<FilterTuple[]>> {
        return this.get<FilterTuple[]>(
            '/discover/sortorder',
            cancelTokenSource,
        );
    }

    async getSeries(id: string | number, widths: string,
                    cancelTokenSource: CancelTokenSource,
    ): Promise<Series> {
        const params = [];
        params.push(['widths', widths]);

        const apiResponse = await this.getData<Series>('/series/V2/' + id, params);

        switch (apiResponse.status.geoRestriction) {
            case 'NotRestricted':
                return apiResponse.response;
            case 'Restricted':
                throw new InkyApiGeoRestrictedError(apiResponse.status.geoRestriction, apiResponse.status.message, undefined);
            case 'RestrictedButAdmin':
            case 'RestrictedButOwned':
            case 'RestrictedWithData':
                throw new InkyApiGeoRestrictedError(apiResponse.status.geoRestriction, apiResponse.status.message, apiResponse.response);
            default:
                throw new Error('Unknown GeoRestrictionStatus: ' + apiResponse.status.geoRestriction);
        }
    }


    getComicsForSeries(seriesId: number,
                       cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<Comic[]>> {
        return this.get<Comic[]>(
            `/comic/forSeries/V3/${seriesId}?platform=web`,
            cancelTokenSource,
        );
    }

    async getProductsForSeries(seriesId: number,
                               cancelTokenSource: CancelTokenSource,
    ): Promise<Product[]> {
        const productDTO = await this.get<ProductDTO[]>(
            `/product/forSeries/${seriesId}?platform=web`,
            cancelTokenSource,
        );

        return productDTO.data.map(x => Product.createFromApi(x));
    }

    getComics(seriesIds: string[], cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<Comic[]>> {
        return this.postV2<Comic[]>(
            `/comic/fetch-list/?widths=250,500`,
            JSON.stringify(seriesIds),
            cancelTokenSource,
        );
    }

    getComicByID(id: number, cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<Comic>> {
        return this.get<Comic>('/comic/' + id, cancelTokenSource);
    }

    /* IP Tags */

    async getIPTags(cancelTokenSource: CancelTokenSource): Promise<ApiModel<IpTag[]>> {
        return await this.getResponse('/iptag/', cancelTokenSource);
    }

    async getIPTagByID(id: number, cancelTokenSource: CancelTokenSource): Promise<ApiModel<IpTag>> {
        return await this.getResponse('/iptag/' + id, cancelTokenSource);
    }

    async getMerchByID(id: number, cancelTokenSource: CancelTokenSource): Promise<ApiModel<GamesAndMerch>> {
        return await this.getResponse('/product/' + id, cancelTokenSource);
    }

    async getProductById(id: string | number, cancelTokenSource: CancelTokenSource, width: number = null): Promise<Product> {
        const params = [];
        if (width != null) {
            params.push(['widths', width]);
        }
        // TODO: Make it so that NotFound throws and we catch it in the getResponse.

        const apiResponse = await this.getData<ProductDTO>('/product/' + id, params, null);

        switch (apiResponse.status.geoRestriction) {
            case 'NotRestricted':
                return Product.createFromApi(apiResponse.response);
            case 'Restricted':
                throw new InkyApiGeoRestrictedError(apiResponse.status.geoRestriction, apiResponse.status.message, undefined);
            case 'RestrictedButAdmin':
            case 'RestrictedButOwned':
            case 'RestrictedWithData':
                throw new InkyApiGeoRestrictedError(apiResponse.status.geoRestriction, apiResponse.status.message, Product.createFromApi(apiResponse.response));
            default:
                throw new Error('Unknown GeoRestrictionStatus: ' + apiResponse.status.geoRestriction);
        }
    }

    async getProductBySeriesUrlAndVolume(seriesReadableUrl: string, volumeNumber: string, chapterNumber: string = null, abortSignal?: AbortSignal, width: number = null): Promise<Product> {

        const params = [];
        if (width != null) {
            params.push(['widths', width]);
        }
        if (chapterNumber !== undefined && chapterNumber !== null && chapterNumber !== '') {
            params.push(['chapterNumber', chapterNumber]);
        }
        // TODO: Make it so that NotFound throws and we catch it in the getResponse.

        try {
            const apiResponse = await this.getV4<ApiModel<ProductDTO>>(`/product/${seriesReadableUrl}/${volumeNumber}`, abortSignal, params);

            if (apiResponse.status.type !== 'Success') {
                throw new InkyApiError(apiResponse.status.message, InkyApiStatus.Default);
            } else {
                const product = Product.createFromApi(apiResponse.response);
                return product;
            }
        } catch (e) {
            if (e instanceof InkyApiError) {
                throw e; // Pass it down
            } else {
                throw new Error(e);
            }
        }
    }

    /* BUNDLES */

    getBundleComics(bundleId: number, cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<Comic[]>> {
        return this.get<Comic[]>('/comic/forBundle/' + bundleId,
            cancelTokenSource);
    }

    getBundles(cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<Bundle[]>> {
        return this.get<Bundle[]>('/discover/bundles/', cancelTokenSource);
    }

    /* ORDER CONFIRMATION */

    async getUserOrder(cancelTokenSource: CancelTokenSource, orderId: number): Promise<ApiModel<OrderConfirmationModel>> {
        return await this.getResponse<OrderConfirmationModel>('/order/' + orderId.toString(), cancelTokenSource);
    }

    async getUserOrders(cancelTokenSource: CancelTokenSource): Promise<Order[]> {

        let orders = [];
        const apiResponse: Promise<ApiModel<OrderDTO[]>> = this.getResponse('/order/', cancelTokenSource);
        await apiResponse.then(x => {
            console.debug('API: Successfully recieved shippingRates', x);
            orders = x.response.map(x => Order.createFromApi(x));
            console.debug('Shipping Rates are', orders);
        }).catch(e => {
            console.error('[InkyAPI:getUserOrders] API-error:', e);
            orders = [];
        });
        return orders;

    }

    async getAccountPageOrders(cancelTokenSource: CancelTokenSource, maxItemCount?: number, first?: number): Promise<OrdersCount> {

        const params = [];

        if (maxItemCount) {
            params.push(['maxItemCount', '' + maxItemCount]);
        }

        if (first) {
            params.push(['first', '' + first]);
        }

        let orders = [];
        let amount = 0;
        const apiResponse: Promise<ApiModel<OrderDTO[]>> = this.getResponse('/order/', cancelTokenSource, params);
        await apiResponse.then(x => {
            console.debug('API: Successfully recieved shippingRates', x);
            orders = x.response.map(x => Order.createFromApi(x));
            amount = x.status.fullCount;
        }).catch(e => {
            console.error('[InkyAPI:getAccountPageOrders] API-error:', e);
            orders = [];
        });

        const value = new OrdersCount();
        value.Orders = orders;
        value.Count = amount;

        return value;
    }

    /* NEWS */
    async getNews(cancelTokenSource: CancelTokenSource, maxItemCount?: number, first?: number, widths?: string): Promise<ApiModel<NewsModel[]>> {
        const params = [['pageType', 'News']];

        if (maxItemCount) {
            params.push(['maxItemCount', '' + maxItemCount]);
        }

        if (first) {
            params.push(['first', '' + first]);
        }

        if (widths) {
            params.push(['widths', '' + widths]);
        }

        return await this.getResponse('/news', cancelTokenSource, params);
    }

    async getNewsV2(maxItemCount?: number, first?: number, widths?: string, sort?: number): Promise<Article[]> {
        const params = [['pageType', 'News']];

        if (maxItemCount) {
            params.push(['maxItemCount', '' + maxItemCount]);
        }

        if (first) {
            params.push(['first', '' + first]);
        }

        if (widths) {
            params.push(['widths', '' + widths]);
        }

        if (sort) {
            params.push(['sort', '' + sort]);
        }

        Log.debug('getNewsV2', params);
        const response = await this.getResponse<Article[]>('/news', null, params);

        return response.response;
    }

    async getArticle(articleId: string, widths?: number) {
        const params = [];

        if (widths) {
            params.push(['widths', '' + widths]);
        }

        const response = await this.getResponse<ArticleDTO>(`news/strips-article/${articleId}`, null, params);
        if (response.status.type === 'Success') {
            return Article.createFromApi(response.response);
        }
        return null;
    }

    async getArticlePreview(url: string, widths?: number) {
        const params = [];
        if (widths) {
            params.push(['widths', '' + widths]);
        }
        const response = await this.getResponse<ArticleDTO>(`news/strips-article-preview/${url}`, null, params);
        return Article.createFromApi(response.response);
    }

    async getSpotlightSeries() {
        const response = await this.get<ApiModel<SpotlightSeries[]>>('dashboard/spotlight', null);
        return response;
    }

    async newSpotlightSeries(spotlight: SpotlightSeries) {
        const response = await this.post('/dashboard/spotlight/new', spotlight, null);
        return response;
    }

    async editSpotlightSeries(spotlight: SpotlightSeries) {
        const response = await this.post('/dashboard/spotlight/alter', spotlight, null);
        return response;
    }

    async publishSpotlightSeries(spotlight: SpotlightSeries) {
        const response = await this.post('/dashboard/spotlight/publish?id=' + spotlight.id, null, null);
        return response;
    }

    async unPublishSpotlightSeries(spotlight: SpotlightSeries) {
        const response = await this.post('/dashboard/spotlight/unpublish?id=' + spotlight.id, null, null);
        return response;
    }

    async removeSpotLightSeries(spotlight: SpotlightSeries) {
        const response = await this.post('/dashboard/spotlight/remove?id=' + spotlight.id, null, null);
        return response;
    }

    async getHeroBanner() {
        const response = await this.get<HeroBanner>('/herobanner', null);
        return response;
    }

    async getHeroBannerDashboard() {
        const response = await this.get<HeroBanner[]>('/dashboard/herobanner', null);
        return response;
    }

    async updateHeroBanner(hero: HeroBanner) {
        const response = await this.post('/dashboard/herobanner/update', hero, null);
        return response;
    }

    async getUserBadges() {
        const response = await this.get('/userbadge/get', null);
        return response;
    }

    async getAllUserBadges(abortSignal?: AbortSignal) {
        const response = await this.getV4<ApiModel<DashboardBadgeDTO[]>>('/dashboard/userbadge', abortSignal);
        return response;
    }

    async alterUserBadge(payload: DashboardBadgeDTO, abortSignal?: AbortSignal) {
        const response = await this.postV4<DashboardBadgeDTO, ApiModel<DashboardBadgeDTO>>('/dashboard/userbadge/alter', payload, abortSignal);
        return response;
    }

    async createNewUserBadge(payload: DashboardBadgeDTO, abortSignal?: AbortSignal) {
        const response = await this.postV4<DashboardBadgeDTO, ApiModel<DashboardBadgeDTO>>('/dashboard/userbadge/create', payload, abortSignal);
        return response;
    }


    async claimUserBadge(badgeId: number) {
        const response = await this.post('/userbadge/claim?badgeId=' + badgeId, null, null);
        return response;
    }

    async checkClaimComics(userId: number) {
        const response = await this.get('/userbadge/has/ax?userId=' + userId, null, null);
        return response;
    }

    async ClaimAxComics(userId: number, email: string) {
        const response = await this.post('/userbadge/claim/ax?userId=' + userId + '&email=' + email, null, null);
        return response;
    }


    /* UPLOAD XML TRILOGY EXPORTS */


    async ValidateTrilogyExport(form: FormData, abortSignal?: AbortSignal) {
        const response = await this.postV4<FormData, ApiModel<TrilogyImportUpdates>>('/dashboard/trilogy-import/validate', form, abortSignal);

        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async ValidateAndMergeTrilogyExport(form: FormData, abortSignal?: AbortSignal) {
        const response = await this.postV4<FormData, ApiModel<TrilogyImportUpdates>>('/dashboard/trilogy-import/validateAndMerge', form, abortSignal);

        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async GetXMLImportPriceMappings(abortSignal?: AbortSignal) {
        const response = await this.getV4<ApiModel<XMLPriceMapping[]>>('/dashboard/trilogy-import/price-mappings', abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async AddXMLImportPriceMappings(priceMappings: Pick<XMLPriceMapping, 'rrp' | 'price'>[], abortSignal?: AbortSignal) {
        const response = await this.postV4<Pick<XMLPriceMapping, 'rrp' | 'price'>[], ApiModel<XMLPriceMapping[]>>('/dashboard/trilogy-import/price-mappings/create', priceMappings, abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async RemoveXMLImportPriceMappings(priceMappingIds: number[], abortSignal?: AbortSignal) {
        const response = await this.postV4<number[], ApiModel<XMLPriceMapping[]>>('/dashboard/trilogy-import/price-mappings/remove', priceMappingIds, abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async UpdateXMLImportSeriesInclusions(seriesIds: number[], newState: boolean, abortSignal?: AbortSignal) {

        const response = await this.postV4<number[], ApiModel<BatchXmlImportOverridesUpdateResponse>>('/dashboard/series/batch-xml-import-overrides-update?isInXmlImportInclusionList=' + newState, seriesIds, abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async BatchCreateSeriesNote(seriesIds: number[], title: string, message: string, abortSignal?: AbortSignal) {

        const response = await this.postV4<number[],
            ApiModel<string>>('/dashboard/series/batch-create-note?title=' + title + '&message=' + message, seriesIds, abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async BatchRemoveSeries(seriesIds: number[], abortSignal?: AbortSignal) {

        const response = await this.postV4<number[],
            ApiModel<string>>('/dashboard/series/batch-remove', seriesIds, abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async BatchGetSeries(seriesIds: number[], abortSignal?: AbortSignal) {
        let seriesIdString = '?';
        for (let i = 0; i < seriesIds.length; i++) {
            seriesIdString += 'ids=' + seriesIds[i];
            if (i < seriesIds.length - 1) {
                seriesIdString += '&';
            }
        }
        const response = await this.getV4<ApiModel<any[]>>('dashboard/series/batch-get' + seriesIdString, abortSignal);
        if (response.status.type === 'Success') {
            return response.response.map(x => {
                const ds = new DashboardSeries();
                ds.original = x;
                ds.copyFromOriginal();
                return ds;
            });
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async BatchPublishSeries(seriesIds: number[], abortSignal?: AbortSignal) {

        const response = await this.postV4<number[],
            ApiModel<string>>('/dashboard/series/batch-publish', seriesIds, abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async BatchUnPublishSeries(seriesIds: number[], abortSignal?: AbortSignal) {

        const response = await this.postV4<number[],
            ApiModel<string>>('/dashboard/series/batch-unpublish', seriesIds, abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    async GetSeriesNotes(seriesId: number, abortSignal?: AbortSignal) {
        const response = await this.getV4<ApiModel<Note[]>>('/dashboard/series/' + seriesId + '/notes', abortSignal);
        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(response.status.message, InkyApiStatus.Default);
        }
    }

    /* SEARCH */

    search(query: string,
           cancelTokenSource: CancelTokenSource,
    ): Promise<ApiModel<DiscoverItem[]>> {
        return this.getResponse<DiscoverItem[]>(
            `/search/V3?query=${query}&platform=web`,
            cancelTokenSource,
        );
    }


    searchAndCancel(query: string,
                    includeHighlights:boolean,
                    cancelTokenSource: CancelTokenSource,
    ): {
        res: Promise<ApiModel<DiscoverItem[]>>;
        controller: CancelTokenSource;
    } {
        const cancelToken = Axios.CancelToken.source();
        let url =  `/search/V3?query=${query}&platform=web&showSpotLightInfo=true`;
        if(includeHighlights){
            url += '&includeHighlights=true';
        }
        return {
            res: this.getResponse<DiscoverItem[]>(
               url,
                cancelToken,
            ), controller: cancelToken,
        };
    }


    searchSuggestions(query: string,
                      cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<SearchResult[]>> {
        return this.get<SearchResult[]>(
            '/search/suggestions/' + query + '?field=series',
            cancelTokenSource,
        );
    }

    /* ACCOUNT */


    account(cancelTokenSource?: CancelTokenSource,
    ): Promise<AccountInfo> {
        return this.getV2<AccountInfo>('/account');
    }

    getAccountInfo(accessToken: string = null): Promise<AccountInfo> {
        return this.getV3<AccountInfo>('/account', accessToken);
    }

    setAccountSetting(
        key: string,
        value: string,
        cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<AccountSetting>> {
        return this.post<AccountSetting>(
            `/account/settings/${key}`,
            new AccountSetting(key, value),
            cancelTokenSource,
        );
    }

    resetPassword(
        Email,
        Password,
        NewPassword,
        ConfirmNewPassword,
        cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse> {
        return this.post('account/changePassword/V2', {
            Email,
            Password,
            NewPassword,
            ConfirmNewPassword,
        }, cancelTokenSource);
    }

    forgotPassword(email: string) {
        Log.debug(email);

        const encodedEmail = encodeURIComponent(email);
        return this.post(`/account/forgotPassword?email=${encodedEmail}`, null, null);
    }

    changePassword(password: string) {
        Log.debug('inky-api password', password);
        return this.post('/account/changePassword?password=' + password, null, null);
    }

    sendEvent(accountInfo: AccountInfo, analyticsEvent: AnalyticsEvent) {
        let userId = 0; //Default if no user
        if (accountInfo !== null) {
            userId = accountInfo.userid;
            analyticsEvent.userId = userId;

            if (userId == 0) {
                analyticsEvent.userId = null;
            }
        }

        return this.post('/analytics/sendEvent', analyticsEvent, null);
    }

    sendReaderEvent(accountInfo: AccountInfo, analyticsReaderEvent: AnalyticsReaderEvent) {
        let userId = 0; //Default if no user
        if (accountInfo !== null) {
            userId = accountInfo.userid;
            analyticsReaderEvent.userId = userId;

            if (userId == 0) {
                analyticsReaderEvent.userId = null;
            }
        }
        return this.post('/analytics/sendReaderEvent', analyticsReaderEvent, null);
    }

    resendVerification(cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<void>> {
        return this.post<void>('/account/resendVerification', null, cancelTokenSource);
    }

    validateUserPassword(email: string, password: string) {
        return this.post('/account/token', { UserName: email, Password: password });
    }

    login(email: string, password: string) {
        return this.post('/account/token', { UserName: email, Password: password })
            .then(res => {
                Log.debug('login', res.data);
                const data = res.data as unknown;
                return data as LoginResponse;
            });
    }

    refresh(refreshToken: string) {
        return this.post('/account/token', { refresh_token: refreshToken })
            .then(res => {
                Log.debug('Refresh response received..');
                const data = res.data as LoginResponse;
                return data as LoginResponse;
            });
    }

    signup(userDetails: CreateUserPayload) {
        return this.post('/account/signupCustomer', userDetails).then(res => {
                Log.debug('Signup response received..');
                Log.debug('Signup result:' + res.status);
                if (res.status === 200) {
                    return res.status;
                } else {
                    if (res.status === 406) {
                        throw new Error('signup.failed' + res.statusText);
                    }
                    Log.debug('signup.failed.');
                }
            },
        );
    }

    registerCartData(cancelTokenSource: CancelTokenSource, json: string) {
        return this.post('/analytics/updateCart', { json: json }, cancelTokenSource);
    }


    /* INSTRUMENTATION */
    sendInstrumentationData(data: InstrumentationSessionList,
                            cancelTokenSource: CancelTokenSource,
    ): Promise<AxiosResponse<InstrumentationSessionList>> {
        return this.post<InstrumentationSessionList>('/account/instrumentation', data, cancelTokenSource);
    }

    async checkSteamSubscription() {
        if (window.electron) {
            const steamId = await window.electron.getSteamId();

            console.log('Steam ID is: ' + steamId);

            if (steamId) {
                return await this.get('/steam/hasValidSteamSubscription/' + steamId, null);
            }
        } else {
            return false;
        }
    }

    async initTxnSteam(subscriptionTierId) {
        const steamId = await window.electron.getSteamId();

        console.log('Steam ID: ' + steamId);

        if (steamId) {
            const formData = new FormData();
            return await this.post('steam/initTransaction/' + steamId + '/' + subscriptionTierId, formData, null);
        }
    }

    /* async queryTxnSteam(orderId) {
         if (orderId) {
             return await this.get('steam/queryTransaction/' + orderId);
         }
     }*/
}

const apiClient = new InkyAPI();

export default apiClient;
