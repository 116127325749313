import dayjs from 'dayjs';

export class InkyPortStockInfo {
    warehouseCodeName: string;
    available: number;
    lastUpdated: Date;

    static createFromApi(dto: InkyPortStockInfoDTO): InkyPortStockInfo {
        return Object.assign(new InkyPortStockInfo(), dto, {
            lastUpdated: dayjs(dto.lastUpdated).toDate(),
        });
    }
}

interface InkyPortStockInfoDTO {
    warehouseCodeName: string;
    available: number;
    lastUpdated: string;
}

export class InkyPortProductInfo {
    sku: string;
    name: string;
    stocks: InkyPortStockInfo[];

    getStockCount(): number {
        let total = 0;
        for (let i = 0; i< this.stocks.length; i++) {
            total += this.stocks[i].available;
        }

        return total;
    }

    static createFromApi(dto: InkyPortProductInfoDTO): InkyPortProductInfo {
        return Object.assign(new InkyPortProductInfo(), dto, {
            stocks: dto.stocks.map(x => InkyPortStockInfo.createFromApi(x)),
        });
    }
}

export interface InkyPortProductInfoDTO {
    sku: string;
    name: string;
    stocks: InkyPortStockInfoDTO[];
}