//const cdnPath: string = "https://d3nqfnht5m17hp.cloudfront.net"; // Staging
const cdnPath: string = 'https://cdn.kodansha.us'; // PROD

export const GoodiesData = [
    {
        name: 'Vinland Saga',
        author: 'Makoto Yukimura',
        image: cdnPath + '/statics/comics/2020/12/VinlandSaga_Series_IMG_1200x960.jpg',
        color: 'navy',
        readableUrl: 'vinland-saga',
        reader: 28740,
        genres: ['DRAMA', 'HISTORICAL', 'ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
    {
        name: 'Burn the House Down',
        author: 'Moyashi Fujisawa',
        image: cdnPath + '/statics/comics/2022/06/Burn-the-House-Down-Series_IMG_1200x960.jpg',
        color: 'pink',
        readableUrl: 'burn-the-house-down',
        reader: 30916,
        genres: ['DRAMA', 'THRILLER'],
    },
    {
        name: 'PTSD Radio',
        author: 'Masaaki Nakayama',
        image: cdnPath + '/statics/comics/2020/12/PTSD-Radio_Series_IMG_1200x960.jpg',
        color: 'blue',
        readableUrl: 'ptsd-radio',
        reader: 22278,
        genres: ['HORROR'],
    },
    {
        name: 'Miraculous: Tales of Ladybug & Cat Noir (Manga)',
        author: 'Riku Tsuchida',
        image: cdnPath + '/statics/Series/728_8e4ce0c9-f0c4-410f-ad5a-d9e4cfb9f4ff_1200.jpg',
        color: 'pink',
        readableUrl: 'miraculous-tales-of-ladybug-and-cat-noir',
        reader: 31623,
        genres: ['ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
    {
        name: 'The Drops of God',
        author: 'Shu Okimoto and Tadashi Agi',
        image: cdnPath + '/statics/comics/2020/12/DropsOfGod_Series_IMG_1200x960.jpg',
        color: 'green',
        readableUrl: 'the-drops-of-god',
        reader: 30830,
        genres: ['DRAMA', 'FOOD'],
    },
    {
        name: 'The Fable',
        author: 'Katsuhisa Minami',
        image: cdnPath + '/statics/comics/2022/03/TheFable_Series_IMG_1200x960.jpg',
        color: 'orange',
        readableUrl: 'the-fable',
        reader: 31022,
        genres: ['DRAMA', 'THRILLER'],
    },
];
