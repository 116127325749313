import { atom, getDefaultStore } from 'jotai';

export const DebugModeEnabledAtom = atom(false);

//global function to enable debug mode in developer console
export function goFullInky() {
    const old = getDefaultStore().get(DebugModeEnabledAtom)
    getDefaultStore().set(DebugModeEnabledAtom, !old)

    console.warn("Debug mode " + (!old ? "enabled" : "disabled") + ".");
    return "Debug mode " + (!old ? "enabled" : "disabled") + ".";
}

export function attachDebugMode(window:Window) {
    if( process.env.REACT_APP_ALLOW_DEBUG_MODE !== "true"){
        return;
    }

    if("goFullInky" in window){
        return;
    }

    console.log("Attaching debug mode to window")

    window["goFullInky"] = goFullInky;

    //keyboard shortcuts to enable or disable debug mode if react in developer mode
    if (process.env.NODE_ENV === 'development') {
        window.addEventListener('keydown', (e) => {
            if (e.ctrlKey && e.key === 'F12') {
                goFullInky();
            }
        });
    }

    //for electron app

    window.electron?.onToggleDebugMode((event, data) => {
        goFullInky();
    });
}