import React from 'react';
import './KodanshaUnderlinedText.scss';

interface Props {
    text: string;
}

export type ButtonProps = Partial<Props & React.HTMLAttributes<HTMLSpanElement>>;


const KodanshaUnderlinedText = ({ text, ...props }: ButtonProps): JSX.Element => {
    return (
        <span {...props} className='ui-underlined-text' aria-label={text}>
            {text}
        </span>
    );
};

export default KodanshaUnderlinedText;