import { CancelTokenSource } from 'axios';
import OrderAPI from '../API/OrderAPI';
import { PurchaseError } from './Errors/PurchaseError';
import { PaymentResult, Purchase } from './Purchase';
import Bugsnag from '@bugsnag/js';
import apiClient from '../API/InkyAPI';

export class PurchaseAttempt {
    purchase: Purchase;
    executePayment: (purchase: Purchase) => Promise<PaymentResult>;
    onPurchaseRejected?: (purchase: Purchase, message: string) => void;
    onPurchaseSuccessful?: (purchase: Purchase, message: string) => void;

    /**
     * Create the purchase in the back-end
     */
    async createPurchase(): Promise<PurchaseAttempt> {
        try {
            const confirmedPurchase = await OrderAPI.purchase(this.purchase);
            return Object.assign(new PurchaseAttempt(), this, {
                purchase: confirmedPurchase,
            });
        } catch (e) {
            if (this.onPurchaseRejected) {
                this.onPurchaseRejected(this.purchase, e.message);
            }
            throw new PurchaseError(e.message, this.purchase);
        }
    }

    /**
     * Create the purchase and return paymentintent
     */
    async createPurchaseGetPaymentIntent(): Promise<string> {
        try {
            const confirmedPurchase = await OrderAPI.purchaseGetPaymentIntent(this.purchase);
            return confirmedPurchase;
        } catch (e) {
            if (this.onPurchaseRejected) {
                this.onPurchaseRejected(this.purchase, e.message);
            }
            throw new PurchaseError(e.message, this.purchase);
        }
    }

    /**
     * Pay for purchase, and update back-end to inform of success or failure.
     */
    async payForPurchase(): Promise<PurchaseAttempt> {

        //1. Execute the payment and handle rollback if any exceptions thrown.
        let result: PaymentResult = null;
        try {
            result = await this.executePayment(this.purchase);
        } catch (e) {
            if (e instanceof PurchaseError) {
                await this.reportPaymentFailure(e);
                try {
                    await OrderAPI.reportPaymentFailure(e);
                } finally {
                    e.notifyBugsnag();
                    if (this.onPurchaseRejected) {
                        this.onPurchaseRejected(this.purchase, e.message);
                    }
                }
            } else {
                //Unknown error.
                console.log('Unknown error caught here.', e);
                console.log("Error type", typeof e);
                Bugsnag.notify(e);
                if (this.onPurchaseRejected) {
                    this.onPurchaseRejected(this.purchase, 'Purchase Failed');
                }
            }

            throw e;
        }

        //2. Soft-confirm for back-end.
        await this.confirmPayment(result);
        return this;
    }

    async confirmPayment(result: PaymentResult): Promise<void> {
        try {
            await OrderAPI.confirmPayment(result);
            if (this.onPurchaseSuccessful) {
                this.onPurchaseSuccessful(this.purchase, 'Success');
            }
        } catch (e) {
            //ToDo: Payment was successful, but the back-end may not have been notified.
            console.error(e);
            Bugsnag.notify(e);
        }

    }

    /**
     * Report failure to back-end so that it can rollback the change.
     * Then notify bugsnag and rollback any changes.
     * @param e Returned purchase error.
     */
    async reportPaymentFailure(e: PurchaseError): Promise<void> {
        try {
            console.log("Reporting back to the backend that this purchase failed.")
            await OrderAPI.reportPaymentFailure(e);

        } catch (ex) {
            console.log('Something strange happening here...' + ex.message);
            console.log(ex);
        } finally {
            e.notifyBugsnag();
            if (this.onPurchaseRejected) {
                this.onPurchaseRejected(this.purchase, e.message);
            }
        }
    }

    async redirect(): Promise<any> {
        const guestCheckout = (this.purchase.userId ?? 0) === 0;
        const orderHasId = (this.purchase.orders[0].userId ?? 0) === 0;

        if (guestCheckout && orderHasId) {
            window.location.href = '/checkout/success/guest';
            return;
        }

        if (this.purchase.isSplit()) {
            window.location.href = '/checkout/success/guest';
            return;
        }

        let orderID = 'guest';
        return await apiClient.getUserOrders(null).then(resp => {
            const lastItem = resp.slice(-1)[0];
            orderID = lastItem.id.toString();
            return orderID;
            // window.location.href = '/checkout/success/' + orderID;
        }).catch(error => {
            console.log(error);
            window.location.href = '/checkout/success/guest';
            return '-1';
        });
    }

    // static create(purchase: Purchase, executePayment: (purchase: Purchase) => Promise<PaymentResult>): PurchaseAttempt {
    //
    // }
}
