
export class Category {
    Id: number;
    name: string;

    static createFromApi(id, name): Category{
        return Object.assign({}, this, {
            Id : id,
            name: name
        });
    }
}
