import React, { useEffect, useRef, useState } from 'react';
import './KodanshaNavbar.scss';
import { ReactComponent as Search } from '../Assets/icons/search.svg';
import { useHistory } from 'react-router-dom';
import { AccountInfoAtom, LoginStatus, LoginStatusAtom, LogoutAtom } from '../../store/AccountStore';
import { useAtom, useAtomValue } from 'jotai';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import { KodanshaCart } from '../KodanshaCart/KodanshaCart';
import { FetchCartAtom, ShowCartAtom } from '../../store/CartStore';
import KodanshaEmail from '../KodanshaUIKit/KodanshaEmail/KodanshaEmail';
import KodanshaUnderlinedText from '../KodanshaUIKit/KodanshaUnderlinedText/KodanshaUnderlinedText';
import { KRow } from '../KodanshaUIKit/KodanshaGrid/KodanshaGrid';
import KodanshaArrow from '../KodanshaUIKit/KodanshaArrow/KodanshaArrow';
import {ReactComponent as FullLogo} from 'KodanshaAssets/Logo.svg';
import {ReactComponent as SmallLogo} from '../KodanshaAssets/Kodansha_Logo_dark.svg';
import {
    capitalizeFirstLetter, clearBrowseData,
    getRandomColorByIndex,
    getScrollbarWidth,
    hideScroll,
    showScroll,
} from '../../utils/utils';
import { useWindowSize } from '../Hooks/useWindowSize';
import SeriesPoster from '../KodanshaUIKit/SeriesPoster/SeriesPoster';
import ProductPoster from '../KodanshaUIKit/ProductPoster/ProductPoster';
import {
    FetchSearchAtom,
    FetchSearchQueryAtom,
    SearchResultLoadingStatus,
} from '../../store/SearchStore';
import { DiscoverItem } from '../../Models/DiscoverItem';
import useDebounce from '../Hooks/useDebounce';
import { ResendVerification } from '../KodanshaAuth/KodanshaLogin/KodanshaLogin';
import { useCounter } from '../Hooks/useCounter';
import { Link } from '../../Devon/Components/InkyLink';

const InnerMenu = (): JSX.Element => {
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [accountInfo] = useAtom(AccountInfoAtom);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);
    const [, logout] = useAtom(LogoutAtom);
    const [sendActive, setSendActive] = useState<boolean>(false);
    const { startTimer, sentTime, status } = useCounter('verification_time');

    const history = useHistory();

    useEffect(() => {
        status === false && setSendActive(true);
    }, [status]);

    const onEmailResendClicked = (): void => {
        setSendActive(false);
        if (typeof status === 'boolean') {
            ResendVerification().then(() => {
                startTimer();
            }).catch(e => {
                console.log(e);
                setSendActive(true);
            });
        }
    };

    return <React.Fragment>
        <div className='account-wrapper'>
            {loginStatus === "LoggedIn" ?
                <React.Fragment>
                    <span className='username-text'
                          style={{ borderTop: accountInfo.verified === false && '2px solid #000000' }}
                    >{accountInfo.email}</span>
                    {accountInfo.verified === false && <div className='account-verify-message-inner-wrapper'>
                            <span className='account-verify-message-text'>
                                Your account has not been verified.
                            </span>

                        <KodanshaArrow onClick={onEmailResendClicked}
                                       active={sendActive}
                                       text={typeof status !== 'boolean' ? 'EMAIL HAS BEEN SENT' : 'SEND VERIFICATION EMAIL'}
                                       withArrow={false} />
                    </div>}
                </React.Fragment>
                :
                <KRow onClick={() => setLoginOverlay(true)}
                      className='sign-in-create-account'>

                    <span className='login-or-signup pt-2'>Log in or sign up</span>
                    {/*<span className='-f-bold -f-17 -cl-white -mx-2 log-sign-between'>|</span>*/}
                    {/*<span className='log-sign-between' />*/}
                    {/*<span className='-f-bold -f-17 -cl-white'>SIGN UP</span>*/}

                </KRow>
            }


            <>
                <div className='divider' />
                <div className='library-wrapper'>
                    <Link onClick={showScroll}
                          className={`library-text ${loginStatus !== "LoggedIn" && 'library-text-not-logged-in'}`}
                          to={'/library'}>Library</Link>
                    {/*<span className='library-text'>Library</span>*/}
                    <div className='divider-v' />
                    {loginStatus === "LoggedIn" && <Link onClick={showScroll}
                                                                   className={`account-text ${loginStatus !== "LoggedIn" && 'account-text-not-active'}`}
                                                                   to={'/account'}>Account</Link>
                    }
                    {/*<span className='account-text'>Account</span>*/}
                </div>
                <div className='divider-2' />
            </>

        </div>


        <div className={'menu'}>
            <div className={'div-instead-of-ul'}>
                <li>
                    <Link className={'route'} to={'/browse'}>Browse</Link>
                </li>
                <div className='divider' />
                <div className={'subMenu'}>
                    <ul>
                        <li>
                            <Link onClick={showScroll} className={'route route-first'}
                                  to={'/calendar'}>Release Calendar</Link>
                        </li>
                        <li>
                            <Link onClick={showScroll} className={'route route-first'}
                                  to={'/browse?st=3&&is=false'}>New Releases</Link>
                        </li>
                        <li>
                            <Link onClick={showScroll} className={'route'}
                                  to={'/browse/?s=0&st=true'}>On Sale</Link>
                        </li>
                        <div className='divider' />
                        {/*<li><Link onClick={showScroll} className={'route'} to={'/calendar'}>Release*/}
                        {/*    Calendar</Link></li>*/}
                        {/*<div className='divider' />*/}
                        {/*<li><Link onClick={showScroll} className={'route'} to={'/on-sale'}>On Sale</Link></li>*/}
                        {/*<div className='divider' />*/}
                    </ul>
                </div>
                <Link className={'route'} to={'/news'}>News</Link>
                {/*<div className='divider' />*/}
            </div>

            {accountInfo?.roles?.includes('admin') &&
                <Link onClick={showScroll} className={'route complete-menu-accounts-nav-dashboard'}
                      to={'/dashboard'}>Dashboard</Link>}

            {loginStatus === "LoggedIn" && <div className='logout-wrapper'>
                <span className='logout-text' onClick={(): void => {
                    logout();
                    showScroll();

                }}>Logout</span>
            </div>}

        </div>
    </React.Fragment>;
};

const RecentSearchItem = ({ name, setSearchValue }): JSX.Element => {
    return <div className='recent-search-item-wrapper' onClick={() => {
        setSearchValue(name);
    }}>
        <Search aria-label={'search icon'} width={25} height={25} />
        <span aria-label={'recent search' + name} className='recent-search-item-text'>{name}</span>
    </div>;
};

const selectSearchItem = (itemName: string) => {
    // const isDuplicate = Object.values(localStorage).includes(itemName);
    // if (isDuplicate) {
    //     return;
    // }

    const searchHistory = JSON.parse(localStorage.getItem('SearchHistory') || '[]');

    const filteredHistory = searchHistory.filter((item: string) => item !== itemName);

    // Add the current item to the beginning of the filtered search history array
    filteredHistory.unshift(itemName);

    // Limit the search history array to 5 items
    const limitedHistory = filteredHistory.slice(0, 5);

    // Store the updated search history array in local storage
    localStorage.setItem('SearchHistory', JSON.stringify(limitedHistory));

    // localStorage.setItem('SearchHistory5', localStorage.getItem('SearchHistory4') || '');
    // localStorage.setItem('SearchHistory4', localStorage.getItem('SearchHistory3') || '');
    // localStorage.setItem('SearchHistory3', localStorage.getItem('SearchHistory2') || '');
    // localStorage.setItem('SearchHistory2', localStorage.getItem('SearchHistory1') || '');
    // localStorage.setItem('SearchHistory1', itemName);
};

const ClearSearch = () => {
    console.log('Clearing Search');
    localStorage.setItem('SearchHistory', '[]');
};

const RecentSearches = ({ setSearchValue, isSearchOpen, width }): JSX.Element => {
    const [, clearedSearch] = useState(false);

    const recentSearch: string[] = [];
    const itemNames = JSON.parse(localStorage.getItem('SearchHistory'));
    itemNames?.forEach((itemName) => {
        if (itemName)
            recentSearch.push(itemName);
    });


    if (recentSearch.length === 0) {
        if (width > 1024 && !isSearchOpen) {
            return null;
        }
        return <div className='search-message-wrapper'>
            <span aria-label={'No recent search'} className='search-message-text'>
                No recent search
            </span>
        </div>;
    }

    return <React.Fragment>
        <div className='recent-search-container'>
            {recentSearch.length !== 0 && <div className='clear-search-wrapper'>
                <span className='recent-search-text' aria-label={'Recent search'}>Recent search</span>
                <KodanshaUnderlinedText text='Clear search'
                                        aria-label={'Clear search'}
                                        onClick={(): void => {
                                            ClearSearch();
                                            clearedSearch(true);
                                        }} />
            </div>}

            {recentSearch.length !== 0 && <div className='recent-search-items-container'>
                {recentSearch.map((item, index) => {
                    return <RecentSearchItem key={index} name={item} setSearchValue={setSearchValue} />;
                })}
            </div>}
        </div>
    </React.Fragment>;
};

const SearchBar = ({
                       searchValue,
                       setSearchValue,
                       setIsSearchOpen,
                       IsEnterBlocked,
                       setIsFocusOnSearchInput,
                       goToSearchPage,
                   }): JSX.Element => {
    const { width } = useWindowSize();
    const emailRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (width > 1024) {
            emailRef.current.focus();
            setIsFocusOnSearchInput(true);
        }

        // return (): void => {
        // setIsFocusOnSearchInput(false);
        // };
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        showScroll();
        goToSearchPage();
    };

    return <>
        {width >= 1024 && <div
            aria-label={'search bar'}
            onClick={(): void => {
                setIsSearchOpen(false);
            }}
            className='navbar-backdrop-search' />}
        <div className='menu-search-wrapper'>
            <span aria-label={'Search by title or creator'}
                  className='menu-search-text'>Search by title or creator</span>
            {/*<input type={"text"}/>*/}
            <form className='menu-search-input-wrapper'>
                <KodanshaEmail value={searchValue}
                               onEnterPushed={!IsEnterBlocked ? goToSearchPage : () => 2}
                               ref={emailRef}
                               ariaLabel={'search for product or series'}
                               onSubmit={onSubmit}
                               onChange={(text) => setSearchValue(text)}
                               onFocus={(): void => setIsFocusOnSearchInput(true)}
                    // onBlur={(): void => setIsFocusOnSearchInput(false)}
                               placeHolder={''} />

                <Search aria-label={'search icon'} width={25} onClick={goToSearchPage} />
            </form>
        </div>
    </>;
};

interface SearchResultProp {
    items: DiscoverItem[];
    searchValue: string;
    goToSearchPage: () => void;
    closeMenus: () => void;
    onEnterBlocked: () => void;
}

const SearchResults = (props: SearchResultProp) => {
    const history = useHistory();
    const [searchLoading] = useAtom(SearchResultLoadingStatus);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const searchWrapperRef = useRef(null);
    const listenerToRemove = useRef(null);
    const selectedIndexRef = useRef(-1);
    const firstRender = useRef(true);
    const slicedArray = props.items.slice(0, Math.min(props.items.length, 20));

    const scrollToItem = (number: number): void => {
        const itemRef = document.getElementById('search-result-item-wrapper' + number.toString());
        itemRef?.scrollIntoView({ behavior: 'smooth' });
        itemRef?.focus();
    };

    useEffect(() => {
        selectedIndexRef.current = selectedIndex;
    }, [selectedIndex]);

    useEffect(() => {
        // if (slicedArray.length > 0 && firstRender.current === true) {
        //     firstRender.current = false;
        listenerToRemove.current = function handleKeyDown(event): void {
            if (event.key === 'ArrowDown') {
                props.onEnterBlocked();
                setSelectedIndex(prevIndex => {
                    if (prevIndex >= slicedArray.length - 1) {
                        scrollToItem(slicedArray.length - 1);
                        return slicedArray.length - 1;
                    } else {
                        scrollToItem(prevIndex + 1);
                        return prevIndex + 1;
                    }
                });
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex(prevIndex => {
                    if (prevIndex <= 0) {
                        scrollToItem(0);
                        return 0;
                    } else {
                        scrollToItem(prevIndex - 1);
                        return prevIndex - 1;
                    }
                });
            } else if (event.key === 'Enter') {
                // console.log('props.items', props.items, selectedIndex, selectedIndexRef.current);
                if (selectedIndexRef.current === -1) {
                    props.goToSearchPage();
                    // history.push('/search');
                } else {
                    selectSearchItem(props.items[selectedIndexRef.current]?.content.title);
                    history.push(`/${props.items[selectedIndexRef.current]?.type}/` + (props.items[selectedIndexRef.current]?.content.readableUrl ?? props.items[selectedIndexRef.current]?.content.id));
                }
            }
        };
        document.addEventListener('keydown', listenerToRemove.current);
        // }
        return (): void => {
            document.removeEventListener('keydown', listenerToRemove.current);
        };
    }, [slicedArray]);

    if (props.searchValue.length === 1) {
        return <div className='search-message-wrapper'>
            <span aria-label={'Please enter more than one character'} className='search-message-text'>
                Please enter more than one character
            </span>
        </div>;
    }

    if (searchLoading === true) {
        return <div className='search-message-wrapper'>
            <span aria-label={'loading search'} className='search-message-text'>
                Loading ...
            </span>
        </div>;
    }

    if (props.items.length == undefined || props.items.length == 0) {
        return <div className='search-message-wrapper'>
            <span aria-label={'No search result'} className='search-message-text'>
                No search result
            </span>
        </div>;
    }

    return <div ref={searchWrapperRef} className='search-result-wrapper'>
        {slicedArray.map((item, index) => {
            console.log('item', item);
            return <div key={index} id={'search-result-item-wrapper' + index.toString()}
                        className='search-result-item-wrapper'
                        style={{ backgroundColor: selectedIndex === index && '#C6C6C6' }}
                        onClick={() => {
                            props.closeMenus();
                            showScroll();
                            selectSearchItem(item.content.title);
                            history.push(`/${item.type}/` + (item.content.readableUrl ?? item.content.id));
                        }}>
                {item.type === 'series' ? <SeriesPoster src={item.content.thumbnails[0]?.url} ratio={140}
                        // color={getRandomColorByIndex(index)}
                                                        color={item.content.thumbnails[0]?.color}
                                                        alt={'cover for ' + item.content.title} /> :
                    <ProductPoster ratio={140} src={item.content.thumbnails[0]?.url}
                                   alt={'cover for ' + item.content.title} />}

                <div className='search-result-info-wrapper'>
                    <span aria-label={`${item.displayType} ${item.content.title}`}
                          className='search-result-type'>{capitalizeFirstLetter(item.displayType)}</span>
                    <span aria-label={`${item.displayType} ${item.content.title}`}
                          className='search-result-name'>{capitalizeFirstLetter(item.content.title)}</span>
                </div>
            </div>;
        })}
    </div>;
};

function ShowInnerMenu({
                           isMenuOpen,
                           isSearchOpen,
                           setIsMenuOpen,
                           setIsSearchOpen,
                           setIsFocusOnSearchInput,
                           isFocusOnSearchInput,
                       }): JSX.Element {
    const { width } = useWindowSize();
    const [searchValue, setSearchValue] = useAtom(FetchSearchQueryAtom);
    const loginOverlay = useAtomValue(LoginOverlayVisible);
    const [innerSearchValue, setInnerSearchValue] = useState('');
    const [IsEnterBlocked, setIsEnterBlocked] = useState(false);
    const [searchArray, fetchSearch] = useAtom(FetchSearchAtom);
    const history = useHistory();
    const debouncedValue = useDebounce<string>(searchValue, 200);

    useEffect(() => {
        if (!isSearchOpen) {
            !loginOverlay && showScroll();
        } else {
            setInnerSearchValue('');
        }
    }, [isSearchOpen]);

    useEffect(() => {
        console.log('searchValue', searchValue);
    }, [searchValue]);


    useEffect(() => {
        if (searchValue === '' || searchValue == undefined) {
            return;
        }

        setInnerSearchValue(searchValue);
        fetchSearch(searchValue);
    }, [debouncedValue]);


    useEffect(() => {
        width <= 1024 && setIsFocusOnSearchInput(false);
        if (!isMenuOpen) {
            setInnerSearchValue('');
        }
    }, [isMenuOpen]);

    useEffect(() => {
        if (searchValue === '') {
            setInnerSearchValue('');
        }
    }, [searchValue]);


    const goToSearchPage = () => {
        selectSearchItem(searchValue);
        if (history.location.pathname !== '/search') {
            history.push('/search');
        } else {
            setIsSearchOpen(false);
            setIsMenuOpen(false);
            showScroll();
        }
    };

    const closeMenus = () => {
        setIsSearchOpen(false);
        setIsMenuOpen(false);
    };

    if (!isMenuOpen && !isSearchOpen) {
        return null;
    }

    const onEnterBlocked = () => {
        setIsEnterBlocked(true);
    };

    const searchItem = searchValue.length != 0 ? searchArray : [];

    return (
        <div
            aria-label={'mobile inner menu'}
            className={`inner-menu show-on-mobile ${!isMenuOpen && 'convert-mobile-menu-to-search'}`}>

            <div className={'beta-bug-show-on-mobile'}>
                <div className={'beta-bug'}>BETA</div>
            </div>

            {(!isMenuOpen || width <= 1024) && <SearchBar searchValue={innerSearchValue} setSearchValue={setSearchValue}
                                                          setIsSearchOpen={setIsSearchOpen}
                                                          IsEnterBlocked={IsEnterBlocked}
                                                          goToSearchPage={goToSearchPage}
                                                          setIsFocusOnSearchInput={(isFocus: boolean) => setIsFocusOnSearchInput(isFocus)}
            />}

            {isFocusOnSearchInput && innerSearchValue == '' &&
                <RecentSearches setSearchValue={setSearchValue} isSearchOpen={isSearchOpen} width={width} />}


            {/*{searchValue === '' && <div className='divider' />}*/}
            {innerSearchValue === '' ? (width <= 1024 && !isFocusOnSearchInput &&
                <InnerMenu />) : ((!isMenuOpen || width <= 1024) &&
                <SearchResults items={searchItem} searchValue={searchValue}
                               onEnterBlocked={onEnterBlocked}
                               goToSearchPage={goToSearchPage}
                               closeMenus={closeMenus} />)}

        </div>
    );
}


const CompleteMenu = ({ setIsMenuOpen }): JSX.Element => {
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);
    const [, logout] = useAtom(LogoutAtom);
    const [accountInfo] = useAtom(AccountInfoAtom);
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [sendActive, setSendActive] = useState<boolean>(false);
    const { startTimer, sentTime, status } = useCounter('verification_time');

    const history = useHistory();

    const onMenuItemClicked = () => {
        setIsMenuOpen(false);
        showScroll();
    };

    const onLogOutClicked = () => {
        logout();
        onMenuItemClicked();
    };


    const onEmailResendClicked = (): void => {
        setSendActive(false);
        if (typeof status === 'boolean') {
            ResendVerification().then(() => {
                startTimer();
            }).catch(e => {
                console.log(e);
                setSendActive(true);
            });
        }
    };

    useEffect(() => {
        status === false && setSendActive(true);
    }, [status]);

    return <div
        className={`complete-menu-container ${loginStatus !== "LoggedIn" && 'complete-menu-accounts-user-not-loggedIn'}`}>
        {/*<div className='complete-menu-assets'>*/}
        {/*    Asset*/}
        {/*</div>*/}

        {/*<div className='divider' />*/}

        {/*{loginStatus === "LoggedIn" && <div className='complete-menu-greeting'>*/}
        {/*    {accountInfo.email}*/}
        {/*</div>}*/}

        <div className='complete-menu-accounts'>

            {loginStatus !== "LoggedIn" ?

                <React.Fragment>
                    <div onClick={() => setLoginOverlay(true)} className='complete-menu-accounts-nav'>
                        <span>Log In</span></div>
                    <Link onClick={onMenuItemClicked} to={'/library'}
                          className='complete-menu-accounts-nav-link'><span>Library</span></Link>
                </React.Fragment>

                :

                <React.Fragment>
                    <div className='complete-new-menu-greeting'>
                        {accountInfo.email}
                    </div>
                    {accountInfo.verified === false && <div className='account-verify-message-inner-wrapper'>
                            <span className='account-verify-message-text'>
                                Your account has not been verified.
                            </span>

                        <KodanshaArrow onClick={onEmailResendClicked}
                                       active={sendActive}
                                       text={typeof status !== 'boolean' ? 'EMAIL HAS BEEN SENT' : 'SEND VERIFICATION EMAIL'}
                                       withArrow={false} />
                    </div>}
                    <div className='complete-menu-accounts-nav'>
                        <Link onClick={onMenuItemClicked} to={'/account'}
                              className='complete-menu-accounts-nav-link'><span>Account</span></Link>
                    </div>
                    {loginStatus !== "LoggedIn" && <Link onClick={onMenuItemClicked} to={'/library'}
                                                                   className='complete-menu-accounts-nav-link'><span>Library</span></Link>}
                    {accountInfo?.roles?.includes('admin') && <Link onClick={onMenuItemClicked} to={'/dashboard'}
                                                                    className='complete-menu-accounts-nav-link complete-menu-accounts-nav-dashboard'><span>Dashboard</span></Link>}
                    <div onClick={onLogOutClicked}
                         className='complete-menu-accounts-nav-link complete-menu-accounts-nav-link-logout'><span>Log Out</span>
                    </div>
                </React.Fragment>
            }

        </div>

    </div>;
};


export function KodanshaNavbar(): JSX.Element {
    const { width } = useWindowSize();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [cartAnyType] = useAtom<any>(FetchCartAtom);
    //const [searchValue, setSearchValue] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [showCart, setShowCart] = useAtom(ShowCartAtom);
    const [loginOverLay, setLoginOverlay] = useAtom(LoginOverlayVisible);
    const [isFocusOnSearchInput, setIsFocusOnSearchInput] = useState(false);
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [accountInfo] = useAtom(AccountInfoAtom);

    useEffect(() => {
        if (isMenuOpen) {
            hideScroll();
        }

        if (isMenuOpen || showCart) {
            setIsSearchOpen(false);
            // console.log('scrollableWrapper.current.style.overflowY', getComputedStyle(scrollableWrapper.current).overflowY);
            // console.log('navbar', getComputedStyle(navbar.current).height);
            // console.log('cart', getComputedStyle(cart.current).height);
            // setTimeout(() => {
            //     console.log('scrollableWrapper.current.style.overflowY', getComputedStyle(scrollableWrapper.current).overflowY);
            // }, 1000);
        } else {
            // showScroll();
            // console.log('scroll_c', 'nav 1');
        }
    }, [isMenuOpen, showCart]);

    useEffect(() => {
        return (): void => {
            // showScroll();
            // console.log('scroll_c', 'nav 2');
        };
    }, []);

    useEffect(() => {
        if (loginOverLay) {
            setIsMenuOpen(false);
        }
    }, [loginOverLay]);

    function CartButton(): JSX.Element {

        function LoginOrMenuButton() {
            if(loginStatus === "LoggedIn" || width <= 1024) {
                return (
                    <a href='#' style={{ backgroundColor: isMenuOpen && '#000000', color: isMenuOpen && '#ffffff' }}
                       className={'button'}>
                        Menu
                    </a>
                );
            }

            if(loginStatus === "LoggedOut") {
                return (
                    <a href='#' style={{ backgroundColor: isMenuOpen && '#000000', color: isMenuOpen && '#ffffff' }}
                       className={`button login-when-logged-out-large-screens`}>
                        Login
                    </a>
                );
            }

            return (
                <a className={`button disabled`}> ... </a>
            )
        }

        return (<div className={'div-instead-of-ul-desktop'}>

            <div className={'list-menu-and-cart'}>
                <a href='#' style={{ backgroundColor: showCart && '#000000', color: showCart && '#ffffff' }}
                   onClick={(e) => {
                       e.preventDefault();
                       e.stopPropagation();
                       showCart && showScroll();
                       setShowCart(showCart => !showCart);
                       setIsMenuOpen(false);
                   }}
                   aria-expanded={showCart}
                   aria-controls={'kodansha-cart-popup'}
                   aria-label={'Open Cart'}
                   className={'button'}>Cart</a>
                <span aria-label={'Items in cart'}
                      className={'card-quantity-number'}>{cartAnyType?.cartItems?.length ?? '0'}</span>
            </div>

            <div className={'list-menu-and-cart'} onClick={(e) => {
                e.preventDefault();
                if (loginStatus === "LoggedIn" || width <= 1024) {
                    isMenuOpen && showScroll();
                    setIsMenuOpen(isMenuOpen => !isMenuOpen);
                } else  if (loginStatus === "LoggedOut") {
                    setLoginOverlay(true);
                }

                setShowCart(false);
                setIsFocusOnSearchInput(false);

            }}>
                {accountInfo.verified === false && loginStatus === "LoggedIn" &&
                    <span aria-label={'account is not verified'} className={'menu-not-verified'}>!</span>}
                <LoginOrMenuButton/>
            </div>

        </div>);
    }

    const closeCarts = () => {
        showScroll();
        setShowCart(false);
    };

    const goToBrowse = () => {
        closeCarts();
        clearBrowseData();
    };

    const onSearchClicked = (e) => {
        e.preventDefault();
        setIsSearchOpen(isSearchOpen => !isSearchOpen);
        closeCarts();
        setIsMenuOpen(false);
    };

    return (<>

        <nav onClick={(e) => {
            e.stopPropagation();
            if (e.currentTarget === e.target) {
                showScroll();
                setShowCart(false);
                setIsMenuOpen(false);
            }
        }} className='KodanshaNavbar' style={{ width: `calc(100vw - ${getScrollbarWidth()}px)` }}>
            <div className={'inner-navbar'}>
                <Link to={'/'} aria-label='Kodansha Logo'>
                    {/*<img className='logo' src={logo} />*/}
                    <FullLogo aria-label={'logo'} className='logo logo-more-420' />
                    <SmallLogo aria-label={'logo'} className='logo logo-less-420' />
                </Link>

                <div className={'beta-bug'}>BETA</div>
                <nav aria-label={'navigation bar'} className={'hide-on-mobile'}>
                    <div className={'div-instead-of-ul-desktop'}>
                        <Link onClick={goToBrowse} aria-label={'link to browse page'} className={'route'}
                              to={'/browse'}>Browse</Link>
                        <Link onClick={closeCarts} aria-label={'link to news page'} className={'route'}
                              to={'/news'}>News</Link>
                        <Link onClick={closeCarts} aria-label={'link to library page'} className={'route'}
                              to={'/library'}>Library</Link>
                        <Link onClick={(e) => {
                            onSearchClicked(e);
                            setIsFocusOnSearchInput(true);
                            hideScroll();
                        }} className={'route'} to={''}>Search</Link>
                        {CartButton()}
                        {/*<li>{showLogin()}</li>*/}
                    </div>
                </nav>

                <nav aria-label={'cart section'} className={'show-on-mobile'}>
                    <React.Fragment>
                        {CartButton()}
                        {/*<li onClick={() => {*/}
                        {/*    setIsMenuOpen(!isMenuOpen);*/}
                        {/*}}><a className={'button'}>Menu</a></li>*/}
                    </React.Fragment>
                </nav>

            </div>
            {/* Don't write components as a function which will produce memory leak in reactjs */}
            <ShowInnerMenu isMenuOpen={isMenuOpen}
                           isSearchOpen={isSearchOpen}
                           setIsMenuOpen={setIsMenuOpen}
                           setIsFocusOnSearchInput={setIsFocusOnSearchInput}
                           isFocusOnSearchInput={isFocusOnSearchInput}
                           setIsSearchOpen={setIsSearchOpen} />



            {/*<div ref={scrollableWrapper} className={'scroll-wrapper'}>*/}
            {isMenuOpen && <CompleteMenu setIsMenuOpen={setIsMenuOpen} />}

            {<div id={'kodansha-cart-popup'} className={'dropdown ' + (showCart ? '' : 'hidden')}>
                <KodanshaCart CloseCarts={closeCarts} />
            </div>}
            {/*</div>*/}

        </nav>

        {(showCart || isMenuOpen) && <div onClick={() => {
            setIsMenuOpen(false);
            setShowCart(false);
            showScroll();
        }} className='navbar-backdrop'></div>}


    </>);
};


// function showLogin() {
//     const [token] = useAtom(RefreshTokenAtom);
//     const [accountInfo] = useAtom(AccountInfoAtom);
//     const [, logout] = useAtom(LogoutAtom);
//     const [, setLoginOverlay] = useAtom(LoginOverlayVisible);
//
//     if (!token) {
//         // we are  not logged in
//         return (
//             <Link to={''} onClick={(e) => {
//                 e.preventDefault();
//                 setLoginOverlay(true);
//             }} className={'button'}>Log in</Link>
//         );
//     } else {
//         if (accountInfo?.roles?.includes('admin')) {
//             return (
//                 <React.Fragment>
//                     {/*<Link to={'/account'} className={'button'}>My Account</Link>*/}
//                     {/*<Link to={'/dashboard'} className={'button inverted'}>Dashboard</Link>*/}
//                     <Link to={''} onClick={(e) => {
//                         // console.log('22');
//                         e.preventDefault();
//                         logout();
//                     }} className={'button'}>Log out</Link>
//                 </React.Fragment>
//             );
//         }
//         return (<React.Fragment>
//             <Link to={'/account'} className={'button'}>My Account</Link>
//             <Link to={''} onClick={(e) => {
//                 e.preventDefault();
//                 logout();
//             }} className={'button'}>Log out</Link>
//         </React.Fragment>);
//     }
// }
