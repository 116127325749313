import 'react-app-polyfill/ie11';
import 'intersection-observer';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';
import installPolyfills from './polyfills';
import HashScrollToTop from './Components/HashScrollToTop';
import { store } from './store/configureStore';
import { Provider } from 'react-redux';

import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCcPaypal, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Bugsnag, { BrowserConfig } from '@bugsnag/js';

/////////////////////////////////
import { KodanshaApp } from './Kodansha/KodanshaApp';
//////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////
//////////////////////////////////
//////////////////////
////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////////////////////////////////////
//////////

library.add(faCreditCard as IconDefinition);
library.add(faPaypal as IconDefinition);
library.add(faCcPaypal as IconDefinition);

installPolyfills();

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
}

// BugSnag Integration. (Crashlytics)
const bugsnagConf: BrowserConfig = {
    apiKey: process.env.REACT_APP_BUGSNAG_APIKEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: process.env.REACT_APP_VERSION,
};

// Set the release-stage in bugsnag to local to make it easier to distinguish errors.
if (process.env.REACT_APP_LOCAL) {
    console.debug('Running locally. Informing Bugsnag');
    bugsnagConf.releaseStage = 'local';
}


/////////////////////////////////
const renderedApp = <KodanshaApp />;
const render = () => {
    ReactDOM.render(
        <BrowserRouter>
            <HashScrollToTop>
                {renderedApp}
            </HashScrollToTop>
        </BrowserRouter>,
        document.getElementById('root'),
    );
};
//////////////////////////////////
///////////////////////////////////
//////////////////////
////////////////////
///////////////////////
/////////////////////////////
/////////////////////////////
//////////////////////////////
/////////////////////////
////////////////////////////////////////
//////
//
////////////////////////////////////////
///////////////////
//////
////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////
////////////////////////////
////////

//////////////////////
////////////////
///////////////////
/////////////////////////
/////////////////////////
//////////////////////////
/////////////////////
////////////////////////////////////
//
//
//////////////////////////////
///////////////////////////
////////////////////////////////////////////////////////////////////////////
///////////////////
///////////
 
//////////////////////
////////////////
///////////////////
///////////////////////
/////////////////////////////
/////////////////////////////
//////////////////////////////
////////////////////////
/////////////////////
////////////////////////////////////
//
//
//////////


function invokeServiceWorkerUpdateFlow(registration) {
// TODO implement your own UI notification element
console.warn('New version of the service....');
// notification.show("New version of the app is available. Refresh now?");
// notification.addEventListener('click', () => {
//     if (registration.waiting) {
//         // let waiting Service Worker know it should became active
//         registration.waiting.postMessage('SKIP_WAITING')
//     }
// })
}

// check if the browser supports serviceWorker at all
/*
if ('serviceWorker' in navigator) {

// wait for the page to load
console.log('Service Worker....');
window.addEventListener('load', async () => {
    // register the service worker from the file specified
    console.log('Service Worker: Registering..');
    const registration = await navigator.serviceWorker.register('/serviceWorker.bundle.js');

    // ensure the case when the updatefound event was missed is also handled
    // by re-invoking the prompt when there's a waiting Service Worker
    if (registration.waiting) {
        console.log('Service Worker: waiting...');
        invokeServiceWorkerUpdateFlow(registration);
    }

    console.log('Service Worker: add Listener...');
    // detect Service Worker update available and wait for it to become installed
    registration.addEventListener('updatefound', () => {
        if (registration.installing) {
            // wait until the new Service worker is actually installed (ready to take over)
            registration.installing.addEventListener('statechange', () => {
                if (registration.waiting) {
                    // if there's an existing controller (previous Service Worker), show the prompt
                    if (navigator.serviceWorker.controller) {
                        invokeServiceWorkerUpdateFlow(registration);
                    } else {
                        // otherwise it's the first install, nothing to do
                        console.log('Service Worker initialized for the first time');
                    }
                }
            });
        } else {
            console.log('Service Worker: already installed...');
        }
    });

    let refreshing = false;

    // detect controller change and refresh the page
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!refreshing) {
            window.location.reload();
            refreshing = true;
        }
    });
});
}
*/

render();
//
// serviceWorker.register({
//     onUpdate: registration => {
//         console.log("Detected new app-cache. Old cache has been invalidated.");
//         if (registration && registration.waiting) {
//             registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         }
//         window.location.reload();
//     }
// });
