import React from 'react';
import './SeriesSDCC.scss';
import SeriesPoster from '../KodanshaUIKit/SeriesPoster/SeriesPoster';
import KodanshaUnderlinedText from '../KodanshaUIKit/KodanshaUnderlinedText/KodanshaUnderlinedText';

import { GoodiesData } from './GoodiesDataSDCC';
import { useAtom } from 'jotai';
import { LoginStatus, LoginStatusAtom } from '../../store/AccountStore';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import { useHistory } from 'react-router-dom';
import { Link } from '../../Devon/Components/InkyLink';

interface Props {
    data: typeof GoodiesData[0];
}


export const SeriesItem = ({ data }: Props) => {
    const history = useHistory();
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);

    const onButtonCLicked = (e) => {
        if (e.currentTarget.textContent === 'Sign up to read') {
            setLoginOverlay(true);
            return;
        }
    };

    return <div className={'series-item-wrapper'}>
        <Link to={'/series/' + data.readableUrl} className={'series-item-link'}>
            <SeriesPoster ratio={120} isGray={false} color={data.color} src={data.image} />
        </Link>

        <div className={'series-item-info-wrapper'}>

            <h5 className={'series-name'}>{data.name}</h5>
            <span className={'sx-author-name'}>By {data.author}</span>

            <div className={'genres-wrapper'}>
                {data.genres.map((genre, index, array) => {
                    return <>
                        <span>{genre}</span>
                        {index < array.length - 1 ? <span>/</span> : null}
                    </>;
                })}
            </div>
            {/*//TODO: READER uses readable url*/}
            {loginStatus === "LoggedIn" ? <Link to={{
                    pathname: '/reader/' + data.reader.toString(),
                    state: { returnPath: history.location.pathname },
                }}
                >
                    <KodanshaUnderlinedText text={'Read Chapter 1'} />
                </Link> :
                <KodanshaUnderlinedText onClick={onButtonCLicked}
                                        text={'Sign up to read'} />}

        </div>
    </div>;
};

const SeriesSDCC = () => {
    return (
        <div className='series-ax-container'>
            <h1 className='header-one'>SDCC23 SPOTLIGHT SERIES</h1>
            <h2 className='header-two'>A guide to explore the world of manga</h2>
            <h2 className='until'>Up to 3 volumes of the following series were free-to-read until 7/25</h2>

            <div className='series-ax-grid-wrapper'>
                {GoodiesData.map(data => <SeriesItem data={data} />)}
            </div>
        </div>
    );
};

export default SeriesSDCC;