export default class DashUser {
    id: number;
    auth0Id:string
    email: string;
    pictureUrl: string;
    verified: boolean;
    roles: string[];
}

export class DashCustomUserNoteRequest {
    userId: number;
    message: string;
    logLevel: UserNoteLogLevel;
}

export class NukeUserSubscriptionRequest {
    userId: number;
}

export enum UserNoteLogLevel {
    Info = 'Info',
    Success = 'Success',
    Error = 'Error',
}
