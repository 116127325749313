import dayjs from 'dayjs';
import { Publishable } from './Publishable';
import { DashCouponProduct } from '../../store/DashCouponStore';

export enum CouponType{
    Discount = "Discount",
    FreeProduct = "FreeProduct"
}

export class DashCoupon extends Publishable{

    name: string;
    discountText: string;
    maxDiscountText: string;
    restrictionsText: string;
    status: string;
    type: CouponType;
    usesSharedCouponCode: boolean;
    sharedCouponCode: string;
    validity: DashCouponValidity;
    metaData: DashCouponMetaData;
    uniqueCodePrefix?: string;
    uniqueCodeCharacterTypeId?: number;
    // asDiscount: CouponAsDiscount;

//     return Object.assign(new DashCouponDetails(), dto, {
//     validity: DashCouponValidity.createFromApi(dto.validity),
//     metaData: includeMetaData ? DashCouponMetaData.createFromApi(dto.metaData): null,
//     original: setOriginal ? this.createFromApi(dto, false, false) : null,
// });

    canPublish(): boolean {
        return this.status === Publishable.STATUS_DRAFT || this.status === "OnGoing" || this.status === "Expired";
    }

    static createFromApi(dto: DashCouponDTO): DashCoupon{
        if(dto) {
            return Object.assign(new DashCoupon(), dto, {
                validity: DashCouponValidity.createFromApi(dto.validity),
                metaData: DashCouponMetaData.createFromApi(dto.metaData),
                original: null
            });
        }

        return null;
    }
}
export class DashCouponValidity {
    startDate?: Date;
    endDate?: Date;
    claimCountLimit?: number;

    mutate(newProps: any): DashCouponValidity {
        return Object.assign(new DashCouponValidity(), this, newProps);
    }

    static createFromApi(dto: DashCouponValidityDTO): DashCouponValidity{
        if(dto) {
            return Object.assign(new DashCouponValidity(), dto, {
                startDate: dto.startDate?dayjs(dto.startDate+'Z').toDate():null,
                endDate: dto.startDate?dayjs(dto.endDate+'Z').toDate():null,
            });
        }

        return null;
    }

    toApi(): DashCouponValidityDTO {
        return Object.assign({}, this, {
            startDate: dayjs(this.startDate?.toUTCString()),
            endDate: dayjs(this.endDate?.toUTCString())
        });
    }
}
export class DashCouponMetaData {
    firstUsed?: string;
    lastUsed?: string;
    claimCount?: number;
    usedBy: CouponUsage[];

    static createFromApi(dto: DashCouponMetaDataDTO): DashCouponMetaData{
        if(dto) {
            return Object.assign(new DashCouponMetaData(), dto, {
                firstUsed: dto.firstUsed?dayjs(dto.firstUsed+'Z').toDate():null,
                lastUsed: dto.lastUsed?dayjs(dto.lastUsed+'Z').toDate():null,
            });
        }

        return null;
    }
}


export class DashCouponDetails extends DashCoupon implements DashCouponDetailsDTO {
    asDiscount: CouponAsDiscount;
    asFreeProduct: CouponAsFreeProduct;
    uniqueCodes: UniqueCode[];

    initialize(): void {
        this.usesSharedCouponCode = true;
        this.validity = new DashCouponValidity();

        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
        this.validity.startDate = startDate;

        this.validity.claimCountLimit = 0;
        this.type = CouponType.Discount;
        this.asFreeProduct = {
            selectedProducts: []
        };
        this.asDiscount = {
            discountAmount: 0,
            maxDiscountablePrice: 100,
            inclusionType: "Unrestricted",
            selectedProducts: []
        };
        this.uniqueCodePrefix = null;
        this.uniqueCodeCharacterTypeId = 2; //Alphanumerical
    }


    mutate(newProps: any): DashCouponDetails {
        return Object.assign(new DashCouponDetails(), this, newProps);
    }
    static createFromApi(dto: DashCouponDetailsDTO, setOriginal = false, includeMetaData = false): DashCouponDetails{
        if(dto) {
            return Object.assign(new DashCouponDetails(), dto, {
                validity: DashCouponValidity.createFromApi(dto.validity),
                metaData: includeMetaData ? DashCouponMetaData.createFromApi(dto.metaData): null,
                original: setOriginal ? this.createFromApi(dto, false, false) : null,
            });
        }

        return null;
    }

    toApi(): DashCouponDetailsDTO {
        return Object.assign({}, this, {
            original: undefined,
            validity: this.validity.toApi(),
            metaData: null, //metadata not necessary when creating or saving...
        });
    }

    getOriginal(): DashCouponDetails {
        return this.original? this.original as DashCouponDetails : null;
    }

    discardAllChanges(): DashCouponDetails {
        return Object.assign(new DashCouponDetails(),this.original, {
            original: this.original
        });
    }

    hasChanged(): boolean {
        const orig = this.getOriginal();
        return (orig.name !== this.name
            || orig.type !== this.type
            || orig.usesSharedCouponCode !== this.usesSharedCouponCode
            || orig.sharedCouponCode !== this.sharedCouponCode
            || orig.asDiscount?.inclusionType !== this.asDiscount?.inclusionType
            || orig.asDiscount?.maxDiscountablePrice !== this.asDiscount?.maxDiscountablePrice
            || orig.asDiscount?.discountAmount !== this.asDiscount?.discountAmount
            || orig.asDiscount?.selectedProducts !== this.asDiscount?.selectedProducts
            || orig.asFreeProduct?.selectedProducts !== this.asFreeProduct?.selectedProducts
            || orig.validity?.startDate?.toString() !== this.validity?.startDate?.toString()
            || orig.validity?.endDate?.toString() !== this.validity?.endDate?.toString()
            || orig.validity?.claimCountLimit !== this.validity?.claimCountLimit
            || orig.uniqueCodePrefix !== this.uniqueCodePrefix
            || orig.uniqueCodeCharacterTypeId !== this.uniqueCodeCharacterTypeId);
    }
}


interface DashCouponValidityDTO {
    startDate?: Date;
    endDate?: Date;
    claimCountLimit?: number;
}

interface DashCouponMetaDataDTO {
    firstUsed?: string;
    lastUsed?: string;
    claimCount?: number;
}
export interface DashCouponDTO {
    id: number;
    name: string;
    discountText: string;
    restrictionsText: string;
    status: string;
    type: CouponType;
    usesSharedCouponCode: boolean;
    validity: DashCouponValidityDTO;
    metaData: DashCouponMetaDataDTO;
}

export interface DashCouponDetailsDTO extends DashCouponDTO {
    asDiscount: CouponAsDiscount;
    asFreeProduct: CouponAsFreeProduct;
    uniqueCodes: UniqueCode[];
}

export interface CouponAsDiscount {
    discountAmount: number;
    maxDiscountablePrice: number;
    inclusionType: string;
    selectedProducts: DashCouponProduct[];
}

export interface CouponAsFreeProduct {
    selectedProducts: DashCouponProduct[];
}

export class CouponUsage {
    userId: number;
    orderId: number;
    couponCode: string;
    useDate: Date;

    static createFromApi(dto: CouponUsageDTO): CouponUsage {
        return Object.assign(new CouponUsage(), dto, {
            useDate: dto.useDate?dayjs(dto.useDate+'Z').toDate():null,
        });
    }

}

export interface CouponUsageDTO {
    userId: number;
    orderId: number;
    couponCode: string,
    useDate: string;
}

export interface UniqueCode {
    id: number;
    orderId: number;
    userId: number;
    couponCode: string;
}


/*
 {
            "id": 2,
            "name": "My Coupon",
            "discountText": "My Coupon",
            "validity": {
                "startDate": "2024-03-11T00:00:00",
                "endDate": "2024-03-31T23:59:59",
                "claimCountLimit": 10
            },
            "status": "Draft",
            "metaData": {
                "claimCount": 0
            },
            "type": "FreeProduct",
            "usesSharedCouponCode": false
        },
* */

