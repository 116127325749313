import { ApiModel } from '../API/InkyAPI';
import { Atom, atom } from 'jotai/index';
import { useSetAtom } from 'jotai';

/**
 * General pattern, should be able to trigger a fetch with options i.e mainly abort ,
 * have access to the fetch status somehow.
 * have the fetch trigger automatically onmount if enough data available.
 */

export type APIFetchStatus<T> =
    {status:"complete",data:T}
    |{status:"fetching",abortController:AbortController, data?:T}
    |{status:"unFetched"}
    |{status:"error", message:string}

export const createFetchAtoms = <T>(fetchCall:(abortSignal?: AbortSignal) => Promise<ApiModel<T>>,empty?:T) => {
    const baseFetchAtom = atom<APIFetchStatus<T>>({status:"unFetched"});

    baseFetchAtom.onMount = (setAtom) => {
        const abortController = new AbortController();
        setAtom({status:"fetching",abortController:abortController})
        fetchCall(abortController.signal).then((res) => {
            console.debug('result from atom', res);
            if (res.status.type =="Success"){
                setAtom({status:"complete", data:res.response});
            }else {
                setAtom({status:"error",message:"TODO some error message"})
            }
        }).catch((error) => {
            if (abortController.signal.aborted){
                console.debug(abortController.signal.reason)
                setAtom({status:"error",message:"TODO some error message"})
            }
            else {
                throw error;
            }
        });
        //Get rid of the product when we close the Editor (unmount).
        return () => {
            abortController.abort("Unmounted BadgeTotal")
            setAtom(null);
        }
    }
    const ReadData:Atom<T> =atom ((get) => {
        const current =get(baseFetchAtom)
        if (current?.status ==="complete"|| (current?.status ==="fetching" && current?.data)) return current?.data;
        else return empty
    })
    //Why is "get(baseFetchAtom)?" null
    const ReadFetchStatus = atom(get => get(baseFetchAtom)?.status);

    const fetchData = atom(null,async (get,set) =>{
        const old = get(baseFetchAtom)
        const newAbortController = new AbortController();

        switch (old.status){
            case 'complete': {
                set(baseFetchAtom, { data: old.data, status: "fetching", abortController: newAbortController })
                break;
            }
            case 'fetching': {
                old.abortController.abort("Requested new Fetch");
                set(baseFetchAtom, { status: "fetching", data: old.data, abortController: newAbortController })
                break;
            }
            default: {
                set(baseFetchAtom, { status: "fetching", abortController: newAbortController })
                break;
            }

        }

        await fetchCall(newAbortController.signal).then((res) => {
            console.debug('result from atom', res);
            if (res.status.type =="Success"){
                set(baseFetchAtom,{status:"complete", data:res.response});
            } else {
                set(baseFetchAtom,{status:"error",message:"TODO some error message"})
            }
        }).catch(error => {
            if (newAbortController.signal.aborted){
                console.debug(newAbortController.signal.reason)
            }
            else {
                set(baseFetchAtom,{status:"error",message:"TODO some error message"})
                throw error;
            }
        });
    })

    return [ReadData,ReadFetchStatus,fetchData] as const
}
/**
 *  T is resulting data, U is DTO
 * @param fetchCall
 * @param empty
 * @param fromApi
 */
export const createFetchAtomsDTO = <T,U>(fetchCall:(abortSignal?: AbortSignal) => Promise<ApiModel<U[]>>, fromApi :(dto: U) =>T,empty?:T[],) => {
    const baseFetchAtom = atom<APIFetchStatus<T[]>>({status:"unFetched"});

    baseFetchAtom.onMount = (setAtom) => {
        const abortController = new AbortController();
        setAtom({status:"fetching",abortController:abortController})
        fetchCall(abortController.signal).then((res) => {
            console.debug('result from atom', res);
            if (res.status.type =="Success"){
                setAtom({status:"complete", data:res.response.map(e => fromApi(e))});
            }else {
                setAtom({status:"error",message:"TODO some error message"})
            }
        }).catch((error) => {
            if (abortController.signal.aborted){
                console.debug(abortController.signal.reason)
                setAtom({status:"error",message:"TODO some error message"})
            }
            else {
                throw error;
            }
        });
        //Get rid of the product when we close the Editor (unmount).
        return () => {
            abortController.abort("Unmounted BadgeTotal")
            setAtom(null);
        }
    }
    const ReadData:Atom<T[]> =atom ((get) => {
        const current =get(baseFetchAtom)
        if (current?.status ==="complete"|| (current?.status ==="fetching" && current?.data)) return current?.data;
        else return empty
    })
    //Why is "get(baseFetchAtom)?" null
    const ReadFetchStatus = atom(get => get(baseFetchAtom)?.status);

    const fetchData = atom(null,async (get,set) =>{
        const old = get(baseFetchAtom)
        const newAbortController = new AbortController();

        switch (old.status){
            case 'complete': {
                set(baseFetchAtom, { data: old.data, status: "fetching", abortController: newAbortController })
                break;
            }
            case 'fetching': {
                old.abortController.abort("Requested new Fetch");
                set(baseFetchAtom, { status: "fetching", data: old.data, abortController: newAbortController })
                break;
            }
            default: {
                set(baseFetchAtom, { status: "fetching", abortController: newAbortController })
                break;
            }

        }

        await fetchCall(newAbortController.signal).then((res) => {
            console.debug('result from atom', res);
            if (res.status.type =="Success"){
                set(baseFetchAtom,{status:"complete", data:res.response.map(e => fromApi(e))});
            } else {
                set(baseFetchAtom,{status:"error",message:"TODO some error message"})
            }
        }).catch(error => {
            if (newAbortController.signal.aborted){
                console.debug(newAbortController.signal.reason)
            }
            else {
                set(baseFetchAtom,{status:"error",message:"TODO some error message"})
                throw error;
            }
        });
    })

    return [ReadData,ReadFetchStatus,fetchData] as const
}

export enum Platform {
    Web,
    Steam
}

export const PlatformAtom = atom<Platform>(Platform.Web);
