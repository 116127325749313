//ToDo: Move to Model.
import * as _ from 'lodash';
import { Selectable } from '../Selectable';
import { NameTuple } from '../FilterTuple';
import { OrderStatus } from '../Order';

class DashOrder implements Selectable {
    id: number;
    accountingId: string;
    customerName: string;
    invoiceStatusId: number;
    orderStatus: OrderStatus;
    orderTotalPrice: number;
    shippingAddress: DashAddress;
    orderDate: Date;
    invoicePrinted: boolean;
    tags: NameTuple[];

    selected: boolean; //Selectable implementation.
}

class DashAddress {
    firstName: string;
    surName: string;
    companyName: string;
    country: {
        key: string;
        value: string;
    };
    address: string;
    address2: string;
    locality: string;
    postalCode: string;
    administrativeArea: string;
    phoneNumber: string;
    email: string;

    static areExactMatch(a: DashAddress, b: DashAddress): boolean{
        const obj = Object.keys, ta = typeof a, tb = typeof b;
        return a && b && ta === 'object' && ta === tb ? (
            obj(a).length === obj(b).length &&
            obj(a).every(key => this.areExactMatch(a[key], b[key])) && a.constructor === b.constructor) :
            (a === b && (a != null && b != null));
    }
}

class DashOrderDetails extends DashOrder {
    billingAddress: DashAddress;
    orderItems: DashOrderItem[];

    original: DashOrderDetails;

    hasChanges(): boolean {
        const orig = this.original;
        return !(
            this.customerName === orig.customerName &&
                this.invoiceStatusId === orig.invoiceStatusId &&
                this.orderStatus === orig.orderStatus &&
                this.invoicePrinted === orig.invoicePrinted &&
                DashAddress.areExactMatch(this.billingAddress, orig.billingAddress) &&
                DashAddress.areExactMatch(this.shippingAddress, orig.shippingAddress)
        );
    }

    static createFromOriginal(original: DashOrderDetails): DashOrderDetails {
        const dod = new DashOrderDetails();
        Object.assign(dod, original);
        dod.original = original;

        return dod;
    }
}

class DashCustomOrderNoteRequest {
    orderId: number;
    message: string;
    logLevel: OrderNoteLogLevel;
}

enum OrderNoteLogLevel {
    Info = "Info",
    Success = "Success",
    Error = "Error",
}

class DashOrderItem {
    productName: string;
    variation: string;
    sku: string;
    costInUsd: number;
    quantity: number;
    totalCostInUsd: number;
}



export {DashOrder, DashAddress, DashOrderDetails, DashOrderItem, DashCustomOrderNoteRequest, OrderNoteLogLevel};