import { PublishManager, DashBundle, IapsResult} from './';
import { AxiosResponse, CancelTokenSource } from 'axios';
import apiClient from "../../API/InkyAPI";

class BundleManager extends PublishManager<DashBundle> {

  constructor() {
    super();

    this.addItem = async(
      cancelTokenSource: CancelTokenSource,
      item: DashBundle
    ) => {
      console.log("Adding bundle...\n" + JSON.stringify(item));
      return await apiClient.post<DashBundle>("/dashboard/bundles/new",item, cancelTokenSource);
    };

    this.alterItems = (cancelTokenSource: CancelTokenSource, items: DashBundle[]) => {
      console.log("Altering bundles...\n" + JSON.stringify(items));
      return apiClient.post<DashBundle[]>("/dashboard/bundles/alter", items, cancelTokenSource);
    };

    this.publishItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      return apiClient.post<Object>("/dashboard/bundles/publish?id="+id, null, cancelTokenSource);
    };

    this.unPublishItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      return apiClient.post<Object>("/dashboard/bundles/unpublish?id="+id, null, cancelTokenSource);
    };

    this.removeItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      return apiClient.post<Object>("/dashboard/bundles/remove?id="+id, null, cancelTokenSource);
    };

    this.fetchItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      const item = await apiClient.get<DashBundle>(`/dashboard/bundles/${id}`, cancelTokenSource);
      const ds = new DashBundle();
      ds.original = item.data;
      ds.copyFromOriginal();

      return ds;
    };
  }

  async getPublishStatus(item: DashBundle): Promise<IapsResult> {
    console.log("Getting publish status");
    const appleSkuCode = null; //item.codes.find(x => x.type === "SKU" && x.variant=== "APPLE");
    const androidSkuCode = null; //item.codes.find(x => x.type === "SKU" && x.variant=== "ANDROID");
    if (process.env.REACT_APP_USING_IAPS && (appleSkuCode || androidSkuCode)) {
      //return {appleIapStatus: "???"}
      const res = await apiClient.get<IapsResult>(`/dashboard/bundles/${item.id}/iaps`, this.tokenSource);
      console.log(res.data);
      return res.data;
    }
    else {
      const res = new IapsResult();
      res.id = item.id;

      return res;
    }
  }

  async submitIAPs(bundleId: number): Promise<AxiosResponse<Object>> {
    return apiClient.post<Object>(`/dashboard/bundles/${bundleId}/iaps/add`, null, this.tokenSource);
  }
}


export default BundleManager;
