import React, { useEffect, useState } from 'react';
import './SDCC.scss';
import './AX.scss';
import { useAtom } from 'jotai';
import { BadgesCache, fetchBadges } from '../store/BadgesStore';
import { AccountInfoAtom, LoginStatusAtom } from '../store/AccountStore';
import { Helmet } from 'react-helmet';
import { KodanshaNavbar } from './KodanshaNavbar/KodanshaNavbar';
import SeriesPoster from './KodanshaUIKit/SeriesPoster/SeriesPoster';
// import MALHeader from './MAL/images/AfterParty_Hero.png';
import { KodanshaFooter } from './KodanshaFooter/KodanshaFooter';
import IntroMAL from './MAL/IntroMAL';
import ExclusivesMAL from './MAL/ExclusivesMAL';
import SeriesMAL from './MAL/SeriesMAL';
import DiscountMal from './MAL/DiscountMAL';
import { BadgeResponse } from './MAL/BadgeMAL';
import NewsMAL from './MAL/NewsMAL';
import ChaptersMAL from './MAL/ChaptersMAL';
import AfterPartyMAL from './MAL/AfterPartyMAL';
import NoCodes from './MAL/NoCodes';
import { useResetAtom } from 'jotai/utils'
import ExclusivesMALFinished from './MAL/ExclusivesMALFinished';
// import ExclusivesMALFinished from './MAL/ExclusivesMALFinished';
enum StatesType {
    Normal,
    AfterParty,
    NoCodes
}

const MAL = (props) => {
    const [data, fetch] = useAtom(fetchBadges);
    const [loginStatus] = useAtom(LoginStatusAtom);
    const resetBadgesCache = useResetAtom(BadgesCache);
    const [userData] = useAtom(AccountInfoAtom);
    const [redeemComics, setRedeemComics] = useState(false);
    const [MalBadge, setMalBadge] = useState<BadgeResponse>(undefined);
    const [state, useStateType] = useState<StatesType>(StatesType.AfterParty);

    useEffect(() => {
        if (userData.email && !data) {
            fetch({ hardFetch: false });
        }

        if (userData.email && data) {
            if (data?.some(b => b.badge.id === 3)) {
                setRedeemComics(true);
                setMalBadge(data?.find(b => b.badge.id === 3));
            }
        }
    }, [data, userData.email, redeemComics]);

    useEffect(() => {
        if (loginStatus === "LoggedOut") {
            setRedeemComics(false);
            setMalBadge(undefined)
            resetBadgesCache()
        }
    }, [loginStatus])

    return (
        <div className='anime-expo-container'>
            <Helmet>
                <title>{'Kodansha Worldwide After Party | Kodansha – Attack On Titan'}</title>
            </Helmet>
            <KodanshaNavbar />
            <div className={'AXHero'}>
                <SeriesPoster
                    color={'transparent'}
                    hover={false}
                    ratio={33}
                    src={'https://cdn.kodansha.us/statics/afterparty/AfterParty_Hero.png'}
                    isGray={false}
                    s={{width: 576}}
                    m={{width: 768}}
                    l={{width: 992}}
                    xl={{width: 1440}}
                />
            </div>
            <div className='anime-expo-max-wrapper'>
                {state === StatesType.Normal && <>
                    <IntroMAL redeemComics={redeemComics} setRedeemComics={setRedeemComics} />
                    {redeemComics && loginStatus === 'LoggedIn' && <DiscountMal badge={MalBadge} />}
                </>}


                {state === StatesType.AfterParty && <AfterPartyMAL />}

                {state === StatesType.NoCodes && <NoCodes />}


                {/*<ExclusivesMAL />*/}
                <ExclusivesMALFinished />

                <NewsMAL />
            </div>


            <div className='mal-color'>
                <div className='anime-expo-max-wrapper'>
                    <ChaptersMAL />
                </div>
            </div>

            <div className='mal-color'>
                <div className='anime-expo-max-wrapper'>
                    <SeriesMAL />
                </div>
            </div>

            <KodanshaFooter />
        </div>
    );
};

export default MAL;