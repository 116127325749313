import {
  OrderedPublishManager, DashboardHomeListEntry, createDashboardHomeListEntryFrom
} from './';
import dashboard from "../../API/DashboardAPI";
import { CancelTokenSource } from 'axios';

class HomeListManager extends OrderedPublishManager<DashboardHomeListEntry> {

  constructor() {
    super();

    this.fetchItems = async(cancelTokenSource: CancelTokenSource) => {
      const res = await dashboard.getAllWheelItems(this.tokenSource);
      return res.data.map(x => createDashboardHomeListEntryFrom(x));
    }

    this.removeItem = dashboard.removeWheelItem;
    this.publishItem = dashboard.publishWheelItem;
    this.unPublishItem = dashboard.unPublishWheelItem;
    this.alterItems = dashboard.alterWheelItems;
    this.reOrderItems = dashboard.reOrderWheelItems;
    this.addItem = dashboard.addNewWheelItem;
  }

}

export default HomeListManager;
