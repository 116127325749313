import { Purchase } from '../Purchase';
import Bugsnag from '@bugsnag/js';
import {StripeError} from '@stripe/stripe-js';

const PURCHASE_ERROR_CONTEXT = 'User-checkout';

export class PurchaseError extends Error {
    purchase: Purchase;
    displayMessage: string;

    paymentMethod: string;

    innerError: any;

    /**
     * Basic constructor (always include a purchase and context).
     * @param message
     * @param context
     * @param purchase
     * @param paymentMethod
     * @param innerError
     */
    constructor(message: string,  purchase: Purchase,
                paymentMethod: string = null, innerError: any = null,
                displayMessage: string = null) {
        super(message);

        // https://github.com/microsoft/TypeScript-wiki/blob/81fe7b91664de43c02ea209492ec1cea7f3661d0/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, PurchaseError.prototype);

        this.name = 'PurchaseError';
        this.purchase = purchase;
        this.paymentMethod = paymentMethod;
        this.innerError = innerError;
        this.displayMessage = displayMessage;

    }

    notifyBugsnag(): void {
        let metaData = {
            "purchase": this.purchase
        };

        if(this.innerError) {
            metaData = Object.assign(metaData, {"Error-Message": this.innerError.message ?? '' });
        }
        if(this.paymentMethod) {
            metaData = Object.assign(metaData, { "Payment-method": this.paymentMethod});
        }

        Bugsnag.notify(this, function(event) {
            event.addMetadata("Additional Info", metaData);
            event.context = PURCHASE_ERROR_CONTEXT;
        });
    }

    getDisplayMessage(): string {
        if(this.displayMessage) {
            return this.displayMessage;
        }

        return this.message;
    }
}
