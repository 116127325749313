import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './ReaderPage.scss';
import apiClient from '../../API/InkyAPI';
import Axios, { CancelTokenSource } from 'axios';

interface ReaderPageRouterProps {
    title?: string;
}

export interface ReaderPageProps
    extends RouteComponentProps<ReaderPageRouterProps> {
    pageNumber: number;
    url: string;
    width: number;
    height: number;
    opacity: number;
    isLoaded: boolean;
    comicId: number;
}

interface ReaderPageState {
    fullUrl: string;
}

class SinglePage {
    url: string;
}

class ReaderPage extends React.Component<ReaderPageProps, ReaderPageState> {
    image: HTMLDivElement;
    private tokenSource: CancelTokenSource;

    constructor(props: ReaderPageProps) {
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadImage = this.loadImage.bind(this);
        this.render = this.render.bind(this);
        this.state = {
            fullUrl: '',
        };
    }

    async loadImage() {
        apiClient
            .get<SinglePage>("/comic/" + this.props.comicId + "/pages/" + this.props.pageNumber, this.tokenSource)
            .then(response => {
                this.setState({
                    fullUrl: response.data.url,
                });
            })
            .catch(reason => {
                //TODO: We need some visuals for the user.
                console.log(reason);
            });
    }

    componentDidMount() {
        this.tokenSource = Axios.CancelToken.source();
        if (this.props.isLoaded) this.loadImage();
    }

    componentDidUpdate(prevProps: ReaderPageProps) {
        if (!prevProps.isLoaded && this.props.isLoaded) {
            this.loadImage();
        } else if (prevProps.isLoaded && !this.props.isLoaded) {
            this.setState({ fullUrl: '' });
        }
    }

    componentWillUnmount = () => {
        this.tokenSource.cancel();
    };

    render() {
        let css = {
            width: this.props.width,
            height: this.props.height,
            opacity: this.props.opacity,
            backgroundImage: `url("${this.state.fullUrl}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat"
        };

        return (
            <div>
                <div data-pagenumber={this.props.pageNumber + 1} ref={e => (this.image = e)} className="lazy reader-page" style={css} />
            </div>
        );
    }
}

export default withRouter(ReaderPage);
