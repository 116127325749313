export enum AgeRating {
    E = 'e',
    E7 = 'e7',
    PG = 'pg',
    T = 't',
    M = 'm',
}

export function ageToAgeRating(age: number): AgeRating {
    if (age < 7) {
        return AgeRating.E;
    }
    if (age < 12) {
        return AgeRating.E7;
    }
    if (age < 15) {
        return AgeRating.PG;
    }
    if (age < 18) {
        return AgeRating.T;
    }
    return AgeRating.M;
}

export function ageRatingToInt(rating: AgeRating): number {
    switch (rating) {
        case AgeRating.E:
            return 1;
        case AgeRating.E7:
            return 2;
        case AgeRating.PG:
            return 3;
        case AgeRating.T:
            return 4;
        case AgeRating.M:
            return 5;
    }
}

export interface AgeRatingMetadata {
    mappedToServerLetter: AgeRating;
    letter: string;
    text: string;
    description: string;
    color: string;
}

export const ageRatingMetadata: AgeRatingMetadata[] = [
    {
        mappedToServerLetter: AgeRating.E,
        letter: 'E',
        text: 'Content appropriate for all ages.',
        description: 'Everyone',
        color: '#1FCECB',
    },
    {
        mappedToServerLetter: AgeRating.E7,
        letter: 'E7',
        text: 'Mild violence, mild language or minimal suggestive themes.',
        description: 'Everyone 7+',
        color: '#9ACD32',
    },
    {
        mappedToServerLetter: AgeRating.PG,
        letter: 'PG',
        text: 'Moderate violence, some sexual situations, infrequent coarse language or some suggestive dialogue.\n',
        description: 'Parental Guidance',
        color: '#FFDF00',
    },
    {
        mappedToServerLetter: AgeRating.T,
        letter: 'T',
        text: 'Intense violence, intense sexual situations or intensely suggestive dialogue.\n',
        description: 'Teen',
        color: '#FFA500',
    },
    {
        mappedToServerLetter: AgeRating.M,
        letter: 'M',
        text: 'Graphic violence, explicit sexual activity or crude indecent language.\n',
        description: 'Mature',
        color: '#E34234',
    },
];
