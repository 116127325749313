export enum LogLevels {
    DEBUG,
    INFO,
    WARN,
    ERROR,
}

export default class Log {
    static levels = LogLevels;
    static currentLevel = parseInt(process.env.REACT_APP_LOG_LEVEL);

    constructor() {
    }

    static error(message, err?: any) {
        if (Log.currentLevel <= Log.levels.ERROR) {
            console.error(`[ERROR] ${message}`, err);
        }
        //ToDO: Add Bugsnag stuff here? Include the error?
    }

    static warn(message) {
        if (Log.currentLevel <= Log.levels.WARN) {
            console.warn(`[WARN] ${message}`);
        }
    }

    static info(message, obj?: any, obj2?: any) {
        if (Log.currentLevel <= Log.levels.INFO) {
            if(obj === undefined) {
                console.info(`[INFO] ${message}`);
            }
            else {
                if(obj2 === undefined) {
                    console.info(`[INFO] ${message}`, obj);
                }
                else {
                    console.info(`[INFO] ${message}`, obj, obj2);
                }
            }
        }
    }

    static debug(message, obj?: any, obj2?: any) {
        if (Log.currentLevel <= Log.levels.DEBUG) {
            if(obj === undefined) {
                console.log(`[DEBUG] ${message}`);
            }
            else {
                if(obj2 === undefined) {
                    console.log(`[DEBUG] ${message}`, obj);
                }
                else {
                    console.log(`[DEBUG] ${message}`, obj, obj2);
                }
            }
        }
    }
}
