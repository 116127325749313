import React, { useEffect, useMemo, useState } from 'react';
import './IntroMAL.scss';
import { ReactComponent as Icon } from '../KodanshaButton/Assets/Survey_Kodansha.svg';
import apiClient from '../../API/InkyAPI';
import { useAtom } from 'jotai';
import { AccountInfoAtom, LoginStatus, LoginStatusAtom } from '../../store/AccountStore';
import { fetchBadges, fetchClaimComics } from '../../store/BadgesStore';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import { Link } from '../../Devon/Components/InkyLink';
import KodanshaArrow from '../KodanshaUIKit/KodanshaArrow/KodanshaArrow';

const SecondHeader = {
    0: 'ATTACK ON TITAN',
    1: '',
    2: '',
};

interface GiftItem {
    type: 'LOCK' | 'CURRENT' | 'ACTIVE';
    text: string;
    giftId: number;
}

export type GiftItemProps = Partial<GiftItem & React.HTMLAttributes<HTMLDivElement>>;

const GiftItem = ({ text, type, giftId, ...props }: GiftItemProps) => {
    return <div className={'gift-item-container'}>
        <span className={'gift-state-number'}>Step {giftId}</span>
        <div className={'gift-item-wrapper'} {...props}>
            {type === 'ACTIVE' && <Icon />}

            {giftId === 3 && type === 'CURRENT' ?
                <Link target={'_blank'} className={`type-${type} type-${giftId} link-join-the-party-mal`}
                      to={{ pathname: 'https://party.shingeki.tv/' }}>
                     <span className={`type-${type} type-${giftId}`}>
                        {text}
                    </span>
                </Link>
                : <span className={`type-${type} type-${giftId}`}>
                {text}
            </span>
            }

        </div>
    </div>;
};

const BadgeOrLogin = (props) => {
    const states = useMemo(() => ({
        0: <>
            <h3 className='intro-third-header'>A fresh perk just for Kodansha Readers</h3>
            <span className='intro-ml-text'>
                <u onClick={() => props.setLoginOverlay(true)}>Log in</u> or <u
                onClick={() => props.setLoginOverlay(true)}>sign up</u> to claim your discount code.
            </span>
        </>,
        1: <>
            <h3 className='intro-third-header'>Thanks for being a Kodansha Reader!</h3>
            <span className='intro-ml-text'>
                To show you our appreciation for being a Kodansha Reader, we’re offering this exclusive discount* on a ticket to the <i>Attack on Titan</i> Worldwide After Party. Just click or tap the “Claim Code” button next to this section to display your code.
            </span>
            <span className='note-text'>
                *Only one discount code is available per Kodansha Reader account. Discount codes cannot be reused.
            </span>
        </>,
        2: <>
            <h3 className='intro-third-header'>See you at the party!</h3>
            {/*<span className='intro-ml-text'>*/}
            {/*    We hope you enjoy your event-exclusive gifts and continue to explore new manga by accessing the hundreds of free chapters found on this site. Happy reading!*/}
            {/*</span>*/}

            {/*<Link to={'/library'} className={'intro-link'}>*/}
            {/*    <KodanshaArrow text={'take me to my library'} withArrow={false} />*/}
            {/*</Link>*/}
        </>,
    }), []);

    return states[props.id];
};

const IntroMAL = (props) => {
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);
    const [step, setStep] = useState(props.redeemComics ? 2 : 0);
    const [error, setError] = useState(null);
    const [, fetch] = useAtom(fetchBadges);

    useEffect(() => {
        if (loginStatus === 'LoggedIn') {
            if (props.redeemComics) {
                setStep(2);
                return;
            }
            setStep(1);
        } else {
            setStep(0);
        }
    }, [loginStatus, props.redeemComics]);

    const claimRewards = () => {
        apiClient.claimUserBadge(3).then((response) => {
            if (response.data.status.message === 'Badge cannot be claimed: no more available gifts') {
                setError('Not enough gifts');
                return;
            }
            setStep(2);
            fetch({ hardFetch: true });
        });
    };

    return (
        <div id={'intro-ax'} className='intro-ax-container'>
            <div className={'text-info-wrapper'}>
                <h1 className='intro-header'>Attack on Titan</h1>
                <h2 className='intro-second-header'>Worldwide After Party</h2>
                <BadgeOrLogin setLoginOverlay={setLoginOverlay} id={step} />
            </div>

            <div className={'gift-state-wrapper'}>
                {loginStatus === 'LoggedOut' && <GiftItem giftId={1} text={'Login'}
                                                          onClick={() => step === 0 && setLoginOverlay(true)}
                                                          type={step === 0 ? 'CURRENT' : 'ACTIVE'} />}
                {loginStatus === 'LoggedIn' && <GiftItem giftId={1} text={'Logged in'}
                                                         onClick={() => step === 0 && setLoginOverlay(true)}
                                                         type={step === 0 ? 'CURRENT' : 'ACTIVE'} />}
                <GiftItem giftId={2} text={step === 2 ? 'Code claimed' : 'Claim discount code'}
                          onClick={step === 1 && claimRewards}
                          type={step === 1 ? 'CURRENT' : step === 2 ? 'ACTIVE' : 'LOCK'} />
                <GiftItem giftId={3} text={step === 2 ? 'Join the party' : 'Join the party'}
                          type={step === 2 ? 'CURRENT' : 'LOCK'} />

                {error !== null && <span className='gift-error'>{error}</span>}
            </div>
        </div>
    );
};

export default IntroMAL;
