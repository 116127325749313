import React from 'react';
import './ExclusivesSDCC.scss';
import SDCCGift from './images/SDCC23_Button 1280x960.png';
import { scrollToItem } from '../../utils/utils';

const ExclusivesSDCC = () => {
    return (
        <div className='exclusives-ax-container'>
            <div className={'text-info-wrapper'}>
                <h1 className='intro-header'>SDCC23 Exclusives</h1>
                <h2 className='intro-second-header'>Event-limited gifts</h2>
                <span className='intro-text'>
                    Newly created <strong>Kodansha Reader </strong> accounts received SDCC23-exclusives gifts, including:
                </span>

                <ul className={'ex-list'}>
                    <li>A <i>Vinland Saga</i> button*</li>
                    <li>Access to read SDCC23 Spotlight Series**</li>
                    <li><span onClick={()=>scrollToItem('digital-manga-samplers-title', 120)}>Digital manga samplers</span> organized by genre</li>
                </ul>

                <span className='intro-booth'>
                    Visitors to our booth also got these other freebies:
                </span>

                <ul className={'ex-list'}>
                    <li className={'ex-list-item-italic'}>
                        <i>Vinland Saga</i> SDCC23 exclusive manga samplers*
                    </li>
                    <li className={'ex-list-item-italic'}>Buttons for our SDCC23 featured titles*</li>
                </ul>


                <div className={'explain-wrapper'}>
                    <span className='intro-explain'>
                        * While supplies last
                    </span>
                    <span className='intro-explain'>
                        ** Availability of extended previews vary by series.
                    </span>
                </div>
            </div>

            <div className={'gift-wrapper'}>
                <img src={SDCCGift} />
            </div>
        </div>
    );
};

export default ExclusivesSDCC;