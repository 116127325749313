import React from 'react';
import './BoothSDCC.scss';
import KodanshaArrow from '../KodanshaUIKit/KodanshaArrow/KodanshaArrow';
import { Link } from '../../Devon/Components/InkyLink';
import SDCCBooth from './images/SDCC23 News 1280x960.png';

const BoothSDCC = () => {
    return (
        <div className='ax-booth-container'>
            <div className='booth-image-wrapper'>
                <img alt={'ax 2023 booth image'} className='booth-image' src={SDCCBooth} />
            </div>


            <div className='text-info-wrapper'>
                <h3 className='third-header'>BOOTH #5542</h3>
                <h3 className='first-header'>Discover Kodansha at SDCC23</h3>

                <span className='check-show'>All our activities at the show:</span>

                <ul className={'show-list'}>
                    <li className={'show-list-item'}>Panels & Autograph Sessions</li>
                    <li className={'show-list-item'}>Event-exclusives & sketch giveaway</li>
                    <li className={'show-list-item'}><i>Vinland Saga</i> Deluxe Edition pre-orders</li>
                </ul>

                <Link to={'/sdcc23'} className={'link-to-details'}>
                    <KodanshaArrow text={'Details'} />
                </Link>
            </div>


        </div>
    );
};

export default BoothSDCC;