import React, { lazy, Suspense, useEffect, useState } from 'react';

// import './mixins/grid.scss';
// import './mixins/spacing.css';
// import './mixins/fonts.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './KodanshaApp.scss';
import './Reboot.scss';

import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { AccessTokenAtom, AccountInfoAtom, LoginStatusAtom } from '../store/AccountStore';
import { CheckoutOverlayVisible, LoginOverlayVisible } from '../store/OverlayStore';
import { PaymentIntentAtom, PaymentMethodAtom, ShowPaymentProcessingOverlayAtom } from '../store/PaymentStore';
import { CartIdAtom, GetAddToCartOverlayBox } from '../store/CartStore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';
import { useLocalStorage } from './Services/localstorage/useLocalStorage';
import { LocalStorageKeys } from './Services/localstorage/LocalStorageKeys';
import ReactGA from 'react-ga4';
import { AnalyticsIdAtom, AnalyticsUserProxyAtom } from '../store/AnalyticsStore';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { Md5 } from 'ts-md5';
import apiClient from '../API/InkyAPI';
import AnalyticsEvent from '../Models/Analytics/AnalyticsEvent';
import { Helmet } from 'react-helmet';
import SDCC from './SDCC';
import Log from './Services/Logger';
import { UseStripeWrapperAtom } from '../store/LoadStripeStore';
import {GTagManager, GTagManagerTriggerLoad } from '../Devon/Components/GTagManager/GTagManager';
import MAL from './MAL';
import { attachDebugMode, goFullInky } from '../store/DebugStore';
import { ensureTrailingSlash } from '../Devon/Components/InkyLink';
// React-components (Remember to lazy-load)
const Account = lazy(
    () => import('./Routes/Account/Account'),
);
// Lazy-loaded components
const KodanshaNews = lazy(
    () => import('./KodanshaNews').then(module => ({ default: module.KodanshaNews })),
);
const KodanshaArticle = lazy(
    () => import('./KodanshaArticle').then(module => ({ default: module.KodanshaArticle })),
);
const KodanshaArticlePreview = lazy(
    () => import('./KodanshaArticlePreview').then(module => ({ default: module.KodanshaArticlePreview })),
);
const KodanshaCheckout = lazy(
    () => import('./KodanshaCheckout').then(module => ({ default: module.KodanshaCheckout })),
);
const KodanshaReader = lazy(
    () => import('./KodanshaReader').then(module => ({ default: module.KodanshaReader })),
);
const KodanshaReadableReader = lazy(
    () => import('./KodanshaReader').then(module => ({ default: module.KodanshaReadableReader })),
);
const KodanshaHomeFallback = lazy(() => import('./KodanshaHomeFallback'));
const Dashboard = lazy(
    () => import('../Components/Dashboard/Dashboard'),
);
const KodanshaSeries = lazy(
    () => import('./Routes/Series/KodanshaSeries'),
);
const KodanshaAuth = lazy(
    () => import('./KodanshaAuth/KodanshaAuth'),
);
const KodanshaLibrary = lazy(
    () => import('./KodanshaLibrary').then(module => ({ default: module.KodanshaLibrary })),
);
const KodanshaProduct = lazy(
    () => import('./Routes/Product/KodanshaProduct'),
);
const KodanshaBrowse = lazy(
    () => import('./KodanshaBrowse').then(module => ({ default: module.KodanshaBrowse })),
);
const KodanshaSearch = lazy(
    () => import('./KodanshaSearch'),
);
const KodanshaCheckoutSuccess = lazy(
    () => import('./KodanshaCheckoutSuccess/KodanshaCheckoutSuccess'),
);
const KodanshaDiscover = lazy(
    () => import('./KodanshaDiscover').then(module => ({ default: module.KodanshaDiscover })),
);
const KodanshaReleaseCalendar = lazy(
    () => import('./KodanshaReleaseCalendar').then(module => ({ default: module.KodanshaReleaseCalendar })),
);
const CookiesConsent = lazy(
    () => import('./CookiesConsent/CookiesConsent'),
);
const AnimeExpo = lazy(
    () => import('./AX'),
);
const AccountBenefits = lazy(
    () => import('./StaticPages/AccountBenefits').then(module => ({ default: module.AccountBenefits })),
);
const KodanshaAbout = lazy(
    () => import('./KodanshaAbout/KodanshaAbout').then(module => ({ default: module.KodanshaAbout })),
);
/*const SupportCenter = lazy(
    () => import('./SupportCenter/SupportCenter').then(module => ({ default: module.SupportCenter })),
);*/
const AddedToCartOverlay = lazy(
    () => import('./KodanshaAddedToCartOverlay/AddedToCartOverlay').then(module => ({ default: module.AddedToCartOverlay })),
);
/*
const TermsOfUse = lazy(
    () => import('./StaticPages/TermsOfUse').then(module => ({ default: module.TermsOfUse })),
);
const PrivacyPolicy = lazy(
    () => import('./StaticPages/PrivacyPolicy').then(module => ({ default: module.PrivacyPolicy })),
);
const Copyright = lazy(
    () => import('./StaticPages/Copyright'),
);
const KodanshaLibrarians = lazy(
    () => import('./StaticPages/Librarians').then(module => ({ default: module.Librarians })),
);
*/


export function KodanshaApp(props): JSX.Element {




    const renderLoading = (): JSX.Element => <> </>;

    /**
     * All overlays except Login + Force mount necessary atoms.
     * @constructor
     */
    function Overlays() {
        const [checkoutOverlay] = useAtom(CheckoutOverlayVisible);
        const [addToCartOverlay] = useAtom(GetAddToCartOverlayBox);

        //The following need to be referenced so that we can trigger an onMount.
        const [AccessToken] = useAtom(AccessTokenAtom);
        const [CartId] = useAtom(CartIdAtom);

        //For the payment overlays (TODO: Check if these are truly necessary)
        const [paymentIntent] = useAtom(PaymentIntentAtom);
        const [paymentMethod] = useAtom(PaymentMethodAtom);
        const [showPaymentProcessingOverlay] = useAtom(ShowPaymentProcessingOverlayAtom);


        Log.debug("Render overlays...");
        return (
            <React.Fragment>
                {addToCartOverlay === true && <Suspense fallback={renderLoading()}><AddedToCartOverlay /></Suspense>}
                {checkoutOverlay && <Suspense fallback={renderLoading()}><KodanshaCheckout /></Suspense>}
            </React.Fragment>
        )
    }

    /**
     * Login overlay only. Leave this outside the stripe wrapper or it breaks the login.
     * @constructor
     */
    function LoginOverlay(): JSX.Element {
        const loginOverlay = useAtomValue(LoginOverlayVisible);
        if(loginOverlay) {
            return (
                <Suspense fallback={renderLoading()}><KodanshaAuth /></Suspense>
            );
        }
        return null;
    }

    function CookiesAndConsent() {
        const [CookiesConsentValue, setCookiesConsentValue] = useLocalStorage(LocalStorageKeys.CookiesConsent.toString(), false);

        return CookiesConsentValue ? null : (
            <Suspense fallback={renderLoading()}><CookiesConsent setCookiesConsentValue={setCookiesConsentValue} /></Suspense>
        );
    }

    function AccountInfo() {
        const [accountInfo, setAccountInfo] = useAtom(AccountInfoAtom);

        const updateVerification = (event) => {
            if (event.key === 'email_verified') {
                const accountInfoTemp = { ...accountInfo, verified: true };
                setAccountInfo(accountInfoTemp);
                window.removeEventListener('storage', updateVerification);
                localStorage.removeItem('email_verified');
            }
        };

        useEffect(() => {
            window.addEventListener('storage', updateVerification);

            return () => {
                window.removeEventListener('storage', updateVerification);
            };
        }, [accountInfo]);

        Log.debug("Render Account Info...");
        return null;
    }

    //ToDO: Since there is no UI logic here, Could this be turned into an atom?
    function Analytics() {
        const [analyticsId, setAnalyticsId] = useAtom(AnalyticsIdAtom);
        const [analyticsUserProxy, setAnalyticsUserProxy] = useAtom(AnalyticsUserProxyAtom);
        const googleAnalyticsID = process.env.GA_TRACKING_ID;
        const location = useLocation();
        const [accountInfo] = useAtom(AccountInfoAtom);
        const [loginStatus] = useAtom(LoginStatusAtom);

        function generateSessionId(): string {
            const randomId = uuidv4();
            const time = dayjs(Date.now()).unix(); // 1548381600
            const hash = Md5.hashStr(randomId.toString() + time.toString());
            return hash;
        }

        if (navigator.userAgent !== 'RenderBot') {
            // react-ga4 apparently have no way to defer,
            // so we are explicitly holding the initialization back.
            const timer = setTimeout(() => {
                Log.info("Loading GA")
                // Do something if the user agent is not RenderBot
                ReactGA.initialize(googleAnalyticsID);
            }, 2000);
        }

        function sendEvent(){
            if (navigator.userAgent !== 'RenderBot') {
                apiClient.sendEvent(accountInfo, new AnalyticsEvent(analyticsId.toString(),
                    'PAGE_VIEW',
                    location.pathname, window.location.href, analyticsUserProxy.toString())).then(res => {
                    //console.log(res.data);
                });
            }
        }

        useEffect(() => {
            Log.debug('ANALYTICS SESSION:', analyticsId);
            if (analyticsId === '') {
                Log.debug('Generating Analytics Id.');
                if(sessionStorage.getItem("sessionId") === null)
                    setAnalyticsId(generateSessionId());
            }
        }, [analyticsId]);
        useEffect(() => {
            if(localStorage.getItem("userProxy") === null)
                setAnalyticsUserProxy(generateSessionId());
        },[analyticsUserProxy]);

        useEffect(() => {
            Log.info('Location changed');
            console.log(accountInfo)

            if(loginStatus === "Unknown")
                return null;

            else if (analyticsId.toString() !== '' && loginStatus.toString() !== "Unknown") {
                    sendEvent();
                    return null;
            }

        },[location, analyticsId, loginStatus]);


        return null;
    }


    function useQuery(): URLSearchParams {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    /**
     *
     * @constructor
     */
    function MainOrPage(): JSX.Element {
        const query = useQuery();
        if (query.has('page_id')) {
            Log.info(`Found page_id=${query.get('page_id')}`);
            return <KodanshaArticle overrideUrl={`%3Fpage_id=${query.get('page_id')}`}/>
            //return <Redirect to={`%3Fpage_id=${query.get('page_id')}`} />;
        }
        if (query.has('p')) {
            Log.info(`Found p=${query.get('p')}`);
            return <KodanshaArticle overrideUrl={`%3Fp=${query.get('p')}`}/>
            // return <Redirect to={`%3Fp=${query.get('p')}`} />;
        }

        return <KodanshaHomeFallback />;
    }

    Log.info("Render Whole App...");
    useEffect(() => {
        GTagManagerTriggerLoad();
    }, [])

    const { location, replace } = useHistory();
    useEffect(() => {
        const pathname = ensureTrailingSlash(location.pathname);
        if (location.pathname === pathname) {
            return;
        }
        console.error(`missing trailing slash ${location.pathname}`);
        replace({ ...location, pathname });
    }, [location, replace]);

    return (<React.Fragment>
        <Helmet>
            <title>{'Kodansha – Inspire Impossible Stories'}</title>
        </Helmet>
        <AccountInfo/>
        <LoginOverlay/>
        <StripeWrapper>
            <Overlays/>
            {/* ONLY ADD OVERLAYS HERE. */}
            {/* DO NOT ADD NAVBAR HERE. THAT IS SUPPOSED TO BE IN THE VIEWCOMPONENTS */}
            <Suspense fallback={renderLoading()}>
                <Switch>
                    <Route
                        path='/search'
                        render={(): JSX.Element => <KodanshaSearch />} />
                    <Route
                        path='/checkout'
                        render={(): JSX.Element => <KodanshaHomeFallback showCheckout={true} />} />
                    <Route
                        exact={true}
                        path='/'
                        render={(): JSX.Element => <KodanshaHomeFallback />} />
        {/*            <Route
                        exact
                        path='/'
                        render={(): JSX.Element => <MainOrPage />} />*/}
                    <Route
                        exact
                        path='/product/:pid'
                        render={(): JSX.Element => <KodanshaProduct />} />
                    <Route
                        exact
                        path='/series/:sid'
                        render={(): JSX.Element => <KodanshaSeries />} />
                    <Route
                        exact
                        path="/reader/:seriesParam/volume-:volumeParam"
                        render={(): JSX.Element => <KodanshaReadableReader />} />
                    <Route
                        exact
                        path="/reader/:seriesParam/volume-:volumeParam/chapter-:chapterParam"
                        render={(): JSX.Element => <KodanshaReadableReader />} />
                    <Route
                        exact
                        path='/news'
                        render={(): JSX.Element => <KodanshaNews />} />
                    <Route
                        exact
                        path='/preview/*'
                        render={(): JSX.Element => <KodanshaArticlePreview />} />
                    <Route
                        exact
                        path='/news/articles/:aid'
                        render={(): JSX.Element => <KodanshaArticle />} />
                    <Route
                        exact
                        path='/:year/:month/:day/:aid'
                        render={(): JSX.Element => <KodanshaArticle />} />
                    <Route
                        exact
                        path='/dashboard*'
                        render={(): JSX.Element => <Dashboard />} />
                    <Route
                        exact
                        path='/reader/:pid'
                        render={(): JSX.Element => <KodanshaReader />} />
                    <Route
                        exact
                        path='/library'
                        render={(): JSX.Element => <KodanshaLibrary />} />
                    {/*<Route
                        exact
                        path='/librarians'
                        render={(): JSX.Element => <KodanshaLibrarians />} />*/}
                    <Route
                        exact
                        path={'/discover/:id'}
                        render={(): JSX.Element => <KodanshaDiscover />} />
                    <Route
                        exact
                        path='/account'
                        render={(): JSX.Element => <Account />} />
                    <Route
                        exact
                        path='/calendar'
                        render={(): JSX.Element => <KodanshaReleaseCalendar />} />
                    <Route
                        exact
                        path={'/orderSuccess/:paramId'}
                        render={(): JSX.Element => <KodanshaCheckoutSuccess id={'-1'} />} />
                    <Route exact
                           path='/browse'
                           render={(): JSX.Element => <KodanshaBrowse />} />
                    <Route exact
                           path={'/browse/:genreId'}
                           render={(): JSX.Element => <KodanshaBrowse />} />
          {/*          <Route
                        exact
                        path='/copyright'
                        render={(): JSX.Element => <Copyright />} />*/}
                    <Route
                        exact
                        path='/account-benefits'
                        render={(): JSX.Element => <AccountBenefits />} />
                    <Route
                        exact
                        path='/ax23'
                        render={(): JSX.Element => <Redirect to={'/2023/06/15/kodansha-usa-anime-expo-2023/'} />}
                    />
                    <Route
                        exact
                        path='/sdcc23-signup'
                        render={(): JSX.Element => <SDCC />}
                    />
                    <Route
                        exact
                        path='/aot-worldwide-after-party'
                        render={(): JSX.Element => <MAL />}
                    />
                    <Route
                        exact
                        path='/ax23-announcements'
                        render={(): JSX.Element => <Redirect
                            to={'/2023/07/01/july-2023-spring-2024-new-licensing-announcement/'} />}
                    />
                    <Route
                        exact
                        path='/sdcc23'
                        render={(): JSX.Element => <Redirect
                            to={'2023/06/08/vinland-saga-creator-makes-u-s-debut-at-comic-con-2023/'} />}
                    />
                    <Route
                        exact
                        path='/ax23-signup'
                        render={(): JSX.Element => <AnimeExpo />} />
                    <Route
                        exact
                        path='/about'
                        render={(): JSX.Element => <KodanshaAbout />} />
{/*                    <Route
                        exact
                        path='/support-center'
                        render={(): JSX.Element => <SupportCenter />} />*/}
                 {/*   <Route
                        exact
                        path='/terms-of-use'
                        render={(): JSX.Element => <TermsOfUse />} />
                    <Route
                        exact
                        path='/privacy-policy'
                        render={(): JSX.Element => <PrivacyPolicy />} />*/}
                    <Route
                        exact
                        path='/email-verified'
                        render={(): JSX.Element => <AccountBenefits verifiedLink={true} />} />
                    {/* NB: Since this is parameterized it will consume all routes. As such the 404 is rendered from */}
                    {/* within the KodanshaArticle component */}
                    <Route
                        path='/*'
                        render={(): JSX.Element => <KodanshaArticle />} />
                </Switch>
            </Suspense>
            {/* Cookies overlay should be after content for SEO reasons */}
            <CookiesAndConsent/>
        </StripeWrapper>
        <Analytics/>
        <GTagManager scriptId='gtm-script-container' Id={'GTM-N4GJK84'} />
    </React.Fragment>);
}

/**
 * Only load Stripe when needed as defined by UseStripeWrapperAtom
 */
function StripeWrapper(props: React.PropsWithChildren<{}>): JSX.Element {
    const useStripeWrapper = useAtomValue(UseStripeWrapperAtom);

    const [stripe, setStripe] = React.useState<Promise<Stripe> | null>(null);
    React.useEffect(() => {
        if (useStripeWrapper) {
            Log.info('Loading stripe...');
            setStripe(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
        } else {
            Log.info('No need for stripe yet...');
        }
    }, [useStripeWrapper]);

    Log.info('render StripeWrapper...');
    return <Elements stripe={stripe}>{props.children}</Elements>;
}

attachDebugMode(window);