import React from 'react';
import './IntroSDCC.scss';


const IntroSDCC = (props) => {

    return (
        <div id={'intro-ax'} className='intro-ax-container'>
            <div className={'text-info-wrapper'}>
                <h1 className='intro-header'>Kodansha at San Diego Comic-Con</h1>
                <h2 className='intro-second-header'>SDCC 2023</h2>
                <h3 className='intro-third-header'>Thanks for visiting us!</h3>

                <p className='intro-text'>
                    We appreciate you stopping by to see us at booth #5542 and hope you had a wonderful time at San Diego Comic-Con this year.
                </p>
                <p className='intro-text'>
                    We couldn’t have done it without you!
                </p>
                <p className='intro-text'>
                    Stay tuned to our homepage for more event information soon. We’re looking forward to seeing you at our next convention, and until then, happy reading!
                </p>
            </div>
        </div>
    );
};

export default IntroSDCC;