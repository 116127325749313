import { ProductSeries } from './DashboardProduct';
import { ProductChapter } from './Dashboard/ProductChapter';

class DashReadableComp {
    issueNumber: number;
    series: ProductSeries;
    subtitle: string;
    releaseDate: Date;
    readingLevelId: number;
    illustrationColorTypeId: number;
    signed: boolean;
    remarqued: boolean;
    pageCount: number;
    imprintId: number;
    coverTypeId: number;
    chapters: ProductChapter[] = [];
    eisbn: string;
    isbn: string;
    relativeName: string;
    defaultRelativeNameExample: string;

    hasChanged(orig: DashReadableComp): boolean {
      return this.pageCount !== orig.pageCount
          || orig.series?.id !== this.series?.id
          || this.illustrationColorTypeId !== orig.illustrationColorTypeId
          || this.coverTypeId !== orig.coverTypeId
          || this.signed !== orig.signed
          || this.remarqued !== orig.remarqued
          || this.imprintId !== orig.imprintId
          || this.readingLevelId !== orig.readingLevelId
          || this.issueNumber !== orig.issueNumber
          || this.eisbn !== orig.eisbn
          || this.isbn !== orig.isbn
          || this.relativeName !== orig.relativeName
          || this.subtitle !== orig.subtitle
          || this.releaseDate !== orig.releaseDate
          || JSON.stringify(this.chapters) !== JSON.stringify(orig.chapters);
    }

    mutate(newProps: any): DashReadableComp {
        const x = this as DashReadableComp;
        return Object.assign(new DashReadableComp(), x, newProps);
    }

    toApi(): DashReadableCompDTO {
        return Object.assign({}, this, {
            chapters: this.chapters.map(x => x.toApi()),
            relativeName: this.relativeName ===""?null:this.relativeName
        });
    }

    static createFromApi(dto: DashReadableCompDTO): DashReadableComp {
        if (!dto) return null;
        return Object.assign(new DashReadableComp(), dto, {
            chapters: dto.chapters.map(x => ProductChapter.createFromApi(x)) ?? [],
            relativeName: dto.relativeName??""
        });
    }
}

export interface DashReadableCompDTO {
    issueNumber: number;
    series: ProductSeries;
    subtitle: string;
    releaseDate: Date;
    readingLevelId: number;
    illustrationColorTypeId: number;
    signed: boolean;
    remarqued: boolean;
    pageCount: number;
    imprintId: number;
    coverTypeId: number;
    chapters: ProductChapter[];
    eisbn: string;
    isbn: string;
    relativeName: string;
    defaultRelativeNameExample: string;
}

export { DashReadableComp };