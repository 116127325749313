import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface HashScrollToTopRouterProps {}

interface HashScrollToTopState {}
export interface HashScrollToTopProps
    extends RouteComponentProps<HashScrollToTopRouterProps> {}

class HashScrollToTop extends Component<
    HashScrollToTopProps,
    HashScrollToTopState
> {
    componentDidUpdate(prevProps) {
        // console.log(
        //     prevProps.location.pathname + ' -> ' + this.props.location.pathname
        // );
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        return this.props.children;
    }
}

export default withRouter(HashScrollToTop);
