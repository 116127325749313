import React from 'react';
import './ExclusivesMAL.scss';
// import MALExplainer from './images/AfterParty_Explainer.png';
import { useAtom } from 'jotai';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import KodanshaArrow from '../KodanshaUIKit/KodanshaArrow/KodanshaArrow';
import { Link } from '../../Devon/Components/InkyLink';
import InkyMageImg from '../../Devon/Components/InkyMageImg/InkyMageImg';

const NewsMAL = () => {
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);

    return (
        <div className='exclusives-ax-container mal-news-container'>

            <div className={'gift-wrapper'}>
                <InkyMageImg className=''
                             alt={""}
                             src={"https://cdn.kodansha.us/statics/afterparty/AfterParty_Explainer.png"}
                             s={{width: 513}}
                             m={{width: 690}}
                             l={{width: 892}}
                             xl={{width: 615}}
                             width={1200}
                             height={960}
                />
            </div>

            <div className={'text-info-wrapper'}>
                <h1 className='intro-header'>NEW PERK ANNOUNCEMENT</h1>
                <h2 className='intro-second-header'>
                    Join the <i>Attack on Titan</i> After Party with a special discount
                </h2>
                <span className='intro-text-mal'>
                    In celebration of the hotly anticipated <i>Attack on Titan</i> finale, Kodansha announced its first fan participation wrap party inviting folks from around the world to celebrate in an online-only <Link target={'_blank'}
                          to={{ pathname: 'https://party.shingeki.tv/#timetable' }}><u><i>Attack on Titan</i> Worldwide After Party</u></Link> happening November 5-8 (JST).
                </span>

                <Link className='link-to-more-mal-news' target={"_blank"} to={{pathname:'/2023/10/03/join-the-attack-on-titan-after-party'}}>
                    <KodanshaArrow text={'Read more'} />
                </Link>
            </div>
        </div>
    );
};

export default NewsMAL;