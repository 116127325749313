import { atom, useAtom } from 'jotai';
import DashboardAPI from '../API/DashboardAPI';
import AnalyticsUniqueCustomers from '../Models/Dashboard/Reporting/AnalyticsUniqueCustomers';
import AnalyticsAverageOrderProductQuantity from '../Models/Dashboard/Reporting/AnalyticsAverageOrderProductQuantity';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { Md5 } from 'ts-md5';
import AverageOrderValue from '../Models/Dashboard/Reporting/AverageOrderValue';
import TopSellingProduct from '../Models/Dashboard/Reporting/TopSellingProduct';
import { Product } from '../Models/Product';
import TopSellingProducts from '../Models/Dashboard/Reporting/TopSellingProducts';

export const AnalyticsStartDateAtom = atom<Date>(new Date());
export const AnalyticsEndDateAtom = atom<Date>(new Date());
export const TopSellingProductListAtom = atom <TopSellingProduct[]>([]);
export const AnalyticsPageMetricsAtom = atom<unknown>({});
export const AnalyticsOrderDataAtom = atom<unknown>({});
export const AnalyticsUserDataAtom = atom<unknown>({});
export const AverageOrderValueAtom = atom<AverageOrderValue>(new AverageOrderValue());
export const AnalyticsLoggedInCustomersAtom = atom<AnalyticsUniqueCustomers>(new AnalyticsUniqueCustomers());
export const AnalyticsAvgOrderProductQuantityAtom = atom<AnalyticsAverageOrderProductQuantity>
                                                    (new AnalyticsAverageOrderProductQuantity());
const analyticsSessionStorage = { ...createJSONStorage(() => sessionStorage), delayInit: false };

export const AnalyticsIdAtom = atomWithStorage('sessionId',"",analyticsSessionStorage);
export const AnalyticsUserProxyAtom = atomWithStorage('userProxy',"");

export const FetchAnalyticsOrderDataAtom = atom(
    (get) => {
        return get(AnalyticsOrderDataAtom);
    },
    async (get, set) => {
        const mutate = get(AnalyticsOrderDataAtom);
        try {
            const start = get(AnalyticsStartDateAtom);
            const end = get(AnalyticsEndDateAtom);

                const res = await DashboardAPI.getAnalyticsOrderData(null, start, end);

                set(AnalyticsOrderDataAtom, res.data);
        } catch (e) {
            console.error('[FetchAnalyticsOrderDataAtom] Could not fetch data: ' + e.message, e);
            throw e;
        }
    },
);

export const FetchAnalyticsUserDataAtom = atom(
    (get) => {
        return get(AnalyticsUserDataAtom);
    },
    async (get, set) => {
        const mutate = get(AnalyticsUserDataAtom);
        try {
            const start = get(AnalyticsStartDateAtom);
            const end = get(AnalyticsEndDateAtom);

            const res = await DashboardAPI.getAnalyticsUserData(null, start, end);
            //console.error(res.data);
            set(AnalyticsUserDataAtom, res.data);
        } catch (e) {
            console.error('[FetchAnalyticsUserDataAtom] Could not fetch data: ' + e.message, e);
            throw e;
        }
    },
);

export const FetchTopSellingProductsAtom = atom(
    (get) => {
        return get(TopSellingProductListAtom);
    },
    async (get, set) => {
        const mutate = get(TopSellingProductListAtom);
        try {
            const start = get(AnalyticsStartDateAtom);
            const end = get(AnalyticsEndDateAtom);

            const res = await DashboardAPI.getTopSellingProducts(null,start, end, "500");
            set(TopSellingProductListAtom, res.response.products);
        } catch (e) {
            console.error('[FetchAnalyticsUserDataAtom] Could not fetch data: ' + e.message, e);
            throw e;
        }
    },
);

export const FetchAnalyticsLoggedInCustomersAtom = atom(
    (get) => {
        return get(AnalyticsLoggedInCustomersAtom);
    },
    async (get, set) => {
        const mutate = get(AnalyticsLoggedInCustomersAtom);
        try {
            const start = get(AnalyticsStartDateAtom);
            const end = get(AnalyticsEndDateAtom);
            const res = await DashboardAPI.getUniqueLoggedInCustomers(null, start, end);
            set(AnalyticsLoggedInCustomersAtom, res.response);
        } catch (e) {
            console.error('[FetchAnalyticsLoggedInCustomersAtom] Could not fetch data: ' + e.message, e);
            throw e;
        }
    },
);

export const FetchAnalyticsAvgOrderProductQuantity =  atom(
    (get) => {
        return get(AnalyticsAvgOrderProductQuantityAtom);
    },
    async (get, set) => {
        const mutate = get(AnalyticsAvgOrderProductQuantityAtom);
        try {
            const start = get(AnalyticsStartDateAtom);
            const end = get(AnalyticsEndDateAtom);

            const res = await DashboardAPI.getAverageOrderProductQuantity(null, start, end);
            set(AnalyticsAvgOrderProductQuantityAtom, res.response);
        } catch (e) {
            console.error('[FetchAnalyticsAvgOrderProductQuantity] Could not fetch data: ' + e.message, e);
            throw e;
        }
    },
);

export const FetchAverageOrderValueAtom =  atom(
    (get) => {
        return get(AverageOrderValueAtom);
    },
    async (get, set) => {
        const mutate = get(AverageOrderValueAtom);
        try {
            const start = get(AnalyticsStartDateAtom);
            const end = get(AnalyticsEndDateAtom);
            const res = await DashboardAPI.getAverageOrderValue(null, start, end);
            set(AverageOrderValueAtom, res.response);
        } catch (e) {
            console.error('[FetchAverageOrderValueAtom] Could not fetch data: ' + e.message, e);
            throw e;
        }
    },
);

export const FetchAnalyticsPageMetricsAtom = atom(
    (get) => {
        return get(AnalyticsPageMetricsAtom);
    },
    async (get, set) => {
        const mutate = get(AnalyticsPageMetricsAtom);
        try {
            const start = get(AnalyticsStartDateAtom);
            const end = get(AnalyticsEndDateAtom);

            const res = await DashboardAPI.getAnalyticsPageMetrics(null, start, end);
            set(AnalyticsPageMetricsAtom, res);

            //console.warn(res);
        } catch (e) {
            console.error('[FetchAnalyticsPageMetricsAtom] Could not fetch data: ' + e.message, e);
            throw e;
        }
    },
);

