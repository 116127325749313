import React, { ReactElement } from 'react';
import './KodanshaSubmit.scss';

interface KodanshaSubmitProps {
    text?: string;
    styles?: string;
    onSubmitClicked?: (...args) => void;
}

const KodanshaSubmit = ({ text = '', styles = '', onSubmitClicked }: KodanshaSubmitProps): ReactElement => {

    const onButtonClicked = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        onSubmitClicked();
    };

    return (
        <button onClick={onButtonClicked} className={`submit ${styles}`}>{text}</button>
    );
};

export default KodanshaSubmit;
