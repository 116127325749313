import React from 'react';
import KodanshaLogoGrey from '../KodanshaAssets/Kodansha_Logo_grey.svg';
import './ImagePlaceholder.scss';

interface KodanshaImagePlaceholderProps {
    alt: string;
}

export function ImagePlaceholder(props: KodanshaImagePlaceholderProps){

    return (
        <div className={"ImagePlaceholder"}>
            <img src={KodanshaLogoGrey} alt={props.alt} />
        </div>
    );

}
