import React from 'react';
import './DiscountMAL.scss';
import { Link } from '../../Devon/Components/InkyLink';
import { BadgeResponse } from './BadgeMAL';

interface Props {
    badge: BadgeResponse;
}

const DiscountMal = ({ badge }: Props) => {
    return (
        <div className='discount-mal-container'>
            <h3 className='header-mal'>Here’s your discount code!</h3>

            <span className='code-mal'>{badge?.gift.code}</span>

            <p className='explain-mal'>
                You’re all set! Please take note of your code and follow the directions below to purchase your
                discounted ticket:
            </p>

            <ol className='list-wrapper-mal'>
                <li>Visit the <u> <Link target={'_blank'}
                                        to={{ pathname: 'https://party.shingeki.tv/tickets' }}><i>Attack on
                    Titan</i> Worldwide After Party tickets page</Link></u></li>
                <li>Select your ticket type (with or without the avatar item)</li>
                <li>Pick your time slot and activate the section below that says “Got coupon?”</li>
                <li>In the field that then appears, enter your coupon code and press the Apply button</li>
                <li>You should receive a confirmation message. Once you do, press the Proceed button to create an
                    account and continue purchasing your ticket.
                </li>
            </ol>

            <p className='explain-mal'>
                Want to use your coupon code later? No worries! You can return to <Link target={"_blank"}
                to={'/aot-worldwide-after-party'}>this
                page</Link> while logged in to view your discount code at any time until the event ends on November 8.
            </p>

            <p className='explain-mal'>
                For additional information, including event details and frequently asked questions about ticketing, please
                visit the official site for the <Link target={'_blank'}
                                                      to={{ pathname: 'https://party.shingeki.tv/' }}><u><i>Attack on
                Titan</i> Worldwide After Party</u></Link> or check out their social media page on X (formerly known as
                Twitter) <u className='explain-mal-twitter'><Link target={'_blank'}
                                  to={{ pathname: 'https://twitter.com/shingeki_party' }}>@shingeki_party.</Link></u>
            </p>
        </div>
    );
};

export default DiscountMal;