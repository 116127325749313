import Axios, { CancelTokenSource } from 'axios';
import apiClient, { ApiModel, ApiStatusModel } from './InkyAPI';
import { v4 as uuid } from 'uuid';
import { Product } from '../Models/Product';
import { Cart, CartDTO } from '../Models/Cart';
import { Country, CountryDTO } from '../Models/Country';
import { State } from '../Models/State';
import {ShippingRate, ShippingRateDTO } from '../Models/ShippingRate';
import SalesTax from '../Models/SalesTax';
import {
    PaymentResult,
    Purchase,
    PurchaseDTO, PurchaseRequest, SoftConfirmResponse,
} from '../Models/Purchase';
import { PurchaseError } from '../Models/Errors/PurchaseError';
import { PurchaseAttempt } from '../Models/PurchaseAttempt';
import { AddressValidationResponse, PostalAddress } from '../Models/Google/AddressValidation';

export class ApiErrorException extends Error {
    type: string;
    constructor(message: ApiStatusModel) {
        super(message.message);
        this.type = message.type;
        this.name = "ApiErrorException";
    }
}

class OrderAPI {

    // @deprecated Use FetchCart in InkyAPI V2
    async fetchCart(cancelTokenSource: CancelTokenSource): Promise<Cart>{
        const cartId = OrderAPI.checkForCartId(null);

        let cart = null;
        const apiResponse: Promise<ApiModel<CartDTO>> = apiClient.getResponse('/cart/' + cartId, cancelTokenSource);
        await apiResponse.then(x => {
            console.debug("API: Successfully fetched Cart", x);
            cart = Cart.createFromApi(x.response);

            if(cartId !== cart.guestId) {
                //If the cart ID has been changed, update it.
                OrderAPI.setCartId(cart.guestId);
            }
            console.debug("Cart is", cart);
        }).catch(e => {
            console.error("[OrderAPI:fetchCart] API-error:", e);
            cart = null;
        });
        return cart;
    }

    async addProductToCart(cart: Cart, productId: number, variantId: number, isFree: boolean ,cancelTokenSource: CancelTokenSource): Promise<Cart> {
        const cartId = OrderAPI.checkForCartId(cart ? cart.id : null);

        const apiRequest = { "productId": productId, "VariantId": variantId, "Amount" : 1, "isFree": true};

        try {
            const apiResponse: ApiModel<CartDTO> = await apiClient.patchResponse<CartDTO>('/cart/' + cartId, apiRequest);
            console.debug("API: Successfully updated Cart", apiResponse);
            const newCart = Cart.createFromApi(apiResponse.response);
            console.debug("Updated cart is", cart);
            if(newCart?.guestId && newCart?.guestId !== cartId) {
                console.log(`Cart Guest ID changed from ${cartId} to ${newCart?.guestId}....`);
                OrderAPI.setCartId(newCart.guestId);
            }
            console.log("NEW CART++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
            return newCart;
        }
        catch(e) {
            console.error("[OrderAPI:addProductToCart] API-error:", e);
            return null;
        }
    }

    async removeProductFromCart(cartId: string, product: Product, variantId: number, cancelTokenSource: CancelTokenSource): Promise<Cart> {
        cartId = OrderAPI.checkForCartId(cartId);


        const apiRequest = { "productId": product.id, "VariantId": variantId, "Amount" : -1};

        let cart = null;
        const apiResponse: Promise<ApiModel<CartDTO>> = apiClient.patchResponse<CartDTO>('/cart/' + cartId, apiRequest, cancelTokenSource);
        await apiResponse.then(x => {
            console.debug("API: Successfully updated Cart", x);
            cart = Cart.createFromApi(x.response);
            if(cart?.guestId && cart?.guestId !== cartId) {
                console.log(`Cart Guest ID changed from ${cartId} to ${cart?.guestId}....`);
                OrderAPI.setCartId(cart.guestId);
            }
            console.log("NEW CART++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
            console.debug("Updated cart is", cart);
        }).catch(e => {
            console.error("[OrderAPI:addProductToCart] API-error:", e);
            cart = null;
        });
        return cart;

    }

    async removeAllOfProductFromCart(cartId: string, product: Product, variantId: number, cancelTokenSource: CancelTokenSource): Promise<Cart> {
        cartId = OrderAPI.checkForCartId(cartId);

        const apiRequest = { "productId": product.id, "VariantId": variantId, "Amount" : -1, "RemoveAll" : true};

        let cart = null;
        const apiResponse: Promise<ApiModel<CartDTO>> = apiClient.patchResponse<CartDTO>('/cart/' + cartId, apiRequest, cancelTokenSource);
        await apiResponse.then(x => {
            console.debug("API: Successfully updated Cart", x);
            cart = Cart.createFromApi(x.response);
            console.debug("Updated cart is", cart);
        }).catch(e => {
            console.error("[OrderAPI:addProductToCart] API-error:", e);
            cart = null;
        });
        return cart;

    }

    async clearCart(cartId: string, cancelTokenSource: CancelTokenSource): Promise<Cart> {
        cartId = OrderAPI.checkForCartId(cartId);
        let cart = null;
        const apiResponse: Promise<ApiModel<CartDTO>> = apiClient.patchResponse('/cart/' + cartId + '/empty', null, cancelTokenSource);
        await apiResponse.then(x => {
            console.debug("API: Successfully updated Cart", x);
            cart = Cart.createFromApi(x.response);
            console.debug("Updated cart is", cart);
        }).catch(e => {
            console.error("[OrderAPI:addProductToCart] API-error:", e);
            cart = null;
        });
        return cart;
        //return await apiClient.patchResponse('/cart/' + cartId + '/empty', null, cancelTokenSource);
    }

    // async getShippingRates(cartId: string, countryCode: string, postalCode: string, cancelTokenSource: CancelTokenSource): Promise<ShippingRate[]> {
    //     const params = [
    //         ["CountryCode", countryCode],
    //         ["ZipCode", postalCode]
    //     ];
    //
    //     let shippingRates = [];
    //     const apiResponse: Promise<ApiModel<ShippingRateDTO[]>> = apiClient.getResponse('/order/shipping/getRatesForCart/' + cartId, cancelTokenSource, params)
    //     await apiResponse.then(x => {
    //         console.debug("API: Successfully received shippingRates", x);
    //         shippingRates = x.response.map(x => ShippingRate.createFromApi(x));
    //         console.debug("Shipping Rates are", shippingRates);
    //     }).catch(e => {
    //         console.error("[OrderAPI:addProductToCart] API-error:", e);
    //         shippingRates = [];
    //     });
    //     return shippingRates;
    //
    //
    // }

    async getSalesTax(countryCode: string, administrativeArea: string, cancelTokenSource: CancelTokenSource): Promise<ApiModel<SalesTax>> {

        const params = [
            ["CountryCode", countryCode],
            ["AdministrativeArea", administrativeArea]
        ];

        return await apiClient.getResponse('/order/shipping/getSalesTax/', cancelTokenSource, params);
    }

    //endregion

    private static checkForCartId(cartId): string{
        // Of cartId is null, lets try to find it in localstorage.
        if(cartId == null){
            cartId = localStorage.getItem('browserId');
        }

        // If cartId is still null, lets generate a new one.
        if(cartId == null){
            cartId = uuid();
            localStorage.setItem('browserId', cartId);
        }
        return cartId;
    }

    /**
     *
     * @param newCartId
     * @private
     */
    private static setCartId(newCartId) {
        localStorage.setItem('browserId', newCartId);
    }

    //region API-calls
    async fetchCountries(cancelTokenSource: CancelTokenSource = null): Promise<Country[]> {
        console.log('Fetch countries for shipping');
        const apiResponse: ApiModel<CountryDTO[]> = await apiClient.getResponse('/order/shippingCountries', cancelTokenSource);

        const countries = apiResponse.response.map(x => Country.createFromApi(x));

        let sortedList = [];
        const usa = countries.find(x => x.countryCode === "US");
        usa.states = await this.fetchStates(cancelTokenSource);
        sortedList.push(usa);
        // Move Canada to top
        sortedList.push(countries.find(x => x.countryCode === "CA"));
        sortedList = sortedList.concat(countries.filter(x => x.countryCode !== "CA" && x.countryCode !== "US"));

        return sortedList;
    }

    //region API-calls
    async fetchStates(cancelTokenSource: CancelTokenSource = null): Promise<[State]> {
        const apiResponse: ApiModel<[State]> = await apiClient.getResponse('/order/shippingStates', cancelTokenSource);
        return apiResponse.response;
    }



    async fetchPurchaseRequest(request: PurchaseRequest, widths: string = null,  abortSignal?: AbortSignal): Promise<Purchase> {
        let params = "";
        if(widths) {
            params = `?widths=${widths}`;
        }
        const apiResponse: ApiModel<PurchaseDTO> = await apiClient.postV4('/purchase-request'+ params, request, abortSignal);
        console.log("Purchase request Response!!!:.");
        console.log(apiResponse);
        return Purchase.createFromApi(apiResponse.response);
    }

    async fetchAddressValidationResponse(Request: PostalAddress, previousResponseId: string, abortSignal?: AbortSignal): Promise<AddressValidationResponse> {
        let params = '';

        params = `?previousResponseId=${previousResponseId}`;
        try {
            const apiResponse = await apiClient.postV4<PostalAddress, ApiModel<AddressValidationResponse>>('/verify-address' + params, Request, abortSignal);
            console.log('Address Validation Response!!!:.');
            console.log(apiResponse);
            if (apiResponse.status.type === 'Success') {
                return apiResponse.response;
            }
        } catch (e) {
            console.log('exception thrown...');
            console.debug('Exception of ', e.response.status);
            console.error(e);
            throw e;
        }
    }

    async purchase(purchase: Purchase, cancelTokenSource: CancelTokenSource = null ): Promise<Purchase> {
        console.debug("Initiating Purchasing-request");
        const dto = purchase.toApi();
        console.log("order/purchase...: dto");
        console.log(dto);
        try {
            const apiResponse: ApiModel<PurchaseDTO> = await apiClient.postResponse('/order/purchase', dto, cancelTokenSource);
            console.log("Response!!!:.");
            console.log(apiResponse);
            if(apiResponse.status.type === 'Success') {
                return Purchase.createFromApi(apiResponse.response);
            }
        }
        catch (e) {
            if(e.response.status == 400){
                const responseStatus = e.response.data.status;
                console.debug(responseStatus);
                throw new ApiErrorException(responseStatus);
            }
            console.log("exception thrown...");
            console.debug("Exception of ", e.response.status);
            console.error(e);
            throw e;
        }
    }

    async purchaseGetPaymentIntent(purchase: Purchase, cancelTokenSource: CancelTokenSource = null ): Promise<string> {
        console.debug("Initiating Purchasing-request");
        const dto = purchase.toApi();
        console.log("order/purchase...: dto");
        console.log(dto);
        try {
            const apiResponse: ApiModel<string> = await apiClient.postResponse('/order/purchase', dto, cancelTokenSource);
            console.log("Response!!!:.");
            console.log(apiResponse);
            if(apiResponse.status.type === 'Success') {
                return apiResponse.response;
            }
        }
        catch (e) {
            if(e.response.status == 400){
                const responseStatus = e.response.data.status;
                console.debug(responseStatus);
                throw new ApiErrorException(responseStatus);
            }
            console.log("exception thrown...");
            console.debug("Exception of ", e.response.status);
            console.error(e);
            throw e;
        }
    }

    async initiatePurchase(purchaseAttempt: PurchaseAttempt, cancelTokenSource: CancelTokenSource = null): Promise<PurchaseAttempt> {

        try {
            const confirmedPurchase = await this.purchase(purchaseAttempt.purchase, cancelTokenSource);
            return Object.assign({}, purchaseAttempt, {
                purchase: confirmedPurchase
            });
        }
        catch (e) {
            throw new PurchaseError(e.message, purchaseAttempt.purchase);
        }
    }

    async confirmPayment(paymentResult: PaymentResult, cancelTokenSource: CancelTokenSource = null): Promise<PaymentResult> {

        const payload = {
            message: paymentResult.message,
            transactionId: paymentResult.transactionId,
            purchaseId: paymentResult.purchase.id
        };
        await apiClient.postResponse('/order/purchase-soft-confirmed', payload, cancelTokenSource);
        //await KlaviyoManager.PurchaseEvent(paymentResult.purchase);
        return paymentResult;
    }

    async confirmPaymentTransaction(paymentResult: PaymentResult, cancelTokenSource: CancelTokenSource = null): Promise<SoftConfirmResponse>{
        const payload = {
            transactionId: paymentResult.transactionId,
        };
        const response = await apiClient.postResponse<SoftConfirmResponse>('/order/payment-soft-confirmed', payload, cancelTokenSource);
        return response.response;
        //await KlaviyoManager.PurchaseEvent(paymentResult.purchase);
    }

    //purchase-failed
    async reportPaymentFailure(paymentFailure: PurchaseError, cancelTokenSource: CancelTokenSource = null): Promise<PurchaseError> {
        const apiPurchaseFailure = {
            purchaseId: paymentFailure.purchase.id,
            message: paymentFailure.message
        };
        await apiClient.postResponse('/order/purchase-failed', apiPurchaseFailure, cancelTokenSource);
        return paymentFailure;
    }
}

const cartController = new OrderAPI();

export default cartController;
