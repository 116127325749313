import { atom } from 'jotai';
import InkyAPI from '../API/InkyAPI';
import { BadgeResponse } from '../Kodansha/MAL/BadgeMAL';
import { atomWithReset } from 'jotai/utils'

export const BadgesCache = atomWithReset<BadgeResponse[] | undefined>(null);

export const fetchBadges = atom((get) => {
    return get(BadgesCache);
}, (get, set, { hardFetch }) => {
    // console.log('hardFetch', get(BadgesCache), hardFetch);
    if (get(BadgesCache) && !hardFetch) {
        return;
    }
    InkyAPI.getUserBadges().then((res) => {
        // console.log('result from atom', res);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        set(BadgesCache, res.data.response);
    }).catch((e) => {
        console.log('e', e);
    });
});

export const ClaimComicsCache = atom<boolean | undefined>(null);

export const fetchClaimComics = atom((get) => {
    return get(ClaimComicsCache);
}, (get, set, { userId }) => {
    if (get(ClaimComicsCache)) {
        console.log('props.redeemComic claim', get(ClaimComicsCache));
        return;
    }
    InkyAPI.checkClaimComics(userId).then((res) => {
        console.log('result from atom', res);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        set(ClaimComicsCache, res.data);
    }).catch((e) => {
        console.log('e', e);
    });
});
