import React from 'react';
import './IntroMAL.scss';

const AfterPartyMAL = (props) => {
    return (
        <div id={'intro-ax'} className='intro-ax-container'>
            <div className={'text-info-wrapper'}>
                <h1 className='intro-header'>Attack on Titan</h1>
                <h2 className='intro-second-header'>Worldwide After Party</h2>
                <h3 className='intro-third-header'>
                    All great things must come to an end…
                </h3>
                <span className='intro-ml-text'>
                    The <i>Attack on Titan</i> Worldwide After Party is over, but we hope you got a chance to enjoy the festivities!
                </span>
            </div>

            <div className={'gift-state-wrapper'}>
            </div>
        </div>
    );
};

export default AfterPartyMAL;
