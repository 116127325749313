import Ghost from './images/MG Ghost.jpg';
import Seven from './images/The Seven Deadly Sins- Four Knights of the Apocalypse.jpg';
import YuriisMyJob from './images/YuriisMyJob.jpg';
import Shangri from './images/shangri_la_frontier.jpg';
import InitialD from './images/Initial-D_Series_IMG_1200x960.jpg';
import SevenDeadlySins from './images/SevenDeadlySins_Series_IMG_1200x960_EDIT.jpg';
import Whisper from './images/WhisperMeALoveSong_Series_IMG_1200x960.jpg';

export const ProductsData = [
    {
        sampler: 'Action & Adventure Manga Sampler',
        image: Ghost,
        reader: 31581
    },
    {
        sampler: 'All-Ages Manga Sampler',
        image: Ghost,
        reader: 31582
    },
    {
        sampler: 'Horror & Suspense Manga Sampler',
        image: Ghost,
        reader: 31585
    },
    {
        sampler: 'Romance Manga Sampler',
        image: Ghost,
        reader: 31586
    },
    {
        sampler: 'BL, Yuri, and LGBTQ+ Manga Sampler',
        image: Ghost,
        reader: 31583
    },
    {
        sampler: 'Essentials Manga Sampler',
        image: Ghost,
        reader: 31584
    },
];
