import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import { VariantDTO } from './Variant';

export class ExpectedDelay{
    delayedUntilDate: Date;
    delayMessage: string;

    toApi(): ExpectedDelayDTO {
        const delayedUntilDateTxt = dayjs(this.delayedUntilDate).isValid() ? dayjs(this.delayedUntilDate).toISOString() : null;

        return Object.assign({}, this, {
            delayedUntilDate: delayedUntilDateTxt
        });
    }

    static createFromApi(dto: ExpectedDelayDTO): ExpectedDelay{
        if (!dto) {
            return null;
        }
        // make sure to null-check if some fields are optional.
        const delayedUntilDate = dto.delayedUntilDate ? dayjs(dto.delayedUntilDate).toDate() : null;

        return Object.assign(new ExpectedDelay(), dto, {
            delayedUntilDate: delayedUntilDate
        });
    }
}

export interface ExpectedDelayDTO {
    delayedUntilDate: string;
    delayMessage: string;
}