import { DashPricingInfo } from './DashProductVariant';

class ProductChapter {
    id: number;
    issueNumber: number;
    status: string;
    rule: string;
    masterRecord: string;
    pricingInfo: DashPricingInfo;
    oldIssueNumber: number;
    pageStart: number;
    pageEnd: number;
    relativeName: string;
    defaultRelativeNameExample: string;

    mutate(newProps: any): ProductChapter {
        return Object.assign(new ProductChapter(), this, newProps);
    }

    toApi(): ProductChapterDTO {
        return Object.assign({}, this);
    }

    static createFromApi(dto: ProductChapterDTO): ProductChapter {
        return Object.assign(new ProductChapter(), dto, {
            relativeName: dto.relativeName ?? null
        });
    }
}

interface ProductChapterDTO {
    id: number;
    issueNumber: number;
    status: string;
    rule: string;
    masterRecord: string;
    pricingInfo: DashPricingInfo;
    oldIssueNumber: number;
    pageStart: number;
    pageEnd: number;
    relativeName: string;
    defaultRelativeNameExample: string;
}

export { ProductChapter };