import { Thumbnail } from './Thumbnail';
import { IpTag } from './IpTag';
import { Creator } from './Creator';
import { Variant } from './Product/Variant';
import { Genre } from './Series';

/**
 * @deprecated The method should not be used
 */
class Comic {
    id: number;
    seriesId: number;
    seriesName: string;
    title = '';
    chapterNumber?: number;
    volumeNumber?: number;
    description: string;
    ageRating: string;
    subCategory?: string;
    pageCount: number;
    thumbnails: Thumbnail[] = [];
    currentPage = 0;
    copyright: string;
    reverseReadOrder? = false;
    creatorsJson?: Creator[];
    tags: string[] = [];
    genres: Genre[] = [];
    supportedReadingModes?: string[] = []
    isbn: string;
    eisbn: string;
    webSKU?: string;
    androidSKU?: string;
    appleSKU?: string;
    diamondCode?: string;
    lastRead?: string;
    BookType: string;
    price: string;
    inMyComics = false;
    progress: number;
    ipTags?: IpTag[];
    variants?: Variant[];
    publishStatus: string;
    readableUrl: string;
    readingProgress?: number;
    publishDate: Date;
    seriesReadableUrl:string;
}




export { Comic };


class ComicInSeries {
    BookType: "Volume" | "Chapter"
    id: number;
    seriesId: number;
    chapterNumber?: number;
    volumeNumber?: number;
    seriesName: string;
    title = '';
    description: string;
    ageRating: string;
    pageCount: number;
    thumbnails: Thumbnail[] = [];
    currentPage = 0;
    copyright: string;
    reverseReadOrder? = false;
    creatorsJson?: Creator[];
    tags: string[] = [];
    genres: Genre[] = [];
    supportedReadingModes?: string[] = []
    isbn: string;
    eisbn: string;
    webSKU?: string;
    androidSKU?: string;
    appleSKU?: string;
    diamondCode?: string;
    lastRead?: string;
    price: string;
    inMyComics = false;
    progress: number;
    ipTags?: IpTag[];
    variants?: Variant[];
    publishStatus: string;
    readableUrl: string;
}




export { ComicInSeries };
