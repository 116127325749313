import { Thumbnail } from '../Thumbnail';
import { AssetLinkGroupDTO } from '../AssetLinkGroup';

interface PriceInfo {
    price: number;
    type: string;
    rrp: number;
}

interface TaxInfo {
    taxStatusId: number;
    taxClassId: number;
}

class DashPricingInfo implements PriceInfo, TaxInfo{
    price: number;
    usingDefaultPrice: boolean;
    taxStatusId: number;
    taxClassId: number;
    type: string;
    rrp: number;
}

class DashInventoryData {
    stockCount: number;
    shelfNumber: string;
}

class PhysicalProductInfo {
    inventoryId: string;
    reserveQuantity: number;
    sku: string;
    description: string;
    canUseMediaMail: boolean;
    weight: number;
    length: number;
    width: number;
    height: number;
    orderLimit?: number;
    delayExpected: boolean;
    delayedUntilDate: Date;
    delayMessage: string;

    static getDensity(pInfo: PhysicalProductInfo): number {
        return pInfo.weight/(pInfo.height*pInfo.width* pInfo.length);
    }

    static getWarnings(pInfo: PhysicalProductInfo): string[] {
        const results = [];
        const paperDensity = 0.043; // Lb per Cubic Inch
        const densityRatio = PhysicalProductInfo.getDensity(pInfo)/paperDensity;
        if(densityRatio > 4 ) {
            results.push("Book is too heavy for it's size. One or more dimensions might be too small, or weight is too high");
        }

        if(densityRatio < 0.25 ) {
            results.push("Book is too light for it's size. One or more dimensions might be too large, or weight is too low");
        }

        return results;
    }
}

export interface DashProductVariantDTO {
    type: string;
    enabled: boolean;
    pricingInfo: DashPricingInfo;
    stockInfo: PhysicalProductInfo;
    inventoryData: DashInventoryData;
    isDefault: boolean;
    alternateId: number;
    defaultThumbnail: Thumbnail;
    thumbnails: Thumbnail[];
    releaseDate: string;
}

class DashProductVariant {
    type: string;
    enabled: boolean;
    pricingInfo: DashPricingInfo;
    stockInfo: PhysicalProductInfo;
    inventoryData: DashInventoryData;
    isDefault: boolean;
    alternateId: number;
    defaultThumbnail: Thumbnail;
    thumbnails: Thumbnail[];
    releaseDate: string;
    //trim:string;


    public static create(type: string): DashProductVariant {
        const newVariant = new DashProductVariant();
        newVariant.type=type;
        newVariant.pricingInfo = new DashPricingInfo();
        if(type==="Print") {
            newVariant.stockInfo = new PhysicalProductInfo();
        }

        return newVariant;
    }

    static createFromApi(dto: DashProductVariantDTO): DashProductVariant {
        return Object.assign(new DashProductVariant(), dto);
    }

    toApi(){
        return Object.assign({}, this);
    }

    /***
     * Helper for Object.assign
     */
    mutate(newProps: any): DashProductVariant {
        const x = this as DashProductVariant;
        return Object.assign(new DashProductVariant(), x, newProps);
    }
}
const PricingTypes = {
    print : "Print",
    free : "Free",
    paid: "Paid",
    notForSale: "NotForSale",
    subscriptionOnly: "SubscriptionOnly",
    paidOrSubscription: "PaidOrSubscription",
    freeToReadAndPurchaseble: "FreeToReadAndPurchaseble",
    freeToReadForRegisteredAndPurchaseble: "FreeToReadForRegisteredAndPurchaseble"
    
} as const

function IsPurchaseable (pi:PriceInfo) {
    switch (pi.type) {
        case PricingTypes.paid:
        case PricingTypes.paidOrSubscription:
        case PricingTypes.freeToReadAndPurchaseble:
        case PricingTypes.freeToReadForRegisteredAndPurchaseble:
            return true;
        default:
            return false;
    }
}

export { DashProductVariant, DashPricingInfo, PhysicalProductInfo,PricingTypes,IsPurchaseable};
export type { PriceInfo, TaxInfo };
