import React, { ReactElement } from 'react';
import './KodanshaArrow.scss';
// import SvgArrow from './SvgArrow';
import { ReactComponent as SvgArrow } from '../../Assets/icons/buttonArrow.svg';

// ignoreActive is a value for trigger onClick on every situation regardless the button is not active
interface KodanshaArrowProps {
    text: string;
    withArrow?: boolean;
    active?: boolean;
    bold?: boolean;
    invert?: boolean;
    ignoreActive?: boolean;
    styles?: string;
    onClick?: (...args) => void;
    title?:string;
}

const KodanshaArrow = ({
                           onClick,
                           active = true,
                           text,
                           withArrow = true,
                           styles = '',
                           ignoreActive = false,
                           bold,
                           invert = false, 
                           title,
                       }: KodanshaArrowProps): ReactElement => {
    const onButtonClicked = (...args): void => {
        if (active && onClick || ignoreActive) {
            onClick(...args);
        }
    };

    function getClassName() {
        let className = styles;
        if(!active) {
            className += " disabled";
        }
        if(invert) {
            className += " invert";
        }

        return className;
    }
    return (
        <div role={'button'}
             onClick={onButtonClicked}
             className={`arrow-container ${getClassName()}`}
             aria-label={text}
             title={title}
        >
            <span className={`arrow-btn ${withArrow ? 'with-arrow' : 'no-arrow' } text-bold ${bold && 'text-bold'}`}>{text}</span>
            {withArrow && <div className='icon-wrapper' >
                <SvgArrow fill={active ? 'white' : '#757575'} width={80} className='icon' />
            </div>}
        </div>
    );
};

export default KodanshaArrow;
