import { useAtom } from 'jotai';
import { AnalyticsIdAtom } from '../../store/AnalyticsStore';
import dayjs from 'dayjs';

const appVersionNumber = process.env.REACT_APP_VERSION

export default class AnalyticsEvent{
    userId: number;
    sessionId: string;
    userProxy: string;
    timeStamp: Date;
    eventName: string;
    pathName: string;
    extraData: string;
    versionNumber: string = appVersionNumber;

    constructor(sessionId: string, eventName: string, pathName: any, extraData: string, userProxy: string) {
        this.sessionId = sessionId.toString();
        this.timeStamp = dayjs().toDate();
        this.eventName = eventName;
        this.extraData = extraData;
        this.pathName = pathName;
        this.userProxy = userProxy;
    }
}