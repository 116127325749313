import React, { useRef } from 'react';
import './SeriesMAL.scss';
import SeriesPoster from '../KodanshaUIKit/SeriesPoster/SeriesPoster';
import KodanshaUnderlinedText from '../KodanshaUIKit/KodanshaUnderlinedText/KodanshaUnderlinedText';

import { SeriesDataMAL } from './SeriesDataMAL';
import { useAtom } from 'jotai';
import { LoginStatus, LoginStatusAtom } from '../../store/AccountStore';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import { useHistory } from 'react-router-dom';
import { Link } from '../../Devon/Components/InkyLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { FreeModeOptions } from 'swiper/types/modules/free-mode';
import { useWindowSize } from '../Hooks/useWindowSize';
import { Swiper as T } from 'swiper/types';
import 'swiper/css';
import 'swiper/css/virtual';

interface Props {
    data: typeof SeriesDataMAL[0];
}


export const SeriesItem = ({ data }: Props) => {
    const history = useHistory();
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);

    const onButtonCLicked = (e) => {
        if (e.currentTarget.textContent === 'Sign up to read') {
            setLoginOverlay(true);
            return;
        }
    };
//TODO: READER uses readable url
    return <div className={'series-item-wrapper'}>
        <Link target={'_blank'} to={'/series/' + data.readableUrl} className={'series-item-link'}>
            <SeriesPoster ratio={120} isGray={true} color={data.color} src={data.image} />
        </Link>

        <div className={'series-item-info-wrapper'}>

            <h5 className={'series-name'}>{data.name}</h5>
            {/*<span className={'sx-author-name'}>By {data.author}</span>*/}

            {/*<div className={'genres-wrapper'}>*/}
            {/*    {data.genres.map((genre, index, array) => {*/}
            {/*        return <>*/}
            {/*            <span>{genre}</span>*/}
            {/*            {index < array.length - 1 ? <span>/</span> : null}*/}
            {/*        </>;*/}
            {/*    })}*/}
            {/*</div>*/}

            {data.reader !== -1 ? <Link target={'_blank'} to={{
                pathname: '/reader/' + data.reader.toString(),
                state: { returnPath: history.location.pathname },
            }}
            >
                <KodanshaUnderlinedText text={'Read chapter 1'} />
            </Link> : null}

        </div>
    </div>;
};

const SeriesMAL = () => {
    const ref = useRef<any>();
    const { width } = useWindowSize();
    const SwiperRef = useRef<T>();

    return (
        <div className='series-ax-container'>
            <h1 className='header-one'>Attack on Titan Spinoffs</h1>
            <h2 className='header-two'>Go beyond the main series with spinoffs, parodies, and more</h2>
            {/*<h2 className='until'>Up to 3 volumes of the following series were free-to-read until 7/25</h2>*/}

            <div className='series-mal-grid-wrapper'>
                <Swiper
                    ref={ref}
                    spaceBetween={15}
                    slidesPerView={2}
                    slidesPerGroup={2}
                    touchEventsTarget={'container'}
                    freeMode={width < 1278}
                    modules={[FreeMode]}
                    longSwipesRatio={0.01}
                    onSwiper={(swiper) => {
                        SwiperRef.current = swiper;
                    }}
                    navigation={{
                        prevEl: '.prev',
                        nextEl: '.next',
                    }}
                    onResize={(swiper) => {
                        swiper.params.freeMode = {
                            ...swiper.params.freeMode as FreeModeOptions,
                            enabled: width < 1278,
                        };
                        // (swiper.params.freeMode as FreeModeOptions).enabled = width < 768;
                        // ref.current && setWheelContainerSize(ref.current.getBoundingClientRect().height);
                        // calculateWheelItemSize();
                        swiper.update();
                    }}
                    onEnded={() => {
                        // setPage(999);
                    }}
                    onSlideChange={() => {
                        // setPage(SwiperRef.current.realIndex);
                    }}
                    breakpoints={{
                        576: {
                            slidesPerView: 2.2,
                            slidesPerGroup: 2,
                        },
                        768: {
                            slidesPerView: 3.2,
                            slidesPerGroup: 3,
                        },
                        1278: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        },
                    }}
                >
                    {SeriesDataMAL.map((data, index) => {
                        return <SwiperSlide key={index}>
                            <SeriesItem data={data} />
                        </SwiperSlide>;
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default SeriesMAL;