// import K1 from './images/K1.jpg'
// import K2 from './images/K2.jpg'
// import K3 from './images/K3.jpg'
// import K4 from './images/K4.jpg'
// import K5 from './images/K5.jpg'
// import K6 from './images/K6.jpg'
// import K7 from './images/K7.jpg'

const path = 'https://cdn.kodansha.us/statics/afterparty/'

export const GoodiesData = [
    {
        name: 'Attack on Titan Manga Box Sets, Volume 1',
        author: 'Makoto Yukimura',
        image: path + 'K1.jpg',
        color: 'none',
        readableUrl: 'attack-on-titan-season-1-part-1-manga-box-set',
        reader: 28740,
        genres: ['DRAMA', 'HISTORICAL', 'ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
    {
        name: 'Attack on Titan Manga Box Sets, Volume 2',
        author: 'Moyashi Fujisawa',
        image: path + 'K2.jpg',
        color: 'none',
        readableUrl: 'attack-on-titan-season-1-part-2-manga-box-set',
        reader: 30916,
        genres: ['DRAMA', 'THRILLER'],
    },
    {
        name: 'Attack on Titan Manga Box Sets, Volume 3',
        author: 'Masaaki Nakayama',
        image: path + 'K3.jpg',
        color: 'none',
        readableUrl: 'attack-on-titan-season-2-manga-box-set',
        reader: 22278,
        genres: ['HORROR'],
    },
    {
        name: 'Attack on Titan Manga Box Sets, Volume 4',
        author: 'Riku Tsuchida',
        image: path + 'K4.jpg',
        color: 'none',
        readableUrl: 'attack-on-titan-season-3-part-1-manga-box-set',
        reader: 31623,
        genres: ['ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
    {
        name: 'Attack on Titan Manga Box Sets, Volume 5',
        author: 'Shu Okimoto and Tadashi Agi',
        image: path + 'K5.jpg',
        color: 'none',
        readableUrl: 'attack-on-titan-season-3-part-2-manga-box-set',
        reader: 30830,
        genres: ['DRAMA', 'FOOD'],
    },
    {
        name: 'Attack on Titan Manga Box Sets, Volume 6',
        author: 'Katsuhisa Minami',
        image: path + 'K6.jpg',
        color: 'none',
        readableUrl: 'attack-on-titan-the-final-season-part-1-manga-box-set',
        reader: 31022,
        genres: ['DRAMA', 'THRILLER'],
    },
    {
        name: 'Attack on Titan Manga Box Sets, Volume 7',
        author: 'Katsuhisa Minami',
        image: path + 'K7.jpg',
        color: 'none',
        readableUrl: 'attack-on-titan-the-final-season-part-2-manga-box-set',
        reader: 31022,
        genres: ['DRAMA', 'THRILLER'],
    },
];
