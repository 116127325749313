import { useCallback, useEffect, useReducer, useRef, useState } from 'react';

interface State<T> {
    data?: T;
    error?: any;
    loading?: boolean;
    fetch?: () => void;
}


function useLazyFetch<T = unknown>(promiseFunction?: Promise<T> | (() => Promise<T>), lazy = true): State<T> {
    const cancelRequest = useRef<boolean>(false);
    const [error, setError] = useState<Error | null>();
    const [loading, setIsLoading] = useState(false);
    const [data, setData] = useState<T>(null);

    const fetch = useCallback((): void => {
        if (!promiseFunction) return;

        cancelRequest.current = false;
        setIsLoading(true);
        let promiseOrFunction;

        if (promiseFunction instanceof Promise) {
            promiseOrFunction = promiseFunction;
        } else {
            promiseOrFunction = promiseFunction();
        }

        promiseOrFunction.then((res) => {
            setIsLoading(false);
            setData(res);
            return res as T;
        }).catch((error) => {
            setIsLoading(false);
            setError(error);
            // return error.response ?? error.response.data ?? error;
            return error;
        });

        if (cancelRequest.current) return;
    }, [promiseFunction]);

    useEffect(() => {
        if (!lazy && data === null) {
            fetch();
        }

        return (): void => {
            cancelRequest.current = true;
        };
    }, []);

    if (lazy) {
        return { data, loading, error, fetch };
    }

    return { data, loading, error };
}

export default useLazyFetch;
