import React, { useEffect, useState } from 'react';
import './SDCC.scss';
import './AX.scss';
import { useAtom } from 'jotai';
import { fetchBadges, fetchClaimComics } from '../store/BadgesStore';
import { AccountInfoAtom } from '../store/AccountStore';
import apiClient from '../API/InkyAPI';
import { Helmet } from 'react-helmet';
import { KodanshaNavbar } from './KodanshaNavbar/KodanshaNavbar';
import SeriesPoster from './KodanshaUIKit/SeriesPoster/SeriesPoster';
import SDCCHeader from './SDCC/images/SDCC23_Page_IMG_2880x1040_rev.png';
import AxBenefits from './AX/AXBenefits';
import EndAX from './AX/EndAX';
import { KodanshaFooter } from './KodanshaFooter/KodanshaFooter';
import IntroSDCC from './SDCC/IntroSDCC';
import ExclusivesSDCC from './SDCC/ExclusivesSDCC';
import SixSDCC from './SDCC/SixSDCC';
import SeriesSDCC from './SDCC/SeriesSDCC';
import SeriesSDCC2 from './SDCC/SeriesSDCC2';
import BoothSDCC from './SDCC/BoothSDCC';

const SDCC = (props) => {
    const [data, fetch] = useAtom(fetchBadges);
    const [userData] = useAtom(AccountInfoAtom);
    const [redeemComics, setRedeemComics] = useState(false);

    useEffect(() => {
        if (userData.email && !data) {
            console.log('badge fetch', userData, data);
            fetch({ hardFetch: false });
        }

        if (userData.email && data) {
            console.log('here1', data);
            if (data?.some(b => b.badge.id === 2)) {
                setRedeemComics(true);
            }
        }
    }, [data, userData.email]);

    // console.log('badge data', data);
    // console.log('props.redeemComics 1', redeemComics);

    return (
        <div className='anime-expo-container'>
            <Helmet>
                <title>{'Kodansha at San Diego Comic-Con | Kodansha – Inspire Impossible Stories'}</title>
            </Helmet>
            <KodanshaNavbar />
            <div className={'AXHero'}>
                <SeriesPoster color={'transparent'} hover={false} ratio={33} src={SDCCHeader} isGray={false} />
            </div>
            <div className='anime-expo-max-wrapper'>
                <IntroSDCC redeemComics={redeemComics} />
                <ExclusivesSDCC />
                <SixSDCC redeemComics={redeemComics} />
            </div>


            <div className='anime-expo-color'>
                <div className='anime-expo-max-wrapper'>
                    <SeriesSDCC />
                </div>
            </div>

            <div className='anime-expo-max-wrapper'>
                <BoothSDCC />
                <AxBenefits />
                <EndAX />
            </div>


            <KodanshaFooter />
        </div>
    );
};

export default SDCC;