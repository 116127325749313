import React, { useEffect, useMemo } from 'react';
import './KodanshaFooter.scss';
import { ReactComponent as Logo } from '../Assets/icons/symbol-logo.svg';
import { ReactComponent as Youtube } from '../Assets/icons/youtube.svg';
import { ReactComponent as Twitter } from '../Assets/icons/x.svg';
import { ReactComponent as Instagram } from '../Assets/icons/instagram.svg';
import { ReactComponent as Tiktok } from '../Assets/icons/tiktok.svg';
import { ReactComponent as Facebook } from '../Assets/icons/facebook.svg';
import { ReactComponent as Tumblr } from '../Assets/icons/tumblr.svg';
import { ReactComponent as InkyEars } from '../KodanshaAssets/InkyEars.svg';
import { useLocation } from 'react-router-dom';
import { Link } from '../../Devon/Components/InkyLink';
import { useAtomValue } from 'jotai/index';
import { Platform, PlatformAtom } from '../../store/GenericAtoms';

const RightColumn = (): JSX.Element => {
    return <div className={'right-column-contact-copyright-wrapper'}>
        <div className={'right-column-each-column right-column-each-column-first'}>
            <span className={'right-column-each-column-title'}>
                COMPANY
            </span>
            <div className={'right-column-each-column-divider'} />

            <div className={'right-column-each-column-items-wrapper'}>
                <Link to={'/About'}>
                    <span className={'right-column-each-column-items-text'}>
                        ABOUT <br /> KODANSHA
                    </span>
                </Link>
                <Link target={'_blank'} to={{ pathname: 'https://www.kodansha.com/' }}>
                    <span className={'right-column-each-column-items-text'}>
                        KODANSHA <br /> CORPORATE SITE
                    </span>
                </Link>
            </div>
        </div>

        <div className={'right-column-each-column right-column-each-column-second'}>
            <span className={'right-column-each-column-title'}>
                RESOURCES
            </span>
            <div className={'right-column-each-column-divider'} />

            <div className={'right-column-each-column-items-wrapper'}>
                <Link to={{ pathname: '/account-benefits' }}>
                    <span className={'right-column-each-column-items-text'}>
                        KODANSHA  <br /> READER BENEFITS
                    </span>
                </Link>

                <Link to={{ pathname: '/support-center' }}>
                    <span className={'right-column-each-column-items-text'}>
                        SUPPORT CENTER
                    </span>
                </Link>

                <Link to={{ pathname: '/librarians' }}>
                    <span className={'right-column-each-column-items-text'}>
                        LIBRARIANS
                    </span>
                </Link>

                <Link to={{ pathname: '/terms-of-use' }}>
                    <span className={'right-column-each-column-items-text'}>
                        TERMS OF USE
                    </span>
                </Link>

                <Link to={{ pathname: '/privacy-policy' }}>
                    <span className={'right-column-each-column-items-text'}>
                        PRIVACY POLICY
                    </span>
                </Link>

                <Link to={{ pathname: '/copyright' }}>
                    <span className={'right-column-each-column-items-text'}>
                        COPYRIGHTS
                    </span>
                </Link>
            </div>
        </div>

        <div className={'right-column-each-column-icons-container'}>
            <div className={'right-column-each-column-icons'}>
                <span aria-label={'Manga'} className={'right-column-each-column-title'}>
                    MANGA
                </span>
                <div className={'right-column-each-column-divider'} />

                <div className={'right-column-each-column-icons-wrapper'}>
                    <Link aria-label={'link to X'} target={'_blank'}
                          to={{ pathname: 'https://twitter.com/KodanshaManga' }}>
                        <Twitter className={'right-column-instagram-icon-twitter'} />
                    </Link>

                    <Link aria-label={'link to Instagram'} target={'_blank'}
                          to={{ pathname: 'https://www.instagram.com/kodanshamanga/' }}>
                        <Instagram className={'right-column-icon-instagram'} />
                    </Link>

                    <Link aria-label={'link to Facebook'} target={'_blank'}
                          to={{ pathname: 'https://www.facebook.com/KodanshaManga/' }}>
                        <Facebook className={'right-column-icon-image-facebook'} />
                    </Link>

                    <Link aria-label={'link to Tumblr'} target={'_blank'}
                          to={{ pathname: 'https://www.tumblr.com/kodanshamanga' }}>
                        <Tumblr className={'right-column-icon-image-tumblr'} />
                    </Link>

                    <Link aria-label={'link to Tiktok'} target={'_blank'}
                          to={{ pathname: 'https://www.tiktok.com/@kodanshaus' }}>
                        <Tiktok className={'right-column-icon-instagram'} />
                    </Link>

                    <Link aria-label={'link to Youtube'} target={'_blank'}
                          to={{ pathname: 'https://www.youtube.com/kodanshaus' }}>
                        <Youtube className={'right-column-icon-youtube'} />
                    </Link>
                </div>
            </div>

            <div className={'right-column-each-column-icons-second'}>
                    <span className={'right-column-each-column-title'}>
                        BOOKS
                    </span>
                <div className={'right-column-each-column-divider'} />

                <div className={'right-column-each-column-icons-wrapper-second'}>
                    <Link aria-label={'Kodansha Books X'} target={'_blank'}
                          to={{ pathname: 'https://twitter.com/KodanshaBooks' }}>
                        <Twitter className={'right-column-instagram-icon-twitter'} />
                    </Link>

                    <Link aria-label={'Kodansha Books Instagram'} target={'_blank'}
                          to={{ pathname: 'https://www.instagram.com/kodanshabooks/' }}>
                        <Instagram className={'right-column-icon-instagram'} />
                    </Link>

                    <Link aria-label={'Kodansha Books Facebook'} target={'_blank'}
                          to={{ pathname: 'https://www.facebook.com/KodanshaBooks/' }}>
                        <Facebook className={'right-column-icon-image-facebook'} />
                    </Link>
                </div>
            </div>
        </div>
    </div>;
};

export function KodanshaFooter(): JSX.Element {
    const PlatformValue = useAtomValue(PlatformAtom);

    const location = useLocation();

    const isBrowseOrNews = useMemo(() => {
        return location.pathname === '/browse' || location.pathname === '/news';
    }, [location]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    if (PlatformValue === Platform.Steam) {
        return null;
    }

    return (<footer aria-label={'kodansha footer'} className={`KodanshaFooter ${isBrowseOrNews && 'paddingForMobile'}`}
                    data-nosnippet={true}>
        <div className='go-to-top-link'>
            <span aria-label={'go to top button'} onClick={() => scrollToTop()} className='go-to-top-link-text'>
                GO TO TOP
            </span>
        </div>

        <div className={'container'}>
            <div className='logo-wrapper'><Logo fill={'white'} width={60} /></div>
            <div className={'wrapper'}>
                <div className='about-wrapper'>
                    <div className={'footer-title'} aria-label={'A history of re-imagining the world'}>A history of
                        re-imagining the world
                    </div>
                    <p aria-label={'About kodansha'} className='about-text'>For over a century, Kodansha has
                        relentlessly pursued quality and
                        creativity, which allows us to continuously
                        reimagine what could be. We see the world without restrictions. A place of diverse passions,
                        profound
                        perspectives and limitless potential. Filled with curious minds and unexpected voices. And
                        as we
                        continue
                        to shine a light on them, there is no telling how much further we can go.</p>
                </div>

                <RightColumn />

                {/*<div className={'contact-copyright-wrapper'}>*/}
                {/*    <ul className={'column-wrapper'}>*/}
                {/*        /!* TODO: Links should be correct instead of sample *!/*/}
                {/*        <li><Link target={'_blank'} to={{pathname: 'https://www.facebook.com/KodanshaManga/'}}>Facebook (Manga)</Link></li>*/}
                {/*        <li><Link target={'_blank'} to={{pathname: 'https://www.instagram.com/kodanshamanga/'}}>Instagram (Manga)</Link></li>*/}
                {/*        <li><Link target={'_blank'} to={{pathname: 'https://twitter.com/KodanshaManga'}}>Twitter (Manga)</Link></li>*/}
                {/*        <li><Link target={'_blank'} to={{pathname: 'https://www.facebook.com/KodanshaBooks/'}}>Facebook (Books)</Link></li>*/}
                {/*        <li><Link target={'_blank'} to={{pathname: 'https://www.instagram.com/kodanshabooks/'}}>Instagram (Books)</Link></li>*/}
                {/*        <li><Link target={'_blank'} to={{pathname: 'https://twitter.com/KodanshaBooks'}}>Twitter (Books)</Link></li>*/}
                {/*    </ul>*/}

                {/*    <ul className={'column-wrapper second-column-wrapper'}>*/}
                {/*        <li><Link to={'/copyright'}>Copyright</Link></li>*/}
                {/*        <li><Link to={'/terms-of-use'}>Terms of Use</Link></li>*/}
                {/*        <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}


            </div>

            <Link to={'/privacy-policy'} aria-label={'WE DO NOT SELL PERSONAL INFORMATION link to privacy policy'}
                  target={'_blank'} className={'do-not-sell-my-data'}>WE DO
                NOT SELL PERSONAL INFORMATION</Link>
            <span className={'terms-of-use'}>COPYRIGHT © 2024 KODANSHA USA PUBLISHING. ALL RIGHTS RESERVED.</span>
            <span className={'footer-beta'}>BETA</span>
            <a href={'https://inky-pen.com/'} target='_blank' rel='noopener noreferrer'
               className={'terms-of-use powered-by'}>POWERED BY <InkyEars /><span>INKYPEN</span></a>

        </div>
    </footer>);
}
