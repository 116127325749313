import React from 'react';
import './AXBenefits.scss';
import { ReactComponent as FirstIcon } from '../KodanshaButton/Assets/Deals_Kodansha.svg';
import { ReactComponent as SecondIcon } from '../KodanshaButton/Assets/Document_Kodansha.svg';
import { ReactComponent as ThirdIcon } from '../KodanshaButton/Assets/Novel_Kodansha.svg';
import { ReactComponent as FourthIcon } from '../KodanshaButton/Assets/Sales_Kodansha.svg';

const iconList = {
    0: FirstIcon,
    1: SecondIcon,
    2: ThirdIcon,
    3: FourthIcon,
};

interface BenefitGridItemProps {
    list: string[];
    title: string;
    icon: any;
}

const GridItem = ({ list, title, icon }: BenefitGridItemProps) => {
    const Icon = iconList[icon];
    return <div className='benefit-grid-item-wrapper'>
        <div className='benefit-grid-header-wrapper'>
            <Icon className='ax-benefit-icon'/>
            <span className={'benefit-item-header'}>
                {title}
            </span>
        </div>

        <ul className='benefit-grid-list'>
            {list.map(item => {
                return <li>{item}</li>;
            })}
        </ul>
    </div>;
};

const AxBenefits = () => {
    return (
        <div className='ax-benefit-container'>
            <h1 className='first-header'>Kodansha Reader Benefits</h1>
            <h2 className='second-header'>With your free account, you can:</h2>

            <div className='benefits-grid'>
                <GridItem title={'Enjoy more manga previews and take advantage of special discounts.'}
                          list={['Get extra free chapters for over 500 series.', 'Preview multiple volumes from our biweekly Spotlight Series.', 'Most Spotlight Series will also have special pricing for purchasable volumes.']}
                          icon={0} />
                <GridItem title={'Collect and read digital manga in your library'}
                          list={['View all of your chapters, volumes, and purchased e-books in one place', 'Your library will feature a constant supply of new and free content.']}
                          icon={1} />
                <GridItem title={'Keep track of your reading progress'}
                          list={['Continue reading from where you left off using any net-enabled device with a web browser.', 'Your reading progress is always tied to your account.']}
                          icon={2} />
                <GridItem title={'Buy manga with greater ease and choice.'}
                          list={['Purchase and read digital manga* the way you want. 1000s of volumes from 100s Kodansha series are available directly from the Kodansha Reader Portal and many other platforms.', 'Save your billing address in your account and choose from multiple payment options for quicker and more convenient checkout.', 'Retain and review your Order History in your account settings.']}
                          icon={3} />
            </div>
        </div>
    );
};

export default AxBenefits;