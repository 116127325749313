import React from 'react';
import loadingImage from '../Images/loading.gif';

interface CachedImageProps {
    src: string;
    style?: React.CSSProperties;
    alt: string;
    useBackgroundImage?: boolean;
    backgroundImagePrefix?: string;
    className?: string;
}

interface CachedImageState {
    isLoaded: boolean;
    url?: string;
    error: boolean;
}

export default function CachedImage(props: CachedImageProps) {
    if(process.env.REACT_APP_USE_IMAGE_CACHING) {
        return (<CachedImage2
            src={props.src}
            style={props.style}
            alt={props.alt}
            useBackgroundImage={props.useBackgroundImage}
            className={props.className}
        />);
    }

    if (props.useBackgroundImage) {
        const prefix = props.backgroundImagePrefix
            ? `${props.backgroundImagePrefix}, `
            : '';
        const backgroundStyle = `${prefix} url(${props.src})`;
        return (
            <div
                className={`${props.className} ${'loaded'}`}
                style={{
                    ...props.style,
                    ...{ backgroundImage: backgroundStyle },
                }}
            />
        );
    }

    //Pretend that we have a loaded image.
    return (
        <img
            className={`${props.className} ${'loaded'}`}
            style={props.style}
            src={props.src}
            alt={`${props.alt}`}
        />
    );

    // return (<img
    //     className={props.className}
    //     src={props.src} style={props.style}
    // />);
}

export class CachedImage2 extends React.Component<
    CachedImageProps,
    CachedImageState
> {
    private image: HTMLImageElement;
    private div: HTMLDivElement;
    private _isMounted: boolean;
    private controller: AbortController;
    get isMounted(): boolean {
        return this._isMounted;
    }

    set isMounted(val: boolean) {
        this._isMounted = val;
    }

    constructor(props: CachedImageProps) {
        super(props);
        this.state = {
            isLoaded: false,
            error: false,
            url: '',
        };
    }

    async getImageBlob(url: string): Promise<Response> {
        const cacheName = 'thumbnail_cache';
        const urlObj = new URL(url);
        const cacheKey =
            urlObj.protocol + '//' + urlObj.hostname + urlObj.pathname;

        // Check for cached image first.
        if ('caches' in window) {
            // The Cache API is supported

            const cache = await caches.open(cacheName);
            const firstMatch = await cache.match(cacheKey);
            //console.log(firstMatch);
            if (firstMatch) {
                const p = new Promise<Response>((resolve, reject) => {
                    resolve(firstMatch);
                });
                //console.log(`${url} found in ${cacheKey}`);
                return p;
            } else {
                //console.log(`Cache lookup failed. Get from network.`);
                let r = undefined;
                try {
                    r = await fetch(url, { signal: this.controller.signal });
                } catch (e) {
                    console.log(e);
                    return;
                }
                const p = new Promise<Response>((resolve, reject) => {
                    resolve(r.clone());
                });
                cache.put(cacheKey, r).finally(() => {
                    //TODO: Might not be true, handle error.
                    //console.log(`${url} cached as ${cacheKey}`);
                });
                return p;
            }
        } else {
            //Get from network.
            console.warn('The cache api is not supported.');
            /*let r = await fetch(url);
            let p = new Promise<Response>((resolve, reject) => {
                resolve(r);
            });*/
        }
    }

    loadImage = () => {
        if (!this.props.src) {
            this.setState({ error: true });
            return;
        }
        this.getImageBlob(this.props.src)
            .then(r => r.blob())
            .then(r => {
                const urlCreator = window.URL;
                const url = urlCreator.createObjectURL(r);
                if (this.isMounted) this.setState({ url: url, isLoaded: true });
            })
            .catch(reason => {
                console.error(`Failed loading image with fetch: ${reason}`);
                console.error('Url was ' + this.props.src);
                if (this.isMounted) this.setState({ error: true });
            });
    };

    componentDidMount = () => {
        this.controller = new AbortController();
        this.isMounted = true;
        this.loadImage();
    };

    componentDidUpdate = (prevProps: CachedImageProps) => {
        if (prevProps.src !== this.props.src) {
            this.setState({
                isLoaded: false,
                url: '',
                error: false,
            });
            //update the image.
            this.loadImage();
        }
    };

    componentWillUnmount = () => {
        this.isMounted = false;
        this.controller.abort();
    };

    render(): JSX.Element {
        let img = undefined;
        let actualUrl = loadingImage;
        let additionalClass = 'not-loaded';
        if (this.state.isLoaded) {
            actualUrl = this.state.url;
            additionalClass = 'loaded';
        }

        if (this.props.useBackgroundImage) {
            const prefix = this.props.backgroundImagePrefix
                ? `${this.props.backgroundImagePrefix}, `
                : '';
            const backgroundStyle = `${prefix} url(${actualUrl})`;
            img = (
                <div
                    ref={div => (this.div = div)}
                    className={`${this.props.className} ${additionalClass}`}
                    style={{
                        ...this.props.style,
                        ...{ backgroundImage: backgroundStyle },
                    }}
                />
            );
        } else {
            if (this.state.error) img = null;
            else
                img = (
                    <img
                        ref={img => (this.image = img)}
                        className={`${this.props.className} ${additionalClass}`}
                        style={this.props.style}
                        src={actualUrl}
                        alt={`${this.props.alt}`}
                    />
                );
        }
        return img;
    }
}
