
class DashCreator {
  id: number;
  firstName: string;
  lastName: string;

  getName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static createFrom(orig: DashCreator): DashCreator {
    const dc = new DashCreator();
    dc.id = orig.id;
    dc.firstName = orig.firstName;
    dc.lastName = orig.lastName;
    return dc;
  }



  static createFromName(name: string): DashCreator {
    const creator = new DashCreator();
    const lastNameStartPos = name.lastIndexOf(" ");
    if (lastNameStartPos > 0) {
      creator.firstName = name.substr(0,lastNameStartPos).trim();
      creator.lastName = name.substr(lastNameStartPos+1).trim();
    }
    else {
      creator.firstName ="";
      creator.lastName=name.trim();
    }

    return creator;
  }
}

//class CreatorType

export {DashCreator};
