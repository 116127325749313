import axios, { AxiosError } from 'axios';
import { ApiModel } from './InkyAPI';
import {
    CouponType,
    DashCoupon,
    DashCouponDetails,
    DashCouponDetailsDTO,
    DashCouponDTO,
} from '../Models/Dashboard/DashCoupon';
import { DashCouponProduct } from '../store/DashCouponStore';

export enum ApiErrorStatus {
    // Returned whenever a network call reports an unauthorized request.
    // Reaction to this error should be to use the refresh-token to
    // get a new access-token, and retry whichever request that failed.
    InvalidAccessToken = "InvalidAccessToken"
}

export class DashApiError extends Error {

    status: ApiErrorStatus;

    constructor(msg: string, status: ApiErrorStatus) {
        super(msg);

        this.status = status;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, DashApiError.prototype);
    }

}

interface TokenResponse {
    access_token: string;
    refresh_token: string;
    expires_in: string;
    token_type: string;
}


export class InkyDashApiV2{
    private static instance: InkyDashApiV2;
    private baseUrl = process.env.REACT_APP_BASE_URL;

    public static shared(): InkyDashApiV2{
        if(!this.instance){
            this.instance = new InkyDashApiV2();
        }
        return this.instance;
    }


    public RefreshToken: string;
    public AccessToken: string;


    async FetchOrderCount(filter?: string): Promise<number> {
       /* let filters = "";
        if (filter){
            filters = filter;
        }
        const result = await this.get<number>(`/dashboard/orders/count${filters}`);*/
        return 0;
    }

    async fetchCoupons(query?: string): Promise<DashCoupon[]> {
        const params: string[][] = [];
        if( (query?.length ?? 0) > 0) {
            params.push(["searchQuery", query]);
        }
        const result = await this.get<ApiModel<DashCouponDTO[]>>("/dashboard/coupon", params);
        if(result.status.type ===  "Success") {
            return result.response.map(x=> DashCoupon.createFromApi(x));
        }

        return [];
    }

    async fetchCouponDetails(couponId: number): Promise<DashCouponDetails> {
        const result = await this.get<ApiModel<DashCouponDetailsDTO>>(`/dashboard/coupon/${couponId}`);
        if(result.status.type ===  "Success") {
            return DashCouponDetails.createFromApi(result.response, true, true);
        }

        return null;
    }

    async alterCoupon(coupon: DashCouponDetails) {
        const result = await this.post<ApiModel<DashCouponDetailsDTO>>(`/dashboard/coupon/alter`, coupon.toApi());
        if(result.status.type ===  "Success") {
            return DashCouponDetails.createFromApi(result.response, true, true);
        }

        return null;
    }

    async removeCoupon(id: number) {
        const result = await this.post<ApiModel<string>>(`/dashboard/coupon/remove?id=${id}`, []);
        if(result.status.type ===  "Success") {
            return result.response;
        }

        return null;
    }

    async restoreCoupon(id: number) {
        const result = await this.post<ApiModel<string>>(`/dashboard/coupon/restore?id=${id}`, []);
        if(result.status.type ===  "Success") {
            return result.response;
        }

        return null;
    }

    async fetchEligibleProductsForCoupon(couponType: CouponType, query?: string, start?: number, count?: number, category?: string): Promise<DashCouponProduct[]> {
        const params: string[][] = [];
        if((query?.length ?? 0) > 0) {
            params.push(["query", query]);
        }
        if(start) {
            params.push(["start", start.toString()]);
        }
        if(count) {
            params.push(["count", count.toString()]);
        }
        if(category) {
            params.push(["category", category]);
        }

        const url = couponType == CouponType.Discount ? "/dashboard/coupon/eligibleDiscountProducts" : "/dashboard/coupon/eligibleFreeProducts";
        const result = await this.get<ApiModel<DashCouponProduct[]>>(url, params);
        if(result.status.type ===  "Success") {
            return result.response;
        }

        return null;
    }

    async fetchEligibleFreeCouponProductCategories(): Promise<string[]> {
        const result = await this.get<ApiModel<string[]>>(`/dashboard/coupon/eligibleFreeProductCategories`);
        if(result.status.type ===  "Success") {
            return result.response;
        }

        return null;
    }

    async fetchEligibleDiscountCouponProductCategories(): Promise<string[]> {
        const result = await this.get<ApiModel<string[]>>(`/dashboard/coupon/eligibleDiscountProductCategories`);
        if(result.status.type ===  "Success") {
            return result.response;
        }

        return null;
    }

    async fetchEligibleCouponTypes(): Promise<CouponType[]> {
        const result = await this.get<ApiModel<CouponType[]>>(`/dashboard/coupon/eligibleCouponTypes`);
        if(result.status.type ===  "Success") {
            return result.response;
        }

        return null;
    }

    async newCoupon(coupon: DashCouponDetails) {
        const result = await this.post<ApiModel<DashCouponDetailsDTO>>(`/dashboard/coupon/new`, coupon.toApi());
        if(result.status.type ===  "Success") {
            return DashCouponDetails.createFromApi(result.response, true, true);
        }
        throw new Error(result?.status?.message);
    }

    private get<T>(url: string, params?: string[][]): Promise<T> {
        const fullUrl = new URL(url, this.baseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        return axios.get(fullUrl.href, { headers: { Authorization: `Bearer ${this.AccessToken}`} })
            .then(function (response) {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if(error.isAxiosError){
                    console.debug("Axios error response", error.response)

                    if(error.response.status == 401){
                        // Unauthorized error.
                        throw new DashApiError("Unauthorized request.", ApiErrorStatus.InvalidAccessToken);
                    }

                    console.log("Acioserror", error.response.status);
                }
                throw error;
            });

    }

    private async post<T>(url: string, payload: object): Promise<T> {
        const fullUrl = new URL(url, this.baseUrl);
        const response = await axios.post<T>(fullUrl.href, payload, { headers: { Authorization: `Bearer ${this.AccessToken}`} });

        return response.data;

    }
}
