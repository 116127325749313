import { Publishable, DashboardProduct } from './';
import { DashBundleComp, DashGeoRestrictableComp, DashPriceableComp } from '../DashboardProduct';

class DashBundle extends DashboardProduct {

  canPublish(): boolean {
    return (this.name && this.name.length >0
      && this.description && this.description.length > 0
      && this.defaultThumbnail !== null);
  }

  getOriginal(): DashBundle {
      return this.original? this.original as DashBundle : null;
  }


  initialize(): void {
    const orig = new DashBundle();
    orig.status=Publishable.STATUS_NEW;
    this.original = orig;
    this.copyFromOriginal();
    this.status = Publishable.STATUS_DRAFT;

    this.bundle = new DashBundleComp();
    this.priceable = new DashPriceableComp();
    this.geoRestrictable = new DashGeoRestrictableComp();
  }


  hasChanged(): boolean {
    if(!super.hasChanged()) {
      const orig = this.getOriginal();
      return (orig.name !== this.name
        || orig.description !== this.description
        || JSON.stringify(orig.bundle.items) !== JSON.stringify(this.bundle.items)
        || JSON.stringify(orig.defaultThumbnail) !== JSON.stringify(this.defaultThumbnail));
    }

    return true;
  }

  //ToDO: Move to Bundle Comp?
  getProductPriceTotal(): number {
    let total = 0;
    for (const p of this.bundle.items) {
      total += p.price;
    }
    console.log("Total: "+ total);
    return total;
  }

  canSubmitToApple(): boolean {
    if (!this.hasChanged() ) {
      if (this.name && this.description && this.defaultThumbnail) {
        return true;

      }
    }
    return false;
  }

  cannotSubmitToAppleReasons(): string[] {
    const reasons: string[] = [];
    if(this.hasChanged()) {
      reasons.push("Must save changes before submitting.");
    }

    if(!this.name) {
      reasons.push("Must have a title.");
    }

    if(!this.description) {
      reasons.push("Must have a description.");
    }

    if(!this.defaultThumbnail) {
      reasons.push("Must have a Cover photo.");
    }

    return reasons;
  }
}



export {DashBundle};
