import React, { useEffect, useMemo, useState } from 'react';
import './IntroMAL.scss';
import { ReactComponent as Icon } from '../KodanshaButton/Assets/Survey_Kodansha.svg';
import apiClient from '../../API/InkyAPI';
import { useAtom } from 'jotai';
import { AccountInfoAtom, LoginStatus, LoginStatusAtom } from '../../store/AccountStore';
import { fetchBadges, fetchClaimComics } from '../../store/BadgesStore';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import KodanshaArrow from '../KodanshaUIKit/KodanshaArrow/KodanshaArrow';

const NoCodes = (props) => {
    return (
        <div id={'intro-ax'} className='intro-ax-container'>
            <div className={'text-info-wrapper'}>
                <h1 className='intro-header'>Attack on Titan</h1>
                <h2 className='intro-second-header'>Worldwide After Party</h2>
                <h3 className='intro-third-header'>
                    The party must go on
                </h3>
                <span className='intro-ml-text'>
                    Unfortunately, we’ve run out of discount codes, but there’s still time to enjoy the celebration!
                </span>
            </div>

            <div className={'gift-state-wrapper'}>
            </div>
        </div>
    );
};

export default NoCodes;
