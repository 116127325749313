class AssetLinkGroup {
    id?: number;
    assetLinks: AssetLink[] =[];
    name: string;

    mutate(newProps: any): AssetLinkGroup {
        return Object.assign(new AssetLinkGroup(), this, newProps);
    }

    removeAssetLink(index: number): AssetLinkGroup {
        const assetLinks = [...this.assetLinks];
        assetLinks.splice(index, 1);
        return this.mutate({
            assetLinks: assetLinks
        });
    }

    alterAssetLink(index: number, newProps:any): AssetLinkGroup {
        const assetLinks = [...this.assetLinks];
        assetLinks[index] = Object.assign(new AssetLink(), assetLinks[index], newProps);
        return this.mutate({
            assetLinks: assetLinks
        });
    }

    addAssetLink(): AssetLinkGroup {
        const assetLinks = [...this.assetLinks];
        assetLinks.push(Object.assign(new AssetLink(), {
            type: 'ExternalUrl'
        }));

        return this.mutate({
            assetLinks: assetLinks
        });
    }

    toApi(): AssetLinkGroupDTO {
        return Object.assign({}, this);
    }


    static createFromApi(dto: AssetLinkGroupDTO): AssetLinkGroup {
        return Object.assign(new AssetLinkGroup(), dto, { assetLinks: dto.assetLinks.map(AssetLink.createFromApi) });
    }
}

class AssetLink {
    url: string;
    name: string;
    type: string;
    enabled: boolean;

    static createFromApi(dto: AssetLinkDTO): AssetLink {
        return Object.assign(new AssetLink(), dto);
    }
}

export interface AssetLinkGroupDTO {
    id?: number;
    assetLinks: AssetLink[];
    name: string;
}

export interface AssetLinkDTO {
    url: string;
    name: string;
    type: string;
    enabled: boolean;
}

export { AssetLinkGroup, AssetLink };
