import {
  PublishManager, DashboardSeries, DashboardProduct
} from './';
import apiClient from "../../API/InkyAPI";
import { AxiosResponse, CancelTokenSource } from 'axios';

class SeriesManager extends PublishManager<DashboardSeries> {


  constructor() {
    super();

    // this.fetchItems = async (cancelTokenSource: CancelTokenSource) => {
    //   let res = await dashboard.getAllCarouselItems(cancelTokenSource);
    //   console.log(`found ${res.data.length} carousel items`);
    //   return res.data.map(x => createDashboardCarouselFrom(x));
    // };

    this.addItem = async (
      cancelTokenSource: CancelTokenSource,
      item: DashboardSeries
    ) => {
      return await apiClient.post<DashboardSeries>("/dashboard/series/new",item, cancelTokenSource);
    };

    this.fetchItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      const item = await apiClient.get<DashboardSeries>(`/dashboard/series/${id}`, cancelTokenSource);
      const ds = new DashboardSeries();
      ds.original = item.data;
      ds.copyFromOriginal();

      return ds;
    };

    // this.removeItem = dashboard.removeCarouselItem;
    this.publishItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      return apiClient.post<Object>("/dashboard/series/publish?id="+id, null, cancelTokenSource);
    };

    this.unPublishItem = async(
      cancelTokenSource: CancelTokenSource,
      id: number,
    ) => {
      return apiClient.post<Object>("/dashboard/series/unpublish?id="+id, null, cancelTokenSource);
    };

    this.alterItems = (cancelTokenSource: CancelTokenSource, items: DashboardSeries[]) => {
      return apiClient.post<DashboardSeries[]>("/dashboard/series/alter", items, cancelTokenSource);
    };
    // this.reOrderItems = dashboard.reOrderCarouselItems;
  }

  //ToDO: Should we move this to ProductManager?
  async getProductsFor(seriesId: number): Promise<AxiosResponse<DashboardProduct[]>> {
      return apiClient.get<DashboardProduct[]>("/dashboard/product/for/series/" + seriesId, this.tokenSource);
  }
}

export default SeriesManager;
