// import S1 from './images/S1.jpg'
// import S2 from './images/S2.jpg'
// import S3 from './images/S3.jpg'
// import S4 from './images/S4.jpg'
// import S5 from './images/S5.jpg'
// import S6 from './images/S6.jpg'
// import S7 from './images/S7.jpg'
// import S8 from './images/S8.jpg'

const path = 'https://cdn.kodansha.us/statics/afterparty/'

export const SeriesDataMAL = [
    {
        name: 'Attack on Titan: No Regrets',
        author: 'Makoto Yukimura',
        image: path + 'S1.jpg',
        color: 'blue',
        readableUrl: 'attack-on-titan-no-regrets',
        reader: 8418,
        genres: ['DRAMA', 'HISTORICAL', 'ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
    {
        name: 'Attack on Titan: Before the Fall',
        author: 'Moyashi Fujisawa',
        image: path + 'S2.jpg',
        color: 'navy',
        readableUrl: 'attack-on-titan-before-the-fall ',
        reader: 8236,
        genres: ['DRAMA', 'THRILLER'],
    },
    {
        name: 'Attack on Titan: Junior High',
        author: 'Masaaki Nakayama',
        image: path + 'S3.jpg',
        color: 'pink',
        readableUrl: 'attack-on-titan-junior-high ',
        reader: 8324,
        genres: ['HORROR'],
    },
    {
        name: 'Attack on Titan Anthology',
        author: 'Riku Tsuchida',
        image: path + 'S4.jpg',
        color: 'pink',
        readableUrl: 'attack-on-titan-anthology',
        reader: 8246,
        genres: ['ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },

    {
        name: 'Attack on Titan: Lost Girls',
        author: 'Riku Tsuchida',
        image: path + 'S5.jpg',
        color: 'blue',
        readableUrl: 'attack-on-titan-lost-girls',
        reader: 8410,
        genres: ['ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
    {
        name: 'Attack on Titan Adventure',
        author: 'Riku Tsuchida',
        image: path + 'S6.jpg',
        color: 'purple',
        readableUrl: 'attack-on-titan-choose-your-path-adventure',
        reader: -1,
        genres: ['ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
    {
        name: 'Attack on Titan: The Harsh Mistress of the City',
        author: 'Riku Tsuchida',
        image: path + 'S7.jpg',
        color: 'green',
        readableUrl: 'attack-on-titan-the-harsh-mistress-of-the-city',
        reader: -1,
        genres: ['ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
    {
        name: 'Spoof on Titan',
        author: 'Riku Tsuchida',
        image: path + 'S8.jpg',
        color: 'green',
        readableUrl: 'spoof-on-titan',
        reader: 25473,
        genres: ['ACTION & ADVENTURE', 'MADE INTO ANIME'],
    },
];
