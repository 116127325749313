import React from 'react';
import './ExclusivesMAL.scss';
import MALGift from './images/AfterParty_News.png';
import { useAtom } from 'jotai';
import { LoginOverlayVisible } from '../../store/OverlayStore';

const ExclusivesMALFinished = () => {
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);

    return (
        <div className='exclusives-ax-container'>
            <div className={'text-info-wrapper'}>
                <h1 className='intro-header'>A FINAL BLOWOUT FOR THE FINALE</h1>
                <h2 className='intro-second-header'>The virtual event of a lifetime for diehard Attack on Titan
                    fans</h2>
                <span className='intro-text-mal'>
                    From November 5-8, 2023, <i>Attack on Titan</i> fans celebrated the series and its final episode through the <i>Attack on Titan</i> Worldwide After Party.&nbsp;
                    <strong>Kodansha Readers were given exclusive discount codes</strong> for tickets to the one-of-a-kind virtual event where they could:
                </span>

                <ul className={'mal-list-finished'}>
                    <li>Virtually commemorate the legacy of <i>Attack on Titan</i> with cast, staff, and fellow <i>AoT</i> fans
                        from
                        around the globe
                    </li>
                    <li>Enjoy a special program held by the anime cast and staff</li>
                    <li>Explore and discover artifacts, memories, and untold stories from the series</li>
                </ul>

                <span className='intro-booth'>
                    The party ended on November 8, and discount codes are no longer available, but the thrills and excitement of <i>Attack on Titan</i> don’t have to end here.
                </span>

                <span className='intro-booth intro-booth-mal-top'>
                    You can continue the fun by viewing the sections below and engaging with the manga in its many forms, including free extended previews of the main series and its various spinoffs.
                </span>
            </div>

            <div className={'gift-wrapper'}>
                <img src={MALGift} />
            </div>
        </div>
    );
};

export default ExclusivesMALFinished;