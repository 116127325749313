import React from 'react';
import './KodanshaGrid.scss';

type LayoutProps = Partial <React.HTMLAttributes<HTMLDivElement>>;

const KRow: React.FC<LayoutProps> = ({ children, ...props }): JSX.Element => {
    return (
        <div {...props} className={`k-row ${props.className ?? ''}`}>
            {children}
        </div>
    );
};

const KColumn: React.FC<LayoutProps> = ({ children, ...props }): JSX.Element => {
    return (
        <div {...props} className={`k-column ${props.className ?? ''}`}>
            {children}
        </div>
    );
};

export { KColumn, KRow };
