import 'react';
import React, { ReactElement } from 'react';
import { ViewPortSizes } from '../../constants';
import { Helmet } from 'react-helmet';

export interface InkyMageImgDimension {
    width: number;
    aspectRatio?: number;
}
interface InkyMageImgProps {
    // The original source of the image. InkyMage will take care of the resizing.
    src:    string;
    /// Description of image. Important for accessibility.
    alt:    string;

    className: string;
    /// Image width when column is small (Will fallback to extra-small if not set.)
    s:      InkyMageImgDimension;
    /// Image width when column is medium (Will fallback to small if not set.)
    m?:      InkyMageImgDimension;
    /// Image width when column is large (Will fallback to medium if not set.)
    l?:      InkyMageImgDimension;
    /// Image width when column is extra-large (Will fallback to large if not set.)
    xl?:     InkyMageImgDimension;

    preloadImage?: boolean | undefined ;

    style?: React.CSSProperties;

    width?: number;

    height?: number;
}

export default function InkyMageImg(props: InkyMageImgProps): ReactElement {

    let fetchPriority: 'auto' | 'low' |'high';
    if(props.preloadImage === undefined){
        fetchPriority = 'auto';
    }
    if(props.preloadImage === false){
        fetchPriority = 'low';
    }
    if(props.preloadImage === true){
        fetchPriority = 'high';
    }

    let loading: undefined | 'lazy' | 'eager';
    if(props.preloadImage === undefined){
        loading = undefined;
    }
    if(props.preloadImage === false){
        loading = 'lazy'
    }
    if(props.preloadImage === true){
        loading = 'eager'
    }

    // Calculate max-size for s
    //const sMaxSize = props.m ? ViewPortSizes.m - 1 : props.l? ViewPortSizes.l - 1 : false;
    const sMaxSize = props.m? ViewPortSizes.m -1 : props.l? ViewPortSizes.l - 1 : props.xl ? ViewPortSizes.xl -1 : false;
    const mMaxSize = props.l? ViewPortSizes.l - 1 : props.xl ? ViewPortSizes.xl -1 : false;
    const lMaxSize = props.xl ? ViewPortSizes.xl -1 : false;

    function getHeightAttribute(aspectRatio, width):string{
        if(!aspectRatio){ return "";}
        return"&h=" + Math.ceil(width/100)*100 * aspectRatio; // We Round up to nearest 100 since thats a limitation in the widths in InkyMage.
    }

    return(<>
            <picture>
                {/* WebP */}
                {props.s && <source type="image/webp" srcSet={`${props.src}?f=webp&w=${props.s.width}${getHeightAttribute(props.s.aspectRatio, props.s.width)}, ${props.src}?f=webp&w=${props.s.width}${getHeightAttribute(props.s.aspectRatio, props.s.width)} 2x`} media={`${sMaxSize && `(max-width: ${sMaxSize}px)`}`} />}
                {props.m && <source type="image/webp" srcSet={`${props.src}?f=webp&w=${props.m.width}${getHeightAttribute(props.m.aspectRatio, props.m.width)}, ${props.src}?f=webp&w=${props.m.width}${getHeightAttribute(props.m.aspectRatio, props.m.width)} 2x`} media={`(min-width: ${ViewPortSizes.m}px) ${mMaxSize && `and (max-width: ${mMaxSize}px)`}`} />}
                {props.l && <source type="image/webp" srcSet={`${props.src}?f=webp&w=${props.l.width}${getHeightAttribute(props.l.aspectRatio, props.l.width)}, ${props.src}?f=webp&w=${props.l.width}${getHeightAttribute(props.l.aspectRatio, props.l.width)} 2x`} media={`(min-width: ${ViewPortSizes.l}px) ${lMaxSize && `and (max-width: ${lMaxSize}px)`}`} />}
                {props.xl && <source type="image/webp" srcSet={`${props.src}?f=webp&w=${props.xl.width}${getHeightAttribute(props.xl.aspectRatio, props.xl.width)}, ${props.src}?f=webp&w=${props.xl.width}${getHeightAttribute(props.xl.aspectRatio, props.xl.width)} 2x`} media={`(min-width: ${ViewPortSizes.xl}px)`} />}

                {/* JPEG */}
                {props.s && <source type="image/jpeg" srcSet={`${props.src}?f=jpeg&w=${props.s.width}${getHeightAttribute(props.s.aspectRatio, props.s.width)}, ${props.src}?f=jpeg&w=${props.s.width}${getHeightAttribute(props.s.aspectRatio, props.s.width)} 2x`} media={`${sMaxSize && `(max-width: ${sMaxSize}px)`}`} />}
                {props.m && <source type="image/jpeg" srcSet={`${props.src}?f=jpeg&w=${props.m.width}${getHeightAttribute(props.m.aspectRatio, props.m.width)}, ${props.src}?f=jpeg&w=${props.m.width}${getHeightAttribute(props.m.aspectRatio, props.m.width)} 2x`} media={`(min-width: ${ViewPortSizes.m}px) ${mMaxSize && `and (max-width: ${mMaxSize}px)`}`} />}
                {props.l && <source type="image/jpeg" srcSet={`${props.src}?f=jpeg&w=${props.l.width}${getHeightAttribute(props.l.aspectRatio, props.l.width)}, ${props.src}?f=jpeg&w=${props.l.width}${getHeightAttribute(props.l.aspectRatio, props.l.width)} 2x`} media={`(min-width: ${ViewPortSizes.l}px) ${lMaxSize && `and (max-width: ${lMaxSize}px)`}`} />}
                {props.xl && <source type="image/jpeg" srcSet={`${props.src}?f=jpeg&w=${props.xl.width}${getHeightAttribute(props.xl.aspectRatio, props.xl.width)}, ${props.src}?f=jpeg&w=${props.xl.width}${getHeightAttribute(props.xl.aspectRatio, props.xl.width)} 2x`} media={`(min-width: ${ViewPortSizes.xl}px)`} />}


                <img fetchpriority={fetchPriority} loading={loading} style={props.style} className={props.className} src={props.src} alt={props.alt} width={props.width} height={props.height} />
            </picture>
        </>
    );
}
