import Dinero from 'dinero.js';
import { DistributionType, Order, OrderDTO } from './Order';
import { Address } from './Address';
import { Cart } from './Cart';
import { AccountInfo } from './AccountInfo';
import { OrderItem } from './OrderItem';
import { AddressValidationResponse } from './Google/AddressValidation';

/**
 * What comes out of a payment attempt.
 */
export interface PaymentResult {
    message?: string;
    transactionId: string;
    purchase?: Purchase;
}

export interface SoftConfirmResponse {
    status?: string;
    purchaseId?: string;
}

export enum PaymentMethod {
    NotSelected,
    Card,
    Paypal,
    GooglePay,
    ApplePay
}

export interface PriceDetailsDTO {

    shippingCost: number;
    netPrice: number;
    taxes: number;
    fullPrice: number;
}

export class PriceDetails {
    shippingCost: Dinero.Dinero;
    netPrice: Dinero.Dinero;
    fullPrice: Dinero.Dinero;
    taxes: Dinero.Dinero;

    toApi(): PriceDetailsDTO {
        return Object.assign({}, this, {
            shippingCost: this.shippingCost?.toUnit() ?? 0,
            netPrice: this.netPrice?.toUnit() ?? 0,
            fullPrice: this.fullPrice?.toUnit() ?? 0,
            taxes: this.taxes?.toUnit() ?? 0
        });
    }

    static createFromApi(dto: PriceDetailsDTO): PriceDetails {
        console.log("Converting pricedetails to Model", dto)
        const p: PriceDetails = Object.assign(new PriceDetails(), dto, {
            shippingCost: Purchase.toDineroUsd(dto?.shippingCost ?? 0),
            netPrice: Purchase.toDineroUsd(dto?.netPrice ?? 0),
            fullPrice: Purchase.toDineroUsd(dto?.fullPrice ?? 0),
            taxes: Purchase.toDineroUsd(dto?.taxes ?? 0),
        });

        return p;
    }
    
    getDiscount(){
        return this.fullPrice.subtract(this.netPrice);
    }

}

export class Purchase {
    id?: number;
    userId?: number;
    cartId: number;
    guestId: string;
    purchaseId: number;
    paymentMethod: PaymentMethod;
    paymentIntent?: string;
    totalPrice: Dinero.Dinero;

    totalPriceDetails: PriceDetails;
    orders: Order[];
    billingAddress: Address;
    shippingAddress: Address;
    couponCode: string;
    couponDescription?: string;
    couponSaveAmount: number;
    eligibleForFreeProduct: boolean;
    selectedProd: number;

    usesBillingAddressForShipping(): boolean {
        return (this.billingAddress === this.shippingAddress);
    }

    setUseBillingAddressForShipping(value: boolean): Purchase {
        if(value === this.usesBillingAddressForShipping()) {
            return this;
        }

        if(value) {
            console.log("Set billing same as shipping.");
            const billAndShipAddress = Object.assign(new Address(), this.billingAddress, {
                isShippingAddress: true,
                isBillingAddress: true
            });

            return Object.assign(new Purchase(), this, {
                billingAddress: billAndShipAddress,
                shippingAddress: billAndShipAddress
            });
        }
        else {
            const billAddress = Object.assign(new Address(), this.billingAddress, {
                isShippingAddress: false,
                isBillingAddress: true
            });

            const shipAddress = Object.assign(new Address(), this.billingAddress, {
                isShippingAddress: true,
                isBillingAddress: false
            });

            return Object.assign(new Purchase(), this, {
                billingAddress: billAddress,
                shippingAddress: shipAddress
            });
        }
    }


    isSplit(): boolean {
        return this.orders.length > 1;
    }

    shippingRatesPotentiallyChanged(previous: Purchase): boolean {
        if(previous && (Address.mandatoryFieldsFilled(previous?.shippingAddress) !== Address.mandatoryFieldsFilled(this.shippingAddress))) {
            //Always update if previous address was valid and new address is not, or vice versa.
            return true;
        }

        const prevCountry = previous?.shippingAddress?.country ?? null;
        const prevPostCode = previous?.shippingAddress?.postalCode ?? null;
        const country = this.shippingAddress?.country ?? null;
        const postCode = this.shippingAddress?.postalCode ?? null;

        if(prevCountry !== country || prevPostCode !== postCode ) {
            return true;
        }

        return false;
    }

    createRequest(): PurchaseRequest {
        return {
            cartId: this.cartId,
            guestId: this.guestId,
            billingAddress: this.billingAddress,
            shippingAddress: this.shippingAddress,
            splitOrder: this.isSplit(),
            couponCode: this.couponCode,
        };
    }

    matchesRequest(request: PurchaseRequest): boolean {
        const newReq = this.createRequest();
        return JSON.stringify(newReq) === JSON.stringify(request);
    }

    calculatePrices(): Purchase {
        let total = Dinero({amount: 0, currency: 'USD'});
        const newOrders = [];

        let changeDetected = false;
        for(let i = 0; i< this.orders.length; i++) {
            const newOrder = this.orders[i].calculatePrices();
            newOrders.push(newOrder);
            total = total.add(newOrder.totalPrice);
            changeDetected = changeDetected || (newOrder !== this.orders[i]);
        }

        if(changeDetected) {
            return Object.assign(new Purchase(), this, {
                orders: newOrders,
                totalPrice: total
            });
        }

        return this;
    }

    getShippingTotal(): Dinero.Dinero {
        let total = Dinero({
            amount: 0,
            currency: 'USD'
        });
        for(let i = 0; i< this.orders.length; i++) {
            const shippingCost = this.orders[i].priceDetails?.shippingCost;
            if(shippingCost) {
                total = total.add(this.orders[i].priceDetails.shippingCost);
            }
        }

        return total;
    }

    updateAddress(newAddress: Address): Purchase {
        const updateBillingAddress = newAddress.isBillingAddress && !Address.areExactMatch(newAddress, this.billingAddress);
        const updateShippingAddress = newAddress.isShippingAddress && !Address.areExactMatch(newAddress, this.shippingAddress);

        console.log(`Update addresses: billing: ${updateBillingAddress}, shipping: ${updateShippingAddress}`);
        if(!updateBillingAddress && !updateShippingAddress) {
            return this;
        }


        const billAddress = updateBillingAddress ? newAddress : this.billingAddress;
        const shipAddress = updateShippingAddress ? newAddress : this.shippingAddress;

        return Object.assign(new Purchase(), this, {
            billingAddress: billAddress,
            shippingAddress: shipAddress
        });
    }

    /**
     *
     * @param excludeDigital Only consider orders with physical items in them for calculating this.
     */
    containsMixOfPreOrdersAndOrders(excludeDigital: boolean): boolean {
        if(this.isSplit()) {
            if(excludeDigital) {
                const physicalOrders = this.orders.filter(x => x.orderItems.some(y => y.distributionType !== DistributionType.Digital));
                return (physicalOrders.length > 1);
            }

            return true;
        }
        if(this.orders[0]) {
            let items = this.orders[0].orderItems;
            if(excludeDigital) {
                items = items.filter(x => x.distributionType !== DistributionType.Digital);
            }
            return items.some(x => x.variant.isPreOrder) && items.some(x => !x.variant.isPreOrder);
        }
        return false;
    }

    containsDigitalItemsOnly(): boolean {
        const digitalOnlyOrders = this.orders.filter(x => x.orderItems.every(y => y.distributionType === DistributionType.Digital));

        return (digitalOnlyOrders.length > 0);
    }

    toApi(): PurchaseDTO {
        return Object.assign({}, this, {
            paymentMethod: PaymentMethod[this.paymentMethod],
            totalPrice: this.totalPrice.toUnit(),
            totalPriceDetails: this.totalPriceDetails.toApi(),
            orders: this.orders.map(x => x.toApi())
        });
    }

    changeAddresses(account: AccountInfo): Purchase {

        //Todo: Refactor get rid of copy-paste
        let billingAddress = account?.defaultBillingAddress;
        let shippingAddress = account?.defaultShippingAddress;
        if(Address.areExactMatch(shippingAddress, billingAddress)) {
            console.log('Exact match');
            billingAddress = Object.assign(new Address(), billingAddress, {
                isBillingAddress: true,
                isShippingAddress: true
            });
            shippingAddress = billingAddress;
            console.log(billingAddress);
        }
        else {
            console.log('NOt exact match');
            billingAddress = Object.assign(new Address(), billingAddress, {
                isBillingAddress: true,
                isShippingAddress: false
            });

            shippingAddress = Object.assign(new Address(), shippingAddress, {
                isBillingAddress: false,
                isShippingAddress: true
            });
        }

        return Object.assign(new Purchase(), this, {
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
        });
    }

    static createFromApi(dto: PurchaseDTO): Purchase {
        const p: Purchase = Object.assign(new Purchase(), dto, {
            totalPrice: Purchase.toDineroUsd(dto.totalPrice),
            totalPriceDetails: PriceDetails.createFromApi(dto.totalPriceDetails),
            orders: dto.orders.map(x => {
                return Order.createFromApi(x);
            })
        });

        for(let i = 0; i < p.orders.length; i++ ) {
            p.orders[i].purchase = p;
        }

        if(p.billingAddress?.isShippingAddress) {
            p.shippingAddress = p.billingAddress;
        }

        return p;
    }

    static toDineroUsd(amount: number): Dinero.Dinero {
        if (amount == undefined) return null;
        return Dinero({
            amount: Math.round(amount * 100),
            currency: 'USD'
        });
    }



    /**
     *
     * @param cart
     * @param account
     * @param splitOrder
     * @param couponCode
     */
    static fromCartAndAccount(cart: Cart, account: AccountInfo, splitOrder: boolean, couponCode: string): Purchase {
        if(!cart) {
            console.debug("[Checkout][Purchase] Cart is empty, returning no Purchase-object");
            return null;
        }

        let billingAddress = account?.defaultBillingAddress;
        let shippingAddress = account?.defaultShippingAddress;
        if(Address.areExactMatch(shippingAddress, billingAddress)) {
            console.log('Exact match');
            billingAddress = Object.assign(new Address(), billingAddress, {
                isBillingAddress: true,
                isShippingAddress: true
            });
            shippingAddress = billingAddress;
            console.log(billingAddress);
        }
        else {
            console.log('NOt exact match');
            billingAddress = Object.assign(new Address(), billingAddress, {
                isBillingAddress: true,
                isShippingAddress: false
            });

            shippingAddress = Object.assign(new Address(), shippingAddress, {
                isBillingAddress: false,
                isShippingAddress: true
            });
        }

        const p = Object.assign(new Purchase(), {
            cartId: cart?.id,
            guestId: cart?.guestId,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            orders: []
        });

        // if(!p.billingAddress) {
        //     p.billingAddress = Object.assign(new Address(), {
        //         isBillingAddress: true
        //     });
        // }
        // if(!p.shippingAddress) {
        //     p.billingAddress.isShippingAddress=true;
        //     p.shippingAddress = p.billingAddress;
        // }

        const orderItems = cart ? cart.cartItems.map(x => {
            return Object.assign(new OrderItem(), x);
        }): [];

        const order = Object.assign(new Order(),{
            id: null,
            accountingId: null,
            userId: cart?.userId,
            status: null,
            orderDate: null,
            billingAddress: null,
            shippingAddress: null,
            shippingMethod: null,
            orderItems: orderItems,
            totalPrice: null,
            orderPrices: null
        });

        if (couponCode){
            p.couponCode = couponCode;
        }

        if(splitOrder) {
           p.orders = order.split();
        }
        else {
            p.orders.push(order);
        }
        console.debug("[Checkout][Purchase] Returning Purchase-object with prices.");
        return p.calculatePrices();
    }

    static fromCartAndAddresses(
        cart: Cart, 
        shippingAddress:Address, billingAddress:Address, 
        billingSameAsShipping:boolean, hasPhysical:boolean, 
        splitOrder: boolean, 
        couponCode: string,
        mailService?: string
    ): Purchase {
        
        shippingAddress = Address.FromPartial(shippingAddress);
        billingAddress = Address.FromPartial(billingAddress);
        
        if(!cart) {
            console.debug("[Checkout][Purchase] Cart is empty, returning no Purchase-object");
            return null;
        }
        if(!hasPhysical){
            //digital purchase use billing address
            console.log("Digital Purchase")
            billingAddress = Object.assign(new Address(), billingAddress, {
                isBillingAddress: true,
                isShippingAddress: true
            });
            
            shippingAddress= billingAddress;
            
            console.log(shippingAddress);
            
        } else if(billingSameAsShipping){
            console.log('No billing, use shipping');
            shippingAddress = Object.assign(new Address(), shippingAddress, {
                isBillingAddress: false,
                isShippingAddress: true,
                email:billingAddress.email,
                phoneNumber:billingAddress.phoneNumber
            });
            
            let billingUpdates:Partial<Address> = {
                isBillingAddress: true,
                isShippingAddress: false,
                address: shippingAddress.address,
                firstName:shippingAddress.firstName,
                surName:shippingAddress.surName,
                companyName:shippingAddress.companyName,
                phoneNumber:shippingAddress.phoneNumber,
                locality:shippingAddress.locality,
                postalCode:shippingAddress.postalCode,
                administrativeArea:shippingAddress.administrativeArea,
                country:shippingAddress.country,
            }
            
            billingAddress = Object.assign(billingAddress, billingUpdates)
     
            console.log(shippingAddress);
        }
        else {
            console.log('Physical purchase, different shipping and billing');
            billingAddress = Object.assign(new Address(), billingAddress, {
                isBillingAddress: true,
                isShippingAddress: false
            });

            shippingAddress = Object.assign(new Address(), shippingAddress, {
                isBillingAddress: false,
                isShippingAddress: true,
                email:billingAddress.email,
                phoneNumber:billingAddress.phoneNumber
            });
            console.log("ShippingAddress",shippingAddress);
            console.log("BillingAddress",billingAddress);
        }

        const p = Object.assign(new Purchase(), {
            cartId: cart?.id,
            guestId: cart?.guestId,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            orders: []
        });

        // if(!p.billingAddress) {
        //     p.billingAddress = Object.assign(new Address(), {
        //         isBillingAddress: true
        //     });
        // }
        // if(!p.shippingAddress) {
        //     p.billingAddress.isShippingAddress=true;
        //     p.shippingAddress = p.billingAddress;
        // }

        const orderItems = cart ? cart.cartItems.map(x => {
            return Object.assign(new OrderItem(), x);
        }): [];

        const order = Object.assign(new Order(),{
            id: null,
            accountingId: null,
            userId: cart?.userId,
            status: null,
            orderDate: null,
            billingAddress: null,
            shippingAddress: null,
            shippingMethod: null,
            orderItems: orderItems,
            totalPrice: null,
            orderPrices: null
        });

        if (couponCode){
            p.couponCode = couponCode;
        }

        if(splitOrder) {
            p.orders = order.split();
        }
        else {
            p.orders.push(order);
        }
        console.debug("[Checkout][Purchase] Returning Purchase-object with prices.");
        return p.calculatePrices();
    }

}



export interface PurchaseDTO {
    id?: number;
    userId?: number;
    cartId: number;
    purchaseId: number;
    totalPrice: number;

    totalPriceDetails: PriceDetailsDTO;
    paymentMethod: string;
    orders: OrderDTO[];
    billingAddress: Address;
    shippingAddress: Address;
    couponCode: string;
    eligibleForFreeProduct: boolean;
}



export interface PurchaseRequest {
    cartId: number;
    billingAddress: Address;
    shippingAddress: Address;
    guestId: string;
    splitOrder: boolean;
    couponCode?: string;
    mailService?: string;
    previousValidationResponseId?: string;
}
