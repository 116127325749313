import React from 'react';
import './EndAX.scss';
import KodanshaSubmit from '../KodanshaUIKit/KodanshaSubmit/KodanshaSubmit';
import { useWindowSize } from '../Hooks/useWindowSize';
import { useAtom } from 'jotai';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import { LoginStatus, LoginStatusAtom } from '../../store/AccountStore';

const EndAX = () => {
    const { width } = useWindowSize();
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);

    return (
        <div className='ax-end-container'>
            <div className='ax-end-more-wrapper'>
                <h3 className='ax-end-more-header'>And More!</h3>
                <p className='ax-end-more-text'>
                    {width < 1280 ?
                        `As new  features are added, we'll make sure to notify Readers. 
                        If not a Reader, sign up for the newsletter or follow on social.`
                        :
                        `We'll make sure to keep you informed as new features are added. If you're not
                    already a Kodansha Reader, we invite you to subscribe to one of our newsletters
                    and follow us on social media for future updates.`}</p>
            </div>

            {loginStatus !== "LoggedIn" &&
                <KodanshaSubmit text={'Create Account'} styles={'ax-create-account-btn'}
                                onSubmitClicked={() => setLoginOverlay(true)} />}


            <span className={'ax-digital-manga-notice'}>
                *Digital manga can be read from anywhere. Digital purchases are not available in Japan.
            </span>
        </div>
    );
};

export default EndAX;