class IapsResult {
  id: number;
  appleIaps: SubIapsResult<AppleIapsResult>;
  androidIaps: SubIapsResult<AndroidIapsResult>;
}

class SubIapsResult<T> {
  sku: string;
  responseBody: string;
  responseObject: T;
}

class AppleIapsResult {
  cleared_for_sale: boolean;
  product_id: string;
  reference_name: string;
  status: string;
  pricing_intervals: [];
}

class AndroidIapsResult {
  title: string;
  description: string;
  sku: string;
  priceInUSD: number;
  active: boolean;
}

export {IapsResult, SubIapsResult, AppleIapsResult, AndroidIapsResult};
